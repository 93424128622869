import { Observable } from 'rxjs';
import IDeployment from 'src/_interfaces/IDeployment';

import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';

import Events from '../_util/events';

@Injectable()
export class DeploymentService {
	private deployments: IDeployment[] = [];

	constructor(private socketService: SocketService) { }

	public init(): Observable<any> {
		return this.socketService.call(Events.LIST_DEPLOYMENTS, (deployments) => {
			this.deployments = deployments;
		});
	}

	public getDeployments(): IDeployment[] {
		return this.deployments;
	}

	public getDeploymentPortal(): IDeployment {
		return this.deployments.find((deployment) => !!deployment.access.portal);
	}

	public getDeploymentById(id: string): IDeployment {
		return this.deployments.find((deployment) => deployment._id === id);
	}

	public loginToDeployment(deploymentId: string, host: string): Observable<any> {
		return this.socketService.call(
			Events.GET_AUTHENTICATION_TOKEN_FOR_DEPLOYMENT,
			(token) => {
				const loginUrl = `https://api.${host}/api/1.0.2/core/auth?token=${token}`;

				window.location.href = loginUrl;
			},
			deploymentId
		);
	}
}

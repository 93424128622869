import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction
} from '../builder.interface';

export class ShapeThicknessToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'shape_thickness';
	label: string = 'Thickness';
	icon: string = 'arrows alternate horizontal';
	tooltip: string = 'Assign a thickness to the shape';
	actionType: ToolbarActionType = ToolbarActionType.INPUT;

	execute(context: IVideoBuilderContext, thickness: number): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const thicknessProperty = context.getPropertyType(ElementType.SHAPE, 'properties["svg::width"]');

		context.setElementProperty(activeElement, thicknessProperty, thickness);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const thicknessProperty = context.getPropertyType(ElementType.SHAPE, 'properties["svg::width"]');

		return context.getElementProperty(context.getActiveElement(), thicknessProperty);
	}
}

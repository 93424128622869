import { SVGElementType, ISVGPoint, ISVG } from "../svg.definition";
import SVGElementGeneric from "./generic.definition";

export class SVGLine extends SVGElementGeneric {
    type: SVGElementType = SVGElementType.LINE;
    points: ISVGPoint[] = [];

    constructor(pointA: ISVGPoint, pointB: ISVGPoint) {
        super();

        this.points = [pointA, pointB];
    }

    generate(builder: any, parent: ISVG) {
        return builder.line({
            ['x1']: this.points[0].x - parent.getBounds().ax,
            ['y1']: this.points[0].y - parent.getBounds().ay,
            ['x2']: this.points[1].x - parent.getBounds().ax,
            ['y2']: this.points[1].y - parent.getBounds().ay,
            ['stroke']: parent.getProperty('fill'),
            ['stroke-width']: parent.getProperty('width')
        });
    }
}
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resourceView.modal.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../_components/waveform/waveform.component.ngfactory";
import * as i3 from "../../_components/waveform/waveform.component";
import * as i4 from "../../_services/waveform.service";
import * as i5 from "../../_pipes/safe.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./resourceView.modal";
import * as i9 from "ng2-semantic-ui/dist/modules/modal/classes/modal-controls";
var styles_ResourceViewModalComponent = [i0.styles];
var RenderType_ResourceViewModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResourceViewModalComponent, data: {} });
export { RenderType_ResourceViewModalComponent as RenderType_ResourceViewModalComponent };
function View_ResourceViewModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.cdnUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceViewModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "video", [["autoplay", ""], ["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.cdnUrl; _ck(_v, 2, 0, currVal_0); }); }
function View_ResourceViewModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-waveform", [], null, null, null, i2.View_WaveformComponent_0, i2.RenderType_WaveformComponent)), i1.ɵdid(2, 4243456, null, 0, i3.WaveformComponent, [i4.WaveformService], { url: [0, "url"], controls: [1, "controls"], interactable: [2, "interactable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.cdnUrl; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ResourceViewModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "iframe", [], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.resource.cdnUrl)); _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceViewModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "cdm-resource-view__no-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon circle info"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Cannot preview this file (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.mimeType; _ck(_v, 3, 0, currVal_0); }); }
export function View_ResourceViewModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SafePipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Viewing ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceViewModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceViewModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceViewModalComponent_3)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceViewModalComponent_4)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceViewModalComponent_5)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "ui button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.approve(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.resource.cdnUrl && _co.isImage()); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.resource.cdnUrl && _co.isVideo()); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.resource.cdnUrl && _co.isAudio()); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.resource.cdnUrl && _co.isPDF()); _ck(_v, 11, 0, currVal_4); var currVal_5 = !_co.canPreview(); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_ResourceViewModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-resource-review", [], null, null, null, View_ResourceViewModalComponent_0, RenderType_ResourceViewModalComponent)), i1.ɵdid(1, 49152, null, 0, i8.ResourceViewModalComponent, [i9.Modal], null, null)], null, null); }
var ResourceViewModalComponentNgFactory = i1.ɵccf("app-modal-resource-review", i8.ResourceViewModalComponent, View_ResourceViewModalComponent_Host_0, {}, {}, []);
export { ResourceViewModalComponentNgFactory as ResourceViewModalComponentNgFactory };

import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { InputModalComponent } from './input.modal';
var InputModal = /** @class */ (function (_super) {
    tslib_1.__extends(InputModal, _super);
    function InputModal(header, message, input) {
        var _this = _super.call(this, InputModalComponent, { header: header, message: message, input: input }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = ModalSize.Small;
        return _this;
    }
    return InputModal;
}(ComponentModalConfig));
export { InputModal };

import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import IDirectory from '../../_interfaces/IDirectory';
import INewDirectoryModalContext from './newDirectory.interface';
import { NewDirectoryModalComponent } from './newDirectory.modal';

export class NewDirectoryModal extends ComponentModalConfig<INewDirectoryModalContext, void, void> {
	constructor(parent: IDirectory = null, type: string = 'user', size = ModalSize.Small) {
		super(NewDirectoryModalComponent, { parent, type });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

import * as tslib_1 from "tslib";
import { ModalService } from '@citadel/common-frontend/_services/modal.service';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { catchError } from 'rxjs/internal/operators';
import { HttpEventType } from '@angular/common/http';
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { DeleteModal } from '../../_modals/delete/delete.export';
import { NewDirectoryModal } from '../../_modals/newDirectory/newDirectory.export';
import { ResourceModal } from '../../_modals/resource/resource.export';
import { ResourceViewModal } from '../../_modals/resourceView/resourceView.export';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import { PreferencesService } from '../../_services/preferences.service';
import { downloadResourceToDisk } from '../../_util/functions';
import { Router } from '@angular/router';
import { validateMimetype } from '../../_util/files';
var FSComponent = /** @class */ (function () {
    function FSComponent(modal, router, preferencesService, loaderService, notificationService, filesystemService) {
        this.modal = modal;
        this.router = router;
        this.preferencesService = preferencesService;
        this.loaderService = loaderService;
        this.notificationService = notificationService;
        this.filesystemService = filesystemService;
        this.defaultVisualMode = 'grid';
        this.metadata = {};
        this.type = '*';
        this.onDirectoryEntitlementChange = new EventEmitter();
        this.onFileEntitlementChange = new EventEmitter();
        this.allowSystemFolderCreation = false;
        this.isCompact = false;
        this.selectedResourcesChange = new EventEmitter();
        // handle view state and commands
        this.currentPath = '';
        this.currentDirectory = null;
        this.currentVisualMode = '';
        this.activeChildrenFiles = [];
        this.activeSubdirectories = [];
        this.currentUploads = [];
        this.refreshing = false;
        this.fileHovering = false;
        this.directoryHistory = [];
        this.directoryHistoryIndex = -1;
        // handle search
        this.searchFilter = '';
        this.isShowingSearchResults = false;
        // handle modal queue
        this.uploadEditQueue = [];
        this.showingEditModal = false;
    }
    FSComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.refresh(true);
                this.currentVisualMode = this.preferencesService.getPreference(['fs', 'visual-mode'], this.defaultVisualMode);
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.hasResourcesToDisplay = function () {
        return this.refreshing || !!this.currentDirectory || this.activeChildrenFiles.length > 0 || this.activeSubdirectories.length > 0;
    };
    FSComponent.prototype.validateDrag = function (_a) {
        var x = _a.x, y = _a.y;
        var MIN_DRAG_THRESHOLD = 8;
        return Math.abs(x) > MIN_DRAG_THRESHOLD || Math.abs(y) > MIN_DRAG_THRESHOLD;
    };
    FSComponent.prototype.setVisualMode = function (mode) {
        this.currentVisualMode = mode;
        this.preferencesService.setPreference(['fs', 'visual-mode'], mode);
    };
    FSComponent.prototype.openDeleteModal = function (resources) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resourceType;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (resources.length > 1) {
                    resourceType = 'resource';
                }
                else {
                    if (resources['path']) {
                        resourceType = 'directory';
                    }
                    else {
                        resourceType = 'file';
                    }
                }
                this.modal
                    .open(new DeleteModal('these resources', resourceType, false))
                    .onApprove(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _i, resources_1, resource, type, e_1;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.loaderService.setLoading(true, "Deleting resource");
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 9, , 10]);
                                if (!(resources.length > 0)) return [3 /*break*/, 6];
                                _i = 0, resources_1 = resources;
                                _a.label = 2;
                            case 2:
                                if (!(_i < resources_1.length)) return [3 /*break*/, 5];
                                resource = resources_1[_i];
                                type = resource.path ? 'directory' : 'file';
                                return [4 /*yield*/, this.filesystemService.delete(type, resource).toPromise()];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                _i++;
                                return [3 /*break*/, 2];
                            case 5: return [3 /*break*/, 8];
                            case 6: return [4 /*yield*/, this.filesystemService.delete(resourceType, resources).toPromise()];
                            case 7:
                                _a.sent();
                                _a.label = 8;
                            case 8:
                                this.refresh(true);
                                return [3 /*break*/, 10];
                            case 9:
                                e_1 = _a.sent();
                                this.notificationService.displayError(e_1);
                                return [3 /*break*/, 10];
                            case 10:
                                this.loaderService.setLoading(false);
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .onDeny(function () { });
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.upload = function () {
        if (this.uploadInput) {
            this.uploadInput.nativeElement.click();
        }
    };
    FSComponent.prototype.openDirectoryEntitlementsModal = function (directory) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.openFileEntitlementsModal = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.getPsuedoParentDirectory = function () {
        // slightly hacky
        if (this.currentDirectory === null) {
            return null;
        }
        return this.currentDirectory.parent
            ? {
                _id: this.currentDirectory.parent,
            }
            : null;
    };
    FSComponent.prototype.makeDirectory = function () {
        var _this = this;
        this.modal
            .open(new NewDirectoryModal(this.currentDirectory, 'user'))
            .onApprove(function () {
            _this.refresh();
        })
            .onDeny(function () { });
    };
    FSComponent.prototype.makeSystemDirectory = function () {
        var _this = this;
        this.modal
            .open(new NewDirectoryModal(this.currentDirectory, 'system'))
            .onApprove(function () {
            _this.refresh();
        })
            .onDeny(function () { });
    };
    FSComponent.prototype.hasThumbnail = function (resource) {
        return !!(resource.urls && resource.urls.thumbnail);
    };
    FSComponent.prototype.getIcon = function (resource) {
        if (resource['path']) {
            return 'folder';
        }
        var file = resource;
        var genericMimeType = file.mimeType.split('/')[0];
        switch (genericMimeType) {
            case 'video':
                return 'file video outline';
            case 'image':
                return 'file image outline';
            case 'audio':
                return 'file audio outline';
            case 'text':
                return 'file alternate outline';
        }
        return 'file outline';
    };
    FSComponent.prototype.isAudio = function (resource) {
        return resource.mimeType.startsWith('audio');
    };
    FSComponent.prototype.isImage = function (resource) {
        return resource.mimeType.startsWith('image');
    };
    FSComponent.prototype.isVideo = function (resource) {
        return resource.mimeType.startsWith('video');
    };
    FSComponent.prototype.isPDF = function (resource) {
        return resource.mimeType === 'application/pdf';
    };
    FSComponent.prototype.isSelected = function (resource) {
        var indexOf = this.selectedResources.indexOf(resource);
        return indexOf !== -1;
    };
    FSComponent.prototype.isDisabled = function (resource) {
        if (!this.type || !resource['mimeType']) {
            return false;
        }
        return !validateMimetype(resource['mimeType'], this.type);
    };
    FSComponent.prototype.isGridViewElementDisabled = function (resource) {
        if (this.mode !== 'filepicker') {
            return false;
        }
        if (!this.type || !resource['mimeType']) {
            return false;
        }
        return !validateMimetype(resource['mimeType'], this.type);
    };
    FSComponent.prototype.areAllSelected = function () {
        return this.selectedResources.length === this.activeChildrenFiles.length + this.activeSubdirectories.length;
    };
    FSComponent.prototype.onAllCheckChange = function ($event) {
        var value = !!$event;
        if (value) {
            this.selectedResources = [];
            for (var _i = 0, _a = this.activeSubdirectories; _i < _a.length; _i++) {
                var subdirectory = _a[_i];
                this.selectedResources.push(subdirectory);
            }
            for (var _b = 0, _c = this.activeChildrenFiles; _b < _c.length; _b++) {
                var file = _c[_b];
                this.selectedResources.push(file);
            }
        }
        else {
            this.selectedResources = [];
        }
        this.selectedResourcesChange.emit(this.selectedResources);
    };
    FSComponent.prototype.onResourceCheckChange = function ($event, resource) {
        var value = !!$event;
        if (value) {
            this.selectedResources.push(resource);
        }
        else {
            var indexOf = this.selectedResources.indexOf(resource);
            if (indexOf !== -1) {
                this.selectedResources.splice(indexOf, 1);
            }
        }
        this.selectedResourcesChange.emit(this.selectedResources);
    };
    FSComponent.prototype.onDirectoryDrop = function (directory, _a) {
        var dropData = _a.dropData;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var type, e_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (directory === dropData) {
                            return [2 /*return*/];
                        }
                        if (dropData['path']) {
                            type = 'directory';
                        }
                        else {
                            type = 'file';
                        }
                        this.refreshing = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.filesystemService.move(type, dropData, directory).toPromise()];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        this.notificationService.displayError(e_2.toString());
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.refresh(false)];
                    case 5:
                        _b.sent();
                        this.refreshing = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    FSComponent.prototype.navigateBack = function () {
        if (this.directoryHistoryIndex === -1) {
            return;
        }
        this.currentDirectory = this.directoryHistory[--this.directoryHistoryIndex];
        this.refresh();
    };
    FSComponent.prototype.navigateForward = function () {
        if (this.directoryHistoryIndex === this.directoryHistory.length - 1) {
            return;
        }
        this.currentDirectory = this.directoryHistory[++this.directoryHistoryIndex];
        this.refresh();
    };
    FSComponent.prototype.navigateTo = function (directory) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (directory && !directory.path) {
                    // this is probably a file
                    return [2 /*return*/];
                }
                this.directoryHistory.splice(this.directoryHistoryIndex + 1, this.directoryHistory.length - this.directoryHistoryIndex);
                this.directoryHistory.push(directory);
                this.directoryHistoryIndex++;
                this.currentDirectory = directory;
                this.refresh();
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.navigateToParent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var directoryDetails;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.refreshing = true;
                        return [4 /*yield*/, this.filesystemService.getDirectoryDetails(this.getPsuedoParentDirectory()).toPromise()];
                    case 1:
                        directoryDetails = _a.sent();
                        this.currentDirectory = directoryDetails.parent;
                        this.activeChildrenFiles = directoryDetails.files;
                        this.activeSubdirectories = directoryDetails.subDirectories;
                        this.refreshing = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    FSComponent.prototype.onGridFileClick = function (file) {
        if (this.mode === 'filepicker') {
            this.toggleResource(file);
        }
        else {
            this.openPreviewModal(file);
        }
    };
    FSComponent.prototype.refresh = function (triggerLoading) {
        if (triggerLoading === void 0) { triggerLoading = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var directoryDetails;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isShowingSearchResults = false;
                        if (triggerLoading) {
                            this.refreshing = true;
                        }
                        this.selectedResources = [];
                        return [4 /*yield*/, this.filesystemService.getDirectoryDetails(this.currentDirectory).toPromise()];
                    case 1:
                        directoryDetails = _a.sent();
                        this.currentDirectory = directoryDetails.parent;
                        this.activeChildrenFiles = directoryDetails.files;
                        this.activeSubdirectories = directoryDetails.subDirectories;
                        if (triggerLoading) {
                            this.refreshing = false;
                        }
                        this.selectedResourcesChange.emit(this.selectedResources);
                        return [2 /*return*/];
                }
            });
        });
    };
    FSComponent.prototype.search = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchFilter, searchDetails;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.searchFilter) {
                            this.refresh();
                            return [2 /*return*/];
                        }
                        searchFilter = this.searchFilter.trim();
                        if (searchFilter.length === 0) {
                            this.refresh();
                            return [2 /*return*/];
                        }
                        this.refreshing = true;
                        this.selectedResources = [];
                        return [4 /*yield*/, this.filesystemService.search(searchFilter).toPromise()];
                    case 1:
                        searchDetails = _a.sent();
                        this.currentDirectory = searchDetails.parent;
                        this.activeChildrenFiles = searchDetails.files;
                        this.activeSubdirectories = searchDetails.subDirectories;
                        this.isShowingSearchResults = true;
                        this.refreshing = false;
                        this.selectedResourcesChange.emit(this.selectedResources);
                        return [2 /*return*/];
                }
            });
        });
    };
    FSComponent.prototype.download = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileName, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileName = file.name + (file.extension ? "." + file.extension : '');
                        this.loaderService.setLoading(true, 'Downloading...');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, downloadResourceToDisk(file.cdnUrl, fileName)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        this.notificationService.displayError(e_3);
                        return [3 /*break*/, 4];
                    case 4:
                        this.loaderService.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    FSComponent.prototype.gotoBulkAction = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(["/dashboard/bulk-management"], {
                    queryParams: {
                        sourceResourceType: 'video',
                        sourceResource: btoa(JSON.stringify(file))
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.toggleResource = function (resource) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var value, indexOf;
            return tslib_1.__generator(this, function (_a) {
                value = this.selectedResources.indexOf(resource) === -1;
                if (value) {
                    this.selectedResources.push(resource);
                }
                else {
                    indexOf = this.selectedResources.indexOf(resource);
                    if (indexOf !== -1) {
                        this.selectedResources.splice(indexOf, 1);
                    }
                }
                this.selectedResourcesChange.emit(this.selectedResources);
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.copyUrl = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                document.addEventListener('copy', function (e) {
                    e.clipboardData.setData('text/plain', file.cdnUrl);
                    e.preventDefault();
                    document.removeEventListener('copy', null);
                });
                document.execCommand('copy');
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.openEditModal = function (resource, justUploaded) {
        if (justUploaded === void 0) { justUploaded = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var onComplete;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                onComplete = function () {
                    if (_this.uploadEditQueue.length > 0) {
                        _this.openEditModal(_this.uploadEditQueue.shift(), justUploaded);
                    }
                    else {
                        _this.refresh();
                        _this.showingEditModal = false;
                    }
                };
                this.showingEditModal = true;
                this.modal
                    .open(new ResourceModal(resource, justUploaded, this.metadata ? this.metadata['tags'] : []))
                    .onApprove(function () {
                    onComplete();
                })
                    .onDeny(function () {
                    onComplete();
                });
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.openPreviewModal = function (resource) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modal.open(new ResourceViewModal(resource));
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.getCurrentDirectoryPath = function () {
        if (!this.currentDirectory) {
            return [];
        }
        return this.currentDirectory.path.split('/');
    };
    // events
    FSComponent.prototype.onFileOver = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.fileHovering = true;
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.onFileLeave = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.fileHovering = false;
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.onFileDrop = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var files, uploadFile, fileEntry;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                files = $event.files;
                if (files && files.length > 0) {
                    uploadFile = files[0];
                    if (!uploadFile.fileEntry.isFile) {
                        this.notificationService.displayError('Not a file!');
                        return [2 /*return*/];
                    }
                    fileEntry = uploadFile.fileEntry;
                    fileEntry.file(function (file) {
                        _this.handleFileInput(file);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.onFileChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, file;
            return tslib_1.__generator(this, function (_b) {
                if ($event.target.files && $event.target.files.length > 0) {
                    for (_i = 0, _a = $event.target.files; _i < _a.length; _i++) {
                        file = _a[_i];
                        this.handleFileInput(file);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    FSComponent.prototype.handleFileInput = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pendingUpload;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                pendingUpload = { name: file.name, progress: 0 };
                this.currentUploads.push(pendingUpload);
                this.filesystemService
                    .upload(file, this.currentDirectory, this.sessionToken)
                    .pipe(catchError(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var errorMessage, indexOf;
                    return tslib_1.__generator(this, function (_a) {
                        errorMessage = event.error.error || event.error.data;
                        this.notificationService.displayError(errorMessage);
                        indexOf = this.currentUploads.indexOf(pendingUpload);
                        if (indexOf !== -1) {
                            this.currentUploads.splice(indexOf, 1);
                        }
                        return [2 /*return*/];
                    });
                }); }))
                    .subscribe(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var progress, upload, indexOf;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!event) {
                                    return [2 /*return*/];
                                }
                                if (event.type === HttpEventType.UploadProgress) {
                                    progress = Math.round((100 * event.loaded) / event.total);
                                    pendingUpload.progress = progress;
                                }
                                if (!(event.type === HttpEventType.Response)) return [3 /*break*/, 2];
                                upload = event.body;
                                if (upload.meta && upload.meta.length > 0) {
                                    if (this.showingEditModal || this.uploadEditQueue.length > 0) {
                                        this.uploadEditQueue.push(upload);
                                    }
                                    else {
                                        this.openEditModal(upload, true);
                                    }
                                }
                                indexOf = this.currentUploads.indexOf(pendingUpload);
                                if (indexOf !== -1) {
                                    this.currentUploads.splice(indexOf, 1);
                                }
                                return [4 /*yield*/, this.refresh()];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    return FSComponent;
}());
export { FSComponent };

import { SuiModal } from 'ng2-semantic-ui';

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import IDirectory from '../../_interfaces/IDirectory';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import IResourceModalContext from './newDirectory.interface';

@Component({
	selector: 'app-modal-new-directory',
	templateUrl: './newDirectory.modal.html',
	styleUrls: ['./newDirectory.modal.scss'],
})
export class NewDirectoryModalComponent {
	public newDirectoryFormGroup: FormGroup;
	public parent: IDirectory;
	public type: string;

	constructor(public modal: SuiModal<IResourceModalContext, void, void>, private notificationService: NotificationService, private filesystemService: FilesystemService, private loaderService: LoaderService, private fb: FormBuilder) {
		this.parent = this.modal.context.parent;
		this.type = this.modal.context.type;
		this.initializeForm();
	}

	private initializeForm() {
		this.newDirectoryFormGroup = this.fb.group({
			name: ['', Validators.compose([Validators.required])],
		});
	}

	public async createDirectory() {
		this.loaderService.setLoading(true, 'Creating directory...');
		try {
			await this.filesystemService.makeDirectory(this.newDirectoryFormGroup.value.name, this.parent, this.type).toPromise();
			this.modal.approve(undefined);
		} catch (e) {
			this.notificationService.displayError(e);
		}
		this.loaderService.setLoading(false);
	}
}

import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import IFile from '../../_interfaces/IFile';
import IResourceViewModalContext from './resourceView.interface';
import { ResourceViewModalComponent } from './resourceView.modal';

export class ResourceViewModal extends ComponentModalConfig<IResourceViewModalContext, void, void> {
	constructor(resource: IFile, size = ModalSize.Normal) {
		super(ResourceViewModalComponent, { resource });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

import { DomSanitizer } from '@angular/platform-browser';

import { SVGCircle } from "../elements/circle.definition";
import { SVGLine } from "../elements/line.definition";
import { ISVGBounds, SVGType } from "../svg.definition";
import SVGGeneric from "./generic.definition";

export default class SVGArrow extends SVGGeneric {
    type = SVGType.ARROW;

    properties: { [key: string]: any; } = {
        ['fill']: '#042e41',
        ['width']: 6
    };

    constructor(ax: number, ay: number, bx: number, by: number) {
        super();

        this.points = [
            {
                x: ax,
                y: ay,
                transformable: true
            },
            {
                x: bx,
                y: by,
                transformable: true
            }
        ];

        this.render();
    }

    protected calculateElements(): void {
        this.elements = [
            new SVGLine(this.points[0], this.points[1]),
            new SVGLine(this.points[1], this.points[2]),
            new SVGLine(this.points[1], this.points[3]),
            new SVGCircle(this.points[0]),
            new SVGCircle(this.points[1]),
            new SVGCircle(this.points[2]),
            new SVGCircle(this.points[3])
        ];
    }

    protected calculatePoints(): void {
        const angleOffset = Math.PI / 4;
        const distance = this.getProperty('width') * 2;

        const adjacent = this.points[1].x - this.points[0].x;
        const opposite = this.points[1].y - this.points[0].y;
        let angle = Math.atan(opposite / adjacent);

        angle = adjacent < 0 ? angle + Math.PI : angle;

        const arrowPointA = {
            x: this.points[1].x - distance * Math.cos(angle + angleOffset),
            y: this.points[1].y - distance * Math.sin(angle + angleOffset),
            transformable: false,
        };

        const arrowPointB = {
            x: this.points[1].x - distance * Math.cos(angle - angleOffset),
            y: this.points[1].y - distance * Math.sin(angle - angleOffset),
            transformable: false,
        };
        
        this.points = [
            this.points[0],
            this.points[1],
            arrowPointA,
            arrowPointB
        ];
    }

    getBounds(): ISVGBounds {
        return {
            ax: Math.min(...this.points.map(point => point.x)) - (this.getProperty('width') / 2),
            ay: Math.min(...this.points.map(point => point.y)) - (this.getProperty('width') / 2),
            bx: Math.max(...this.points.map(point => point.x)) + (this.getProperty('width') / 2),
            by: Math.max(...this.points.map(point => point.y)) + (this.getProperty('width') / 2)
        }
    }
}
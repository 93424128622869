import { SuiModal } from 'ng2-semantic-ui';

import { Component } from '@angular/core';

import IActionModalContext from './action.interface';

@Component({
	selector: 'app-modal-action',
	templateUrl: './action.modal.html',
})
export class ActionModalComponent {
	public title: string;
	public description: string;
	public action: string;

	constructor(public modal: SuiModal<IActionModalContext, void, void>) {
		this.title = this.modal.context.title;
		this.description = this.modal.context.description;
		this.action = this.modal.context.action;
	}
}

import { SuiModal } from 'ng2-semantic-ui';

import { Component } from '@angular/core';

import IConfirmModalContext from './confirm.interface';

@Component({
	selector: 'app-modal-confirm',
	templateUrl: './confirm.modal.html',
})
export class ConfirmModalComponent {
	public title: string;
	public description: string;

	constructor(public modal: SuiModal<IConfirmModalContext, void, void>) {
		this.title = this.modal.context.title;
		this.description = this.modal.context.description;
	}
}

import { ElementType } from '../../builder.enum';
import { IViewportDimensions, IElementStyleSheet, IElementProperty, IElementSVGProperty, IElement } from '../../builder.interface';
import { ISVG, } from '../../svg/svg.definition';
import { GenericElement } from '../generic/generic.element';

export class ShapeElement extends GenericElement {
	type: ElementType = ElementType.SHAPE;
	background: string = '#FFFFFF00';
	svg: ISVG;
	properties: { [key: string]: string; } = {};

	constructor(svg: ISVG) {
		super();

		this.svg = svg;

		// Load in SVG properties to element model
		for (const property in this.svg.properties) {
			this.properties[`svg::${property}`] = this.svg.getProperty(property);
		}

		this.svg.render();
	}

	clone(): IElement {
		this.svg.render();
		this.onRendered();
		return super.clone();
	}

	onRendered(): void {
		const bounds = this.svg.getBounds();
		const size = this.svg.getSize();

		this.position.x = bounds.ax;
		this.position.y = bounds.ay;
		this.size.width = size.width;
		this.size.height = size.height;
	}

	onPropertyChange(property: IElementProperty, value: string): void {
		if ('svg' in property) {
			this.svg.setProperty((property as IElementSVGProperty).svg, value);
			this.svg.render();
		}
	}

	generateCSS(editorViewport: IViewportDimensions, outputViewport: IViewportDimensions): IElementStyleSheet {
		const style: IElementStyleSheet = {};
		const heightRatio = editorViewport.height / outputViewport.height;
		const widthRatio = editorViewport.width / outputViewport.width;

		style['left'] = `${this.svg.getBounds().ax * widthRatio}px`;
		style['top'] = `${this.svg.getBounds().ay * heightRatio}px`;

		style['width'] = `${this.svg.getSize().width * widthRatio}px`;
		style['height'] = `${this.svg.getSize().height * heightRatio}px`;

		return { ...super.generateCSS(editorViewport, outputViewport),  ...style };
	}
}

import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { BuilderModalComponent } from './builder.modal';
var BuilderModal = /** @class */ (function (_super) {
    tslib_1.__extends(BuilderModal, _super);
    function BuilderModal(url, sessionToken, size) {
        if (size === void 0) { size = ModalSize.Normal; }
        var _this = _super.call(this, BuilderModalComponent, { url: url, sessionToken: sessionToken }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return BuilderModal;
}(ComponentModalConfig));
export { BuilderModal };

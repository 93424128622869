import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { IUnsavedChangeAwareComponent } from '../_interfaces/IUnsavedChangeAwareComponent';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<IUnsavedChangeAwareComponent> {
    canDeactivate(target: IUnsavedChangeAwareComponent): boolean {
        if (target.hasUnsavedChanges()) {
            return window.confirm('You have unsaved changes - are you sure you would like to leave this page? Your unsaved changes will be lost.');
        }
        return true;
    }
}
import { ElementType } from '../../builder.enum';
import { IElementProperty, IElementStyleSheet, IViewportDimensions } from '../../builder.interface';
import { GenericElement } from '../generic/generic.element';

export class TextElement extends GenericElement {
	type: ElementType = ElementType.TEXT;
	background = '#FFFFFF00';
	size: { width; height } = {
		width: 320,
		height: 80,
	};

	properties: { [key: string]: string } = {
		['font::type']: 'Roboto',
		['font::size']: '24',
		['font::color']: '#000000',
		['font::alignment']: 'center',
		['font::style']: 'normal',
		['font::weight']: 'normal',
		['font::underline']: 'none',
		['font::effect']: 'none',
		['font::effect-color']: '#000000',
		['font::effect-size']: '2',
	};

	constructor(value: string) {
		super();
		this.value = value;
	}

	onPropertyChange(property: IElementProperty, value: string): void {
		return;
	}

	generateOutlineCSS(color: string, width: number): string {
		const n = Math.ceil(2 * Math.PI * width)
		let shadows: string[] = [];
		for (let i = 0; i < n; i++) {
			const theta = 2 * Math.PI * i / n;
			shadows.push(
				width * Math.cos(theta) + "px " + (width * Math.sin(theta)) + "px 0 " + color
			);
		}

		return shadows.join(', ');
	}

	generateCSS(editorViewport: IViewportDimensions, outputViewport: IViewportDimensions): IElementStyleSheet {
		const fontStyle = {};

		const widthRatio = editorViewport.width / outputViewport.width;

		if (this.properties) {
			for (const property of Object.keys(this.properties)) {
				const value = this.properties[property];
				switch (property) {
					case 'font::alignment':
						fontStyle['display'] = 'flex';
						switch (value) {
							case 'right':
								fontStyle['justify-content'] = 'flex-end';
								fontStyle['align-items'] = 'center';
								fontStyle['text-align'] = 'right';
								break;
							case 'center':
								fontStyle['justify-content'] = 'center';
								fontStyle['align-items'] = 'center';
								fontStyle['text-align'] = 'center';
								break;
							case 'left':
								fontStyle['justify-content'] = 'flex-start';
								fontStyle['align-items'] = 'center';
								fontStyle['text-align'] = 'left';
								break;
							case 'topRight':
								fontStyle['justify-content'] = 'flex-end';
								fontStyle['align-items'] = 'flex-start';
								fontStyle['text-align'] = 'right';
								break;
							case 'top':
								fontStyle['justify-content'] = 'center';
								fontStyle['align-items'] = 'flex-start';
								fontStyle['text-align'] = 'center';
								break;
							case 'topLeft':
								fontStyle['justify-content'] = 'flex-start';
								fontStyle['align-items'] = 'flex-start';
								fontStyle['text-align'] = 'left';
								break;
							case 'bottomRight':
								fontStyle['justify-content'] = 'flex-end';
								fontStyle['align-items'] = 'flex-end';
								fontStyle['text-align'] = 'right';
								break;
							case 'bottom':
								fontStyle['justify-content'] = 'center';
								fontStyle['align-items'] = 'flex-end';
								fontStyle['text-align'] = 'center';
								break;
							case 'bottomLeft':
								fontStyle['justify-content'] = 'flex-start';
								fontStyle['align-items'] = 'flex-end';
								fontStyle['text-align'] = 'left';
								break;
						}
						break;
					case 'font::size':
						fontStyle['font-size'] = `${parseInt(value) * widthRatio}px`;
						break;
					case 'font::color':
						fontStyle['color'] = `${value}`;
						break;
					case 'font::style':
						fontStyle['font-style'] = `${value}`;
						break;
					case 'font::weight':
						fontStyle['font-weight'] = `${value}`;
						break;
					case 'font::underline':
						fontStyle['text-decoration'] = `${value}`;
						break;
					case 'font::effect':
						const effectSize = this.properties['font::effect-size'];
						const effectColor = this.properties['font::effect-color'];
						switch (value) {
							case 'none':
								break;
							case 'glow':
								fontStyle['text-shadow'] = `0px 0px ${parseInt(effectSize) * widthRatio}px ${effectColor}`;
								break;
							case 'outline':
								fontStyle['text-shadow'] = this.generateOutlineCSS(effectColor, parseInt(effectSize) * widthRatio);
								break;
						}
						break;
					case 'font::type':
						fontStyle['font-family'] = `"${value}", sans-serif`;
						break;
					case 'font::shadow-color':
						break;
				}
			}
		}

		return { ...fontStyle, ...super.generateCSS(editorViewport, outputViewport) };
	}
}

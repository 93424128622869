import { ElementType, ToolbarActionType } from '../builder.enum';
var FontTypeToolbarAction = /** @class */ (function () {
    function FontTypeToolbarAction() {
        this.type = 'font_type';
        this.label = 'Font';
        this.icon = 'font';
        this.tooltip = 'Assign a font';
        this.actionType = ToolbarActionType.DROPDOWN;
    }
    FontTypeToolbarAction.prototype.execute = function (context, type) {
        var activeElement = context.getActiveElement();
        if (!activeElement) {
            return;
        }
        var fontTypeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::type"]');
        context.setElementProperty(activeElement, fontTypeProperty, type);
    };
    FontTypeToolbarAction.prototype.getCurrentValue = function (context) {
        var fontTypeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::type"]');
        return context.getElementProperty(context.getActiveElement(), fontTypeProperty);
    };
    FontTypeToolbarAction.prototype.getToolbarContextualValue = function (context, type, option) {
        var _a;
        if (type === 'style') {
            return _a = {}, _a['font-family'] = option['value'] + ", sans-serif", _a;
        }
        return {};
    };
    FontTypeToolbarAction.prototype.getRelevantPropertyType = function (context) {
        return context.getPropertyType(ElementType.TEXT, 'properties["font::type"]');
    };
    return FontTypeToolbarAction;
}());
export { FontTypeToolbarAction };

import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import IFilePickerModalContext from './filePicker.interface';
import { FilePickerModalComponent } from './filePicker.modal';

export class FilePickerModal extends ComponentModalConfig<IFilePickerModalContext, void, void> {
	constructor(type: string, supportsMultiple: boolean = true, showUploadNew: boolean = true, sessionToken: string = '', size = ModalSize.Normal) {
		super(FilePickerModalComponent, { type, supportsMultiple, showUploadNew, sessionToken });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { ResourceViewModalComponent } from './resourceView.modal';
var ResourceViewModal = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceViewModal, _super);
    function ResourceViewModal(resource, size) {
        if (size === void 0) { size = ModalSize.Normal; }
        var _this = _super.call(this, ResourceViewModalComponent, { resource: resource }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return ResourceViewModal;
}(ComponentModalConfig));
export { ResourceViewModal };

import { ElementPropertyDisplayType, ElementPropertyValueType } from '../../builder.enum';
import { IElement, IElementProperty, IElementPropertyEnumOption } from '../../builder.interface';

export class VideoValueProperty implements IElementProperty {
	label: string = 'Video';
	value: string = 'value';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.FILE;
	accept: string = 'video/*';
}

export class VideoMutedProperty implements IElementProperty {
	label: string = 'Sound';
	value: string = 'properties["video::muted"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'Sound off', value: 'true' },
		{ label: 'Sound on', value: 'false' },
	];
}

export class VideoCropProperty implements IElementProperty {
	label: string = 'Crop';
	value: string = 'properties["video::crop"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;
	options: { label: string; value: string }[] = [
		{ label: 'Cover', value: 'cover' },
		{ label: 'Fit', value: 'fit' }
	];
}

import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { BuildingModalComponent } from './building.modal';
var BuildingModal = /** @class */ (function (_super) {
    tslib_1.__extends(BuildingModal, _super);
    function BuildingModal(creation, size) {
        if (size === void 0) { size = ModalSize.Tiny; }
        var _this = _super.call(this, BuildingModalComponent, { creation: creation }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return BuildingModal;
}(ComponentModalConfig));
export { BuildingModal };

import { NgPipesModule } from 'angular-pipes';
import { NotificationsService, SimpleNotificationsModule } from 'angular2-notifications';
import { SuiModule } from 'ng2-semantic-ui';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieService } from 'ngx-cookie-service';
import { FileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'ngx-moment';
import { UnauthenticatedResolver } from 'src/_resolvers/unauthenticated.resolver';
import { AdminService } from 'src/_services/admin.service';
import { DeploymentService } from 'src/_services/deployment.service';
import { UserService } from 'src/_services/user.service';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BuilderService } from '@citadel/common-frontend/_services/builder.service';
import { FilesystemService } from '@citadel/common-frontend/_services/filesystem.service';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { NotificationService } from '@citadel/common-frontend/_services/notification.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { SharedModule } from '@citadel/common-frontend/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AuthenticatedResolver } from '../_resolvers/authenticated.resolver';
import { VersionService } from '../_services/version.service';
import { AppComponent } from './app.component';
import { routes } from './app.routes';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		HttpClientModule,
		RouterModule.forRoot(routes),
		// library modules
		SimpleNotificationsModule.forRoot(),
		FileDropModule,
		SuiModule,
		MomentModule,
		NgPipesModule,
		SharedModule,
		NgxChartsModule,
		ContextMenuModule.forRoot(),
	],
	providers: [
		CookieService,
		SocketService,
		VersionService,
		NotificationService,
		UserService,
		AdminService,
		LoaderService,
		NotificationsService,
		NotificationService,
		FilesystemService,
		DeploymentService,
		BuilderService,
		AuthenticatedResolver,
		UnauthenticatedResolver,
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
})
export class AppModule { }

import { UserService } from 'src/_services/user.service';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { AnalyticsService } from '@citadel/common-frontend/_services/analytics.service';

import { VersionService } from '../_services/version.service';

let loaded = false;

@Injectable()
export class UnauthenticatedResolver implements Resolve<any> {
	constructor(private socket: SocketService, private analytics: AnalyticsService, private version: VersionService, private user: UserService) { }

	resolve(route: ActivatedRouteSnapshot): Promise<any> {
		const promise = new Promise(async (resolve, reject) => {
			if (loaded) {
				resolve(undefined);
				return;
			}

			await this.socket.init(environment.api.url, null).toPromise();
			await this.analytics.init(environment.analytics.google.id).toPromise();

			const completion = Promise.all([this.version.init().toPromise(), this.user.init(false).toPromise()]);
			completion
				.then(() => {
					// hacky but necessary
					const element = document.getElementById('cdm-preload');
					if (element) {
						element.remove();
					}

					loaded = true;
					resolve(undefined);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return promise;
	}
}

export function markForChange() {
	loaded = false;
}

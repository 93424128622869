import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { NewDirectoryModalComponent } from './newDirectory.modal';
var NewDirectoryModal = /** @class */ (function (_super) {
    tslib_1.__extends(NewDirectoryModal, _super);
    function NewDirectoryModal(parent, type, size) {
        if (parent === void 0) { parent = null; }
        if (type === void 0) { type = 'user'; }
        if (size === void 0) { size = ModalSize.Small; }
        var _this = _super.call(this, NewDirectoryModalComponent, { parent: parent, type: type }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return NewDirectoryModal;
}(ComponentModalConfig));
export { NewDirectoryModal };

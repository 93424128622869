import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(document) {
        this.document = document;
        this.window = this.document.defaultView;
    }
    AnalyticsService.prototype.init = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!id) {
                observer.next();
                observer.complete();
                return;
            }
            var script = _this.document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
            _this.document.head.appendChild(script);
            _this.window['dataLayer'] = _this.window['dataLayer'] || [];
            _this.window['gtag'] = function () {
                this.window['dataLayer'].push(arguments);
            };
            _this.window['gtag']('js', new Date());
            _this.window['gtag']('config', id);
            observer.next();
            observer.complete();
        });
    };
    AnalyticsService.prototype.sendEvent = function (event, parameters) {
        this.window['gtag']('event', event, parameters);
    };
    AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.DOCUMENT)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };

import * as tslib_1 from "tslib";
import { ElementType } from '../../builder.enum';
import { GenericElement } from '../generic/generic.element';
var VideoElement = /** @class */ (function (_super) {
    tslib_1.__extends(VideoElement, _super);
    function VideoElement(value, width, height) {
        var _a;
        if (width === void 0) { width = 300; }
        if (height === void 0) { height = 300; }
        var _this = _super.call(this) || this;
        _this.type = ElementType.VIDEO;
        _this.background = '#FFFFFF00';
        _this.size = {
            width: 600,
            height: 300,
        };
        _this.properties = (_a = {},
            _a['video::muted'] = 'true',
            _a['video::crop'] = 'cover',
            _a);
        _this.value = value;
        _this.size.width = width;
        _this.size.height = height;
        return _this;
    }
    VideoElement.prototype.generateVideoCSS = function (editorViewport, outputViewport) {
        var style = {};
        if (this.properties) {
            for (var _i = 0, _a = Object.keys(this.properties); _i < _a.length; _i++) {
                var property = _a[_i];
                var value = this.properties[property];
                switch (property) {
                    case 'video::crop':
                        style['display'] = 'flex';
                        switch (value) {
                            case 'fit':
                                style['object-fit'] = 'contain';
                                break;
                            case 'cover':
                                style['object-fit'] = 'cover';
                                break;
                        }
                }
            }
        }
        return style;
    };
    VideoElement.prototype.generateCSS = function (editorViewport, outputViewport) {
        var style = {};
        return tslib_1.__assign({}, style, _super.prototype.generateCSS.call(this, editorViewport, outputViewport));
    };
    VideoElement.prototype.onPropertyChange = function (property, value) {
        return;
    };
    return VideoElement;
}(GenericElement));
export { VideoElement };

export function runCollectionFilter(collection: any[], filter: any, page: number = 0, pageSize: number = 10e9) {
	const hasValuesToFilter = !!Object.keys(filter).find((key) => !!filter[key]);

	let filteredCollection;
	if (hasValuesToFilter) {
		filteredCollection = collection.filter((resource) => {
			for (const key of Object.keys(filter)) {
				const value = filter[key];
				if (!value || !resource[key]) {
					continue;
				}
				const trimmed = value.trim().toLowerCase();
				if (trimmed.length === 0) {
					continue;
				}

				const contains = resource[key].toLowerCase().indexOf(trimmed) !== -1;
				if (!contains) {
					return false;
				}
			}
			return true;
		});
	} else {
		filteredCollection = collection;
	}

	const originalFilteredLength = filteredCollection.length;
	const startIndex = page * pageSize;
	filteredCollection = filteredCollection.slice(startIndex, startIndex + pageSize);

	return {
		data: filteredCollection,
		totalAmount: originalFilteredLength,
	};
}

export function createClampInterval(min: number, max: number, points: number[], offset: number = 0) {
  const interval = [];
  const range = max - min;
  for (let point of points) {
    interval.push(min + (range * point));
  }
  return {
    offset: offset,
    values: interval,
    points: points
  };
}

export function clampOnInterval(interval: any, n: number, clamp: number): any[] {
  const { values, points, offset } = interval;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    const point = points[i];

    let difference = Math.abs((n + offset) - value);
    if (difference < clamp) {
      return [value - offset, point];
    }
  }
  return [n, undefined];
}

export function checkForClamps(intervals: any[], n: number, clamp: number) {
  for (let interval of intervals) {
    const [clampedValue, clampedPoint] = clampOnInterval(interval, n, clamp);
    if (clampedPoint !== undefined) {
      return [clampedValue, clampedPoint];
    }
  }
  return [n, undefined];
}

export function roundToNearest(value: number, scale: number) {
	return Math.round(value / scale) * scale;
}

export function clamp(value: number, min: number, max: number) {
	return Math.min(Math.max(value, min), max);
}

export function sanitizeSeriesDates(data: any[]) {
	for (const type of data) {
		const series = type.series;
		for (const datapoint of series) {
			datapoint.name = new Date(datapoint.name);
		}
	}
	return data;
}

export function isSharedResource(resource: any, myId: string, isAdmin: boolean) {
	if (isAdmin) {
		return false;
	}

	if (!resource) {
		return false;
	}

	if (!resource.user) {
		return false;
	}

	return resource.user !== myId;
}

export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function downloadLocalResourceToDisk(data: string, name: string) {
	const a = document.createElement('a');
	a.download = name;
	a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;
	document.body.appendChild(a);
	a.click();
	a.remove();
}

export async function downloadResourceToDisk(url: string, name: string) {
	return await downloadResourceToMemory(url).then((blob) => {
		const blobUrl = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.download = name;
		a.href = blobUrl;
		document.body.appendChild(a);
		a.click();
		a.remove();
	});
}

export async function downloadResourceToMemory(url: string) {
	return await fetch(url, {
		headers: new Headers({
			Origin: location.origin,
		}),
		mode: 'cors',
	})
		.then((response) => response.blob())
		.then((blob) => {
			return blob;
		});
}

export function sortByKey(array: any[], key: string, direction: string) {
	const items = array.sort(function (a, b) {
		const aValue = a[key] || '';
		const bValue = b[key] || '';

		if (aValue < bValue) {
			return -1;
		}
		if (aValue > bValue) {
			return 1;
		}
		return 0;
	});

	if (direction === 'desc') {
		return items.reverse();
	}

	return items;
}

export function isValidDate(date: Date) {
	return new Date(date).toString() !== 'Invalid Date';
}

export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(() => resolve(undefined), ms));
}

export function naiveDeepCopy(target: object, source: object): object {
  for (let key of Object.keys(source)) {
    let value = source[key];
    if (typeof value === 'object') {
      // Now you're thinking with portals
      target[key] = naiveDeepCopy({ ...target[key] }, source[key]);
    } else {
      target[key] = value;
    }
  }

  return target;
}

export function naiveDeepEqualityCheck(target: object, source: object): boolean {
	for (let key of Object.keys(source)) {
	  let value = source[key];

	  if (typeof value === 'object') {
		if (!naiveDeepEqualityCheck(target[key], source[key])) {
			return false;
		}
	  } else {
		if (target[key] !== source[key]) {
			console.log(`${key} - ${target[key]} is not equal to ${source[key]}`);
			return false;
		}
	  }
	}
  
	return true;
  }
  
import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class FontSizeToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'font_size';
	label: string = 'Font size';
	icon: string = 'text height';
	tooltip: string = 'Change your font size';
	actionType: ToolbarActionType = ToolbarActionType.INPUT;

	execute(context: IVideoBuilderContext, size: number): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontSizeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::size"]');

		context.setElementProperty(activeElement, fontSizeProperty, size);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const fontSizeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::size"]');
		return context.getElementProperty(context.getActiveElement(), fontSizeProperty);
	}
}

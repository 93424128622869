import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class FontTypeToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'font_type';
	label: string = 'Font';
	icon: string = 'font';
	tooltip: string = 'Assign a font';
	actionType: ToolbarActionType = ToolbarActionType.DROPDOWN;

	execute(context: IVideoBuilderContext, type: string): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontTypeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::type"]');

		context.setElementProperty(activeElement, fontTypeProperty, type);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const fontTypeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::type"]');

		return context.getElementProperty(context.getActiveElement(), fontTypeProperty);
	}

	getToolbarContextualValue(context: IVideoBuilderContext, type: string, option?: any): any {
		if (type === 'style') {
			return { ['font-family']: `${option['value']}, sans-serif` };
		}
		return {};
	}

	getRelevantPropertyType(context: IVideoBuilderContext): any {
		return context.getPropertyType(ElementType.TEXT, 'properties["font::type"]')
	}
}

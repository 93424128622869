import { NotificationsService } from 'angular2-notifications';

import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WaveformService {
    private waveformInstances: WaveSurfer[] = [];

	constructor() {}

	public registerWaveformInstance(instance: WaveSurfer): void {
		this.waveformInstances.push(instance);
	}

	public stopAllPlayback(): void {
        for (const waveformInstance of this.waveformInstances) {
            if (!waveformInstance) {
                continue;
            }
            waveformInstance.stop();
        }
	}
}

import * as tslib_1 from "tslib";
import { SVGType } from "../svg.definition";
import SVGGeneric from "./generic.definition";
import { SVGCircle as SVGCircleElement } from '../elements/circle.definition';
var SVGCircle = /** @class */ (function (_super) {
    tslib_1.__extends(SVGCircle, _super);
    function SVGCircle(x, y, radius) {
        var _a;
        var _this = _super.call(this) || this;
        _this.type = SVGType.CIRCLE;
        _this.properties = (_a = {},
            _a['fill'] = 'black',
            _a['stroke'] = '#042e41',
            _a['width'] = 4,
            _a);
        _this.points = [
            {
                x: x,
                y: y,
                transformable: false
            },
            {
                x: x + radius,
                y: y,
                transformable: true
            }
        ];
        _this.render();
        return _this;
    }
    SVGCircle.prototype.calculateElements = function () {
        this.elements = [
            new SVGCircleElement(this.points[0], this.points[1])
        ];
    };
    SVGCircle.prototype.calculatePoints = function () {
        return;
    };
    SVGCircle.prototype.calculateRadius = function () {
        return Math.sqrt(Math.pow(this.points[0].x - this.points[1].x, 2) +
            Math.pow(this.points[0].y - this.points[1].y, 2));
    };
    SVGCircle.prototype.getBounds = function () {
        return {
            ax: this.points[0].x - this.calculateRadius(),
            ay: this.points[0].y - this.calculateRadius(),
            bx: this.points[0].x + this.calculateRadius(),
            by: this.points[0].y + this.calculateRadius(),
        };
    };
    return SVGCircle;
}(SVGGeneric));
export default SVGCircle;

<div class="header">Deleting {{ type }}</div>
<div class="content">
  <div class="ui icon warning message">
    <i class="exclamation icon"></i>
    <div class="content">
      <div class="header">
        Warning - deletions are permanent
      </div>
      <p>Are you sure you want to delete {{ label }}?</p>
      <ng-container *ngIf="requiresConfirmation"
        ><p>To confirm this deletion, please type <b>{{ label }}</b> below.</p></ng-container
      >
    </div>
  </div>

	<ng-container *ngIf="requiresConfirmation">
		<div class="ui input cdm-input">
			<input type="text" [(ngModel)]="confirmValue" placeholder="{{ label }}" />
		</div>
	</ng-container>
</div>
<div class="actions">
	<button class="ui button" (click)="modal.deny(undefined)">Cancel</button>
	<button class="ui red button" [ngClass]="{ disabled: !isTypedProperly() }" (click)="delete()" autofocus>Delete</button>
</div>

export enum TransformHandle {
	TOP_LEFT = 0,
	TOP_RIGHT = 1,
	BOTTOM_LEFT = 2,
	BOTTOM_RIGHT = 3,
}

export enum SavedProjectType {
	PROJECT = 'project',
	TEMPLATE = 'template'
}

export enum ElementType {
	TEXT = 'text',
	IMAGE = 'image',
	VIDEO = 'video',
	SHAPE = 'shape',
	ANY = '*',
}

export enum ElementPropertyValueType {
	FILE = 'file',
	STRING = 'string',
	NUMBER = 'number',
	COLOR = 'color',
	ENUM = 'enum',
}

export enum ElementPropertyDisplayType {
	FULL_WIDTH = 'full',
	FIT_WIDTH = 'fit',
  	NONE = 'none'
}

export enum ToolbarActionType {
	BUTTON = 'button',
	COLOR = 'color',
	DROPDOWN = 'dropdown',
	INPUT = 'input',
	FILE = 'file'
}

export enum AnimationType {
	NONE = '',
	FADE = 'fade',
	REVEAL = 'reveal',
	WIPE_LEFT = 'wipeLeft',
	WIPE_RIGHT = 'wipeRight',
	SLIDE_LEFT = 'slideLeft',
	SLIDE_RIGHT = 'slideRight',
	SLIDE_UP = 'slideUp',
	SLIDE_DOWN = 'slideDown',
	CAROUSEL_LEFT = 'carouselLeft',
	CAROUSEL_RIGHT = 'carouselRight',
	CAROUSEL_UP = 'carouselUp',
	CAROUSEL_DOWN = 'carouselDown',
	ZOOM = 'zoom'
}

export enum AnimationSpeed {
	NORMAL = '',
	FAST = 'Fast',
	SLOW = 'Slow'
}

export enum AnimationTriggerType {
	NONE = '',
	DELAY = 'delay',
	INITIAL = 'initial',
	CONCURRENT = 'concurrent'
}

export enum SlideTransition {
	NONE = '',
	BLOCKS = 'blocks',
	LINES = 'lines',
	RADIAL = 'radial'
}

export enum ProjectOrientation {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal'
}

export enum ProjectResolution {
	HD = 'hd',
	FULL_HD = 'fhd'
}
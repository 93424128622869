import { DomSanitizer } from '@angular/platform-browser';

import { ISVGBounds, SVGType } from "../svg.definition";
import SVGGeneric from "./generic.definition";
import { SVGCircle as SVGCircleElement } from '../elements/circle.definition';

export default class SVGCircle extends SVGGeneric {
    type = SVGType.CIRCLE;

    properties: { [key: string]: any; } = {
        ['fill']: 'black',
        ['stroke']: '#042e41',
        ['width']: 4
    };

    constructor(x: number, y: number, radius: number) {
        super();

        this.points = [
            {
                x: x,
                y: y,
                transformable: false
            },
            {
                x: x + radius,
                y: y,
                transformable: true
            }
        ];

        this.render();
    }

    protected calculateElements(): void {
        this.elements = [
            new SVGCircleElement(
                this.points[0],
                this.points[1]
            )
        ];
    }

    protected calculatePoints(): void {
        return;
    }

    private calculateRadius(): number {
        return Math.sqrt(
            Math.pow(this.points[0].x - this.points[1].x, 2) + 
            Math.pow(this.points[0].y - this.points[1].y, 2)
        );
    }

    getBounds(): ISVGBounds {
        return {
            ax: this.points[0].x - this.calculateRadius(),
            ay: this.points[0].y - this.calculateRadius(),
            bx: this.points[0].x + this.calculateRadius(),
            by: this.points[0].y + this.calculateRadius(),
        }
    }
}
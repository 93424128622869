import svg from 'svg-builder';
var SVGGeneric = /** @class */ (function () {
    function SVGGeneric() {
    }
    SVGGeneric.prototype.render = function () {
        this.calculatePoints();
        this.calculateElements();
        var size = this.getSize();
        var builder = svg.newInstance().width(size.width).height(size.height);
        for (var _i = 0, _a = this.elements; _i < _a.length; _i++) {
            var element = _a[_i];
            builder = element.generate(builder, this);
        }
        this.rendered = builder.render();
        return this.rendered;
    };
    SVGGeneric.prototype.validateBounds = function (width, height) {
        var bounds = this.getBounds();
        var size = this.getSize();
        var currentOrigin = this.getOriginPoint();
        if (bounds.ax < 0) {
            this.changeOrigin(0, currentOrigin.y);
        }
        if (bounds.ay < 0) {
            this.changeOrigin(currentOrigin.x, 0);
        }
        if (bounds.bx >= width) {
            this.changeOrigin(width - size.width, currentOrigin.y);
        }
        if (bounds.by >= height) {
            this.changeOrigin(currentOrigin.x, height - size.height);
        }
    };
    SVGGeneric.prototype.getOriginPoint = function () {
        var lowestPoint = this.points[0];
        for (var _i = 0, _a = this.points; _i < _a.length; _i++) {
            var point = _a[_i];
            if (!point.transformable) {
                continue;
            }
            if (point.x <= lowestPoint.x && point.y <= lowestPoint.y) {
                lowestPoint = point;
            }
        }
        return lowestPoint;
    };
    SVGGeneric.prototype.changeOrigin = function (x, y) {
        var origin = this.getOriginPoint();
        for (var _i = 0, _a = this.points; _i < _a.length; _i++) {
            var point = _a[_i];
            if (point === origin) {
                continue;
            }
            var currentOffsetX = point.x - origin.x;
            var currentOffsetY = point.y - origin.y;
            point.x = x + currentOffsetX;
            point.y = y + currentOffsetY;
        }
        origin.x = x;
        origin.y = y;
    };
    SVGGeneric.prototype.getSize = function () {
        return {
            width: Math.abs(this.getBounds().bx - this.getBounds().ax),
            height: Math.abs(this.getBounds().by - this.getBounds().ay),
        };
    };
    SVGGeneric.prototype.getPoints = function () {
        return this.points;
    };
    SVGGeneric.prototype.getProperty = function (key) {
        return this.properties[key];
    };
    SVGGeneric.prototype.setProperty = function (key, value) {
        this.properties[key] = value;
    };
    return SVGGeneric;
}());
export default SVGGeneric;

import { ElementType, ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class ToggleItalicToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'toggle_italic';
	icon: string = 'italic';
	tooltip: string = 'Toggle italic effect';
  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontStyleProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::style"]');

		const currentPropertyValue = activeElement.properties['font::style'];

		if (currentPropertyValue === 'italic') {
			context.setElementProperty(activeElement, fontStyleProperty, 'normal');
		} else {
			context.setElementProperty(activeElement, fontStyleProperty, 'italic');
		}
	}
}

<div class="header">{{ header }}</div>
<div class="content">
  <div class="ui message" *ngIf="message">
    <div class="header">
      {{ message.header }}
    </div>
    <p>{{ message.text }}</p>
  </div>
  <div class="ui form">
    <div class="field">
      <label>{{ input.label }}</label>
      <div class="ui input fluid">
        <input type="text" name="name" [(ngModel)]="value" />
      </div>
    </div>
  </div>
</div>
<div class="actions">
	<button class="ui button" (click)="modal.deny(undefined)">Cancel</button>
	<button app-tour="input-modal-next" class="ui button primary" (click)="save()" autofocus [disabled]="!hasValue()">Next</button>
</div>

import * as tslib_1 from "tslib";
import { ElementType } from '../../builder.enum';
import { GenericElement } from '../generic/generic.element';
var TextElement = /** @class */ (function (_super) {
    tslib_1.__extends(TextElement, _super);
    function TextElement(value) {
        var _a;
        var _this = _super.call(this) || this;
        _this.type = ElementType.TEXT;
        _this.background = '#FFFFFF00';
        _this.size = {
            width: 320,
            height: 80,
        };
        _this.properties = (_a = {},
            _a['font::type'] = 'Roboto',
            _a['font::size'] = '24',
            _a['font::color'] = '#000000',
            _a['font::alignment'] = 'center',
            _a['font::style'] = 'normal',
            _a['font::weight'] = 'normal',
            _a['font::underline'] = 'none',
            _a['font::effect'] = 'none',
            _a['font::effect-color'] = '#000000',
            _a['font::effect-size'] = '2',
            _a);
        _this.value = value;
        return _this;
    }
    TextElement.prototype.onPropertyChange = function (property, value) {
        return;
    };
    TextElement.prototype.generateOutlineCSS = function (color, width) {
        var n = Math.ceil(2 * Math.PI * width);
        var shadows = [];
        for (var i = 0; i < n; i++) {
            var theta = 2 * Math.PI * i / n;
            shadows.push(width * Math.cos(theta) + "px " + (width * Math.sin(theta)) + "px 0 " + color);
        }
        return shadows.join(', ');
    };
    TextElement.prototype.generateCSS = function (editorViewport, outputViewport) {
        var fontStyle = {};
        var widthRatio = editorViewport.width / outputViewport.width;
        if (this.properties) {
            for (var _i = 0, _a = Object.keys(this.properties); _i < _a.length; _i++) {
                var property = _a[_i];
                var value = this.properties[property];
                switch (property) {
                    case 'font::alignment':
                        fontStyle['display'] = 'flex';
                        switch (value) {
                            case 'right':
                                fontStyle['justify-content'] = 'flex-end';
                                fontStyle['align-items'] = 'center';
                                fontStyle['text-align'] = 'right';
                                break;
                            case 'center':
                                fontStyle['justify-content'] = 'center';
                                fontStyle['align-items'] = 'center';
                                fontStyle['text-align'] = 'center';
                                break;
                            case 'left':
                                fontStyle['justify-content'] = 'flex-start';
                                fontStyle['align-items'] = 'center';
                                fontStyle['text-align'] = 'left';
                                break;
                            case 'topRight':
                                fontStyle['justify-content'] = 'flex-end';
                                fontStyle['align-items'] = 'flex-start';
                                fontStyle['text-align'] = 'right';
                                break;
                            case 'top':
                                fontStyle['justify-content'] = 'center';
                                fontStyle['align-items'] = 'flex-start';
                                fontStyle['text-align'] = 'center';
                                break;
                            case 'topLeft':
                                fontStyle['justify-content'] = 'flex-start';
                                fontStyle['align-items'] = 'flex-start';
                                fontStyle['text-align'] = 'left';
                                break;
                            case 'bottomRight':
                                fontStyle['justify-content'] = 'flex-end';
                                fontStyle['align-items'] = 'flex-end';
                                fontStyle['text-align'] = 'right';
                                break;
                            case 'bottom':
                                fontStyle['justify-content'] = 'center';
                                fontStyle['align-items'] = 'flex-end';
                                fontStyle['text-align'] = 'center';
                                break;
                            case 'bottomLeft':
                                fontStyle['justify-content'] = 'flex-start';
                                fontStyle['align-items'] = 'flex-end';
                                fontStyle['text-align'] = 'left';
                                break;
                        }
                        break;
                    case 'font::size':
                        fontStyle['font-size'] = parseInt(value) * widthRatio + "px";
                        break;
                    case 'font::color':
                        fontStyle['color'] = "" + value;
                        break;
                    case 'font::style':
                        fontStyle['font-style'] = "" + value;
                        break;
                    case 'font::weight':
                        fontStyle['font-weight'] = "" + value;
                        break;
                    case 'font::underline':
                        fontStyle['text-decoration'] = "" + value;
                        break;
                    case 'font::effect':
                        var effectSize = this.properties['font::effect-size'];
                        var effectColor = this.properties['font::effect-color'];
                        switch (value) {
                            case 'none':
                                break;
                            case 'glow':
                                fontStyle['text-shadow'] = "0px 0px " + parseInt(effectSize) * widthRatio + "px " + effectColor;
                                break;
                            case 'outline':
                                fontStyle['text-shadow'] = this.generateOutlineCSS(effectColor, parseInt(effectSize) * widthRatio);
                                break;
                        }
                        break;
                    case 'font::type':
                        fontStyle['font-family'] = "\"" + value + "\", sans-serif";
                        break;
                    case 'font::shadow-color':
                        break;
                }
            }
        }
        return tslib_1.__assign({}, fontStyle, _super.prototype.generateCSS.call(this, editorViewport, outputViewport));
    };
    return TextElement;
}(GenericElement));
export { TextElement };

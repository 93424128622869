import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private window: Window;

	constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  public init(id: string | undefined): Observable<any> {
    return new Observable((observer) => {
      if (!id) {
        observer.next();
        observer.complete();
        return;
      }

      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

      this.document.head.appendChild(script);

      this.window['dataLayer'] = this.window['dataLayer'] || [];

      this.window['gtag'] = function () {
        this.window['dataLayer'].push(arguments);
      }

      this.window['gtag']('js', new Date());
      this.window['gtag']('config', id);

      observer.next();
			observer.complete();
		});
  }

  public sendEvent(event: string, parameters: any) {
    this.window['gtag']('event', event, parameters);
  }
}

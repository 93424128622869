import { SuiModal } from 'ng2-semantic-ui';

import { Component } from '@angular/core';

import { IVideoScaffold } from '../../_components/builder/builder.interface';
import { BuilderService } from '../../_services/builder.service';
import { NotificationService } from '../../_services/notification.service';
import { sleep } from '../../_util/functions';
import IResourceModalContext from './building.interface';

@Component({
	selector: 'app-modal-building',
	templateUrl: './building.modal.html',
	styleUrls: ['./building.modal.scss'],
})
export class BuildingModalComponent {
	public steps: { step: string; complete: boolean }[] = [
		{ step: 'sending', complete: false },
		{ step: 'queued', complete: false },
		{ step: 'fetching', complete: false },
		{ step: 'rendering', complete: false },
		{ step: 'done', complete: false },
	];
	public creation: IVideoScaffold;

	constructor(private builder: BuilderService, private notification: NotificationService, public modal: SuiModal<IResourceModalContext, string, void>) {
		this.creation = this.modal.context.creation;

		this.runStatusCheckLoop();
	}

	private markStatusComplete(status: string): void {
		const currentIndex = this.steps.findIndex((step) => step.step === status);
		for (let i = 0; i < currentIndex; i++) {
			this.steps[i].complete = true;
		}
	}

	public getCurrentStepIndex(): number {
		const currentStep = this.steps.findIndex((step) => !step.complete);
		if (currentStep === -1) {
			return this.steps.length + 1;
		} else {
			return currentStep;
		}
	}

	private async runStatusCheckLoop() {
		this.requestBuild(this.creation)
			.then((id) => {
				this.generateVideo(id);
			})
			.catch((error) => {
				this.modal.deny(undefined);
				this.notification.displayError(error);
			});
	}

	private async generateVideo(id) {
		this.markStatusComplete('sending');
		let status: string;
		do {
			await sleep(1000);

      try {
        status = await this.getVideoBuildStatus(id);
      } catch (e) {
        console.error(e);
        status = 'failed';
      }

			this.markStatusComplete(status);
			if (status === 'failed') {
				this.notification.displayError('An error occurred processing your video');
				this.modal.deny(undefined);
				return;
			}
		} while (status !== 'done');

		await sleep(500);

		await this.submit(id);
	}

	private async requestBuild(config: IVideoScaffold): Promise<string> {
		return await this.builder.generateVideo(this.creation).toPromise();
	}

	private async getVideoBuildStatus(id: string): Promise<string> {
		return await this.builder.checkVideoStatus(id).toPromise();
	}

	public async submit(id: string) {
		const url: string = await this.builder.getVideoLink(id).toPromise();
		this.modal.approve(url);
	}
}

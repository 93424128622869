import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import IBuilderModalContext from './builder.interface';
import { BuilderModalComponent } from './builder.modal';

export class BuilderModal extends ComponentModalConfig<IBuilderModalContext, void, void> {
	constructor(url: string, sessionToken: string, size = ModalSize.Normal) {
		super(BuilderModalComponent, { url, sessionToken });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

import { ElementType } from '../../builder.enum';
import { IElementProperty, IElementStyleSheet, IViewportDimensions } from '../../builder.interface';
import { GenericElement } from '../generic/generic.element';

export class VideoElement extends GenericElement {
	type: ElementType = ElementType.VIDEO;
	background: string = '#FFFFFF00';

	size: { width; height } = {
		width: 600,
		height: 300,
	};

	properties: { [key: string]: string } = {
		['video::muted']: 'true',
		['video::crop']: 'cover'
	};

	constructor(value: string, width: number = 300, height: number = 300) {
		super();
		this.value = value;
		this.size.width = width;
		this.size.height = height;
	}

	generateVideoCSS(editorViewport: IViewportDimensions, outputViewport: IViewportDimensions): IElementStyleSheet {
		const style: IElementStyleSheet = {};

		if (this.properties) {
			for (const property of Object.keys(this.properties)) {
				const value = this.properties[property];
				switch (property) {
					case 'video::crop':
						style['display'] = 'flex';
						switch (value) {
							case 'fit':
								style['object-fit'] = 'contain';
								break;
							case 'cover':
								style['object-fit'] = 'cover';
								break;
						}
				}
			}
		}

		return style;
	}

	generateCSS(editorViewport: IViewportDimensions, outputViewport: IViewportDimensions): IElementStyleSheet {
		const style: IElementStyleSheet = {};

		return { ...style, ...super.generateCSS(editorViewport, outputViewport) };
	}

	onPropertyChange(property: IElementProperty, value: string): void {
		return;
	}
}

import * as tslib_1 from "tslib";
import { SVGType } from "../svg.definition";
import SVGGeneric from "./generic.definition";
import { SVGRect } from '../elements/rect.definition';
var SVGBox = /** @class */ (function (_super) {
    tslib_1.__extends(SVGBox, _super);
    function SVGBox(x, y, size) {
        var _a;
        var _this = _super.call(this) || this;
        _this.type = SVGType.BOX;
        _this.properties = (_a = {},
            _a['fill'] = 'black',
            _a['stroke'] = '#ffffff',
            _a['width'] = 0,
            _a);
        _this.points = [
            {
                x: x,
                y: y,
                transformable: true
            },
            {
                x: x + size,
                y: y + size,
                transformable: true
            }
        ];
        _this.render();
        return _this;
    }
    SVGBox.prototype.calculateElements = function () {
        this.elements = [
            new SVGRect(this.points[0], this.points[1])
        ];
    };
    SVGBox.prototype.calculatePoints = function () {
        return;
    };
    SVGBox.prototype.getBounds = function () {
        return {
            ax: Math.min.apply(Math, this.points.map(function (point) { return point.x; })) - (this.getProperty('width') / 2),
            ay: Math.min.apply(Math, this.points.map(function (point) { return point.y; })) - (this.getProperty('width') / 2),
            bx: Math.max.apply(Math, this.points.map(function (point) { return point.x; })) + (this.getProperty('width') / 2),
            by: Math.max.apply(Math, this.points.map(function (point) { return point.y; })) + (this.getProperty('width') / 2)
        };
    };
    return SVGBox;
}(SVGGeneric));
export default SVGBox;

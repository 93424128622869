<div class="header">Viewing {{ resource.name }}</div>
<div class="content">
	<ng-container *ngIf="resource.cdnUrl && isImage()">
		<img [src]="resource.cdnUrl" />
	</ng-container>
	<ng-container *ngIf="resource.cdnUrl && isVideo()">
		<video controls autoplay>
			<source [src]="resource.cdnUrl" type="video/mp4" />
		</video>
	</ng-container>
	<ng-container *ngIf="resource.cdnUrl && isAudio()">
		<app-waveform [url]="resource.cdnUrl" [controls]="true" [interactable]="true"></app-waveform>
	</ng-container>
	<ng-container *ngIf="resource.cdnUrl && isPDF()">
		<iframe [src]="resource.cdnUrl | safe"></iframe>
	</ng-container>
	<ng-container *ngIf="!canPreview()">
		<span class="cdm-resource-view__no-preview"><i class="icon circle info"></i>Cannot preview this file ({{resource.mimeType}})</span>
	</ng-container>
</div>
<div class="actions">
	<button class="ui button" (click)="modal.approve(undefined)">Close</button>
</div>

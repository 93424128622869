<div class="content">
	<h1><i class="icon check"></i> Video generated</h1>

	<div class="cdm-builder-green-screen" [ngClass]="{ 'cdm-builder-green-screen--loading': !readyToPlay }">
		<img src="../../assets/video-green-screen.png" />
		<video #video loop muted autoplay (canplay)="onCanPlay()" onloadedmetadata="this.muted = true">
			<source [src]="url" type="video/mp4" />
		</video>
	</div>
	<div class="ui buttons">
		<button [disabled]="hasDownloaded" class="ui button primary cdm-builder-download" (click)="download()"><i class="download icon"></i>Download Video</button>
		<button [disabled]="hasAddedToLibrary" class="ui button primary cdm-builder-download" (click)="addToLibrary()"><i class="upload icon"></i>Add to library</button>
	</div>
</div>
<div class="actions">
	<button class="ui button" (click)="submit()" autofocus>OK</button>
</div>

import * as tslib_1 from "tslib";
import { ElementType } from '../../builder.enum';
import { GenericElement } from '../generic/generic.element';
var ShapeElement = /** @class */ (function (_super) {
    tslib_1.__extends(ShapeElement, _super);
    function ShapeElement(svg) {
        var _this = _super.call(this) || this;
        _this.type = ElementType.SHAPE;
        _this.background = '#FFFFFF00';
        _this.properties = {};
        _this.svg = svg;
        // Load in SVG properties to element model
        for (var property in _this.svg.properties) {
            _this.properties["svg::" + property] = _this.svg.getProperty(property);
        }
        _this.svg.render();
        return _this;
    }
    ShapeElement.prototype.clone = function () {
        this.svg.render();
        this.onRendered();
        return _super.prototype.clone.call(this);
    };
    ShapeElement.prototype.onRendered = function () {
        var bounds = this.svg.getBounds();
        var size = this.svg.getSize();
        this.position.x = bounds.ax;
        this.position.y = bounds.ay;
        this.size.width = size.width;
        this.size.height = size.height;
    };
    ShapeElement.prototype.onPropertyChange = function (property, value) {
        if ('svg' in property) {
            this.svg.setProperty(property.svg, value);
            this.svg.render();
        }
    };
    ShapeElement.prototype.generateCSS = function (editorViewport, outputViewport) {
        var style = {};
        var heightRatio = editorViewport.height / outputViewport.height;
        var widthRatio = editorViewport.width / outputViewport.width;
        style['left'] = this.svg.getBounds().ax * widthRatio + "px";
        style['top'] = this.svg.getBounds().ay * heightRatio + "px";
        style['width'] = this.svg.getSize().width * widthRatio + "px";
        style['height'] = this.svg.getSize().height * heightRatio + "px";
        return tslib_1.__assign({}, _super.prototype.generateCSS.call(this, editorViewport, outputViewport), style);
    };
    return ShapeElement;
}(GenericElement));
export { ShapeElement };

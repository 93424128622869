<div class="cdm-building-modal content">
	<h1><i class="file video outline icon"></i>Creating your video...</h1>

	<div class="ui fluid vertical steps">
		<div class="step" [ngClass]="{ disabled: getCurrentStepIndex() < 0, active: getCurrentStepIndex() === 0, completed: getCurrentStepIndex() > 0 }">
			<i class="paper plane icon"></i>
      <div class="ui active inline slow loader cdm-building-modal__loader"></div>
			<div class="content">
				<div class="title">Sending video details</div>
				<div class="description">Sending your request to the video builder...</div>
			</div>
		</div>
		<div class="step" [ngClass]="{ disabled: getCurrentStepIndex() < 1, active: getCurrentStepIndex() === 1, completed: getCurrentStepIndex() > 1 }">
			<i class="cogs icon"></i>
      <div class="ui active inline slow loader cdm-building-modal__loader"></div>
			<div class="content">
				<div class="title">Waiting in line</div>
				<div class="description">Getting a spot in the video builder...</div>
			</div>
		</div>
		<div class="step" [ngClass]="{ disabled: getCurrentStepIndex() < 2, active: getCurrentStepIndex() === 2, completed: getCurrentStepIndex() > 2 }">
			<i class="download icon"></i>
      <div class="ui active inline slow loader cdm-building-modal__loader"></div>
			<div class="content">
				<div class="title">Fetching</div>
				<div class="description">Video builder is downloading your images & videos...</div>
			</div>
		</div>
		<div class="step" [ngClass]="{ disabled: getCurrentStepIndex() < 3, active: getCurrentStepIndex() === 3, completed: getCurrentStepIndex() > 3 }">
			<i class="boxes icon"></i>
      <div class="ui active inline slow loader cdm-building-modal__loader"></div>
			<div class="content">
				<div class="title">Building</div>
				<div class="description">Putting it all together! Please wait...</div>
			</div>
		</div>
		<div class="step" [ngClass]="{ disabled: getCurrentStepIndex() < 4, active: getCurrentStepIndex() === 4, completed: getCurrentStepIndex() > 4 }">
			<i class="video icon"></i>
      <div class="ui active inline slow loader cdm-building-modal__loader"></div>
			<div class="content">
				<div class="title">Complete</div>
				<div class="description">Video successfully built!</div>
			</div>
		</div>
	</div>
</div>

import { clamp } from '../../../../_util/functions';
import { ElementPropertyDisplayType, ElementPropertyValueType } from '../../builder.enum';
import {
	IElement, IElementProperty, IElementPropertyEnumOption, IViewportDimensions
} from '../../builder.interface';

export class TextValueProperty implements IElementProperty {
	label: string = 'Text';
	value: string = 'value';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.STRING;
}

export class TextAligmentProperty implements IElementProperty {
	label: string = 'Alignment';
	value: string = 'properties["font::alignment"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'Center', value: 'center' },
		{ label: 'Top Left', value: 'topLeft' },
		{ label: 'Top', value: 'top' },
		{ label: 'Top Right', value: 'topRight' },
		{ label: 'Right', value: 'right' },
		{ label: 'Bottom Right', value: 'bottomRight' },
		{ label: 'Bottom', value: 'bottom' },
		{ label: 'Bottom Left', value: 'bottomLeft' },
		{ label: 'Left', value: 'left' },
	];
}

export class TextFontProperty implements IElementProperty {
	label: string = 'Font';
	value: string = 'properties["font::type"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'Arial', value: 'Arial' },
		{ label: 'Times New Roman', value: 'Times New Roman' },
		{ label: 'Roboto', value: 'Roboto' },
		{ label: 'Monsterrat', value: 'Montserrat' },
		{ label: 'Lora', value: 'Lora' },
		{ label: 'Caveat', value: 'Caveat' },
		{ label: 'Helvetica', value: 'Helvetica' }
	];
}

export class TextFontSizeProperty implements IElementProperty {
	label: string = 'Font size';
	value: string = 'properties["font::size"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let minimumValue: number = 0;
		let maximumValue: number = 512;

		return clamp(value, minimumValue, maximumValue);
	}
}

export class TextFontColorProperty implements IElementProperty {
	label: string = 'Font color';
	value: string = 'properties["font::color"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.COLOR;
}

export class TextFontEffectColorProperty implements IElementProperty {
	label: string = 'Effect color';
	value: string = 'properties["font::effect-color"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.COLOR;
}

export class TextFontEffectSizeProperty implements IElementProperty {
	label: string = 'Effect size';
	value: string = 'properties["font::effect-size"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let minimumValue: number = 0;
		let maximumValue: number = 32;

		return clamp(value, value, maximumValue);

	}
}

export class TextFontEffectProperty implements IElementProperty {
	label: string = 'Font effect';
	value: string = 'properties["font::effect"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'No effect', value: 'none' },
		{ label: 'Glow', value: 'glow' },
		{ label: 'Outline', value: 'outline' },
	];
}

export class TextFontStyleProperty implements IElementProperty {
	label: string = 'Font style';
	value: string = 'properties["font::style"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'Normal', value: 'normal' },
		{ label: 'Italic', value: 'italic' },
	];
}

export class TextFontWeightProperty implements IElementProperty {
	label: string = 'Font weight';
	value: string = 'properties["font::weight"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'Thin', value: 'lighter' },
		{ label: 'Normal', value: 'normal' },
		{ label: 'Bold', value: 'bold' },
	];
}

export class TextFontUnderlineProperty implements IElementProperty {
	label: string = 'Font underline';
	value: string = 'properties["font::underline"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;

	options: { label: string; value: string }[] = [
		{ label: 'Disabled', value: 'none' },
		{ label: 'Enabled', value: 'underline' },
	];
}

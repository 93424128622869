import * as tslib_1 from "tslib";
import { SVGElementType } from "../svg.definition";
import SVGElementGeneric from "./generic.definition";
var SVGCircle = /** @class */ (function (_super) {
    tslib_1.__extends(SVGCircle, _super);
    function SVGCircle(point, radialPoint) {
        var _this = _super.call(this) || this;
        _this.type = SVGElementType.CIRCLE;
        _this.points = [];
        _this.points = [point];
        if (radialPoint) {
            _this.points.push(radialPoint);
        }
        return _this;
    }
    SVGCircle.prototype.calculateRadius = function () {
        return Math.sqrt(Math.pow(this.points[0].x - this.points[1].x, 2) +
            Math.pow(this.points[0].y - this.points[1].y, 2));
    };
    SVGCircle.prototype.generate = function (builder, parent) {
        var _a;
        return builder.circle((_a = {},
            _a['cx'] = this.points[0].x - parent.getBounds().ax,
            _a['cy'] = this.points[0].y - parent.getBounds().ay,
            _a['r'] = this.points[1] ? this.calculateRadius() : parent.getProperty('radius') || (parent.getProperty('width') / 2),
            _a['fill'] = parent.getProperty('fill'),
            _a));
    };
    return SVGCircle;
}(SVGElementGeneric));
export { SVGCircle };

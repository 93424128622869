/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filePicker.modal.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../_components/fs/fs.component.ngfactory";
import * as i3 from "../../_components/fs/fs.component";
import * as i4 from "../../_services/modal.service";
import * as i5 from "@angular/router";
import * as i6 from "../../_services/preferences.service";
import * as i7 from "../../_services/loader.service";
import * as i8 from "../../_services/notification.service";
import * as i9 from "../../_services/filesystem.service";
import * as i10 from "@angular/common";
import * as i11 from "./filePicker.modal";
import * as i12 from "ng2-semantic-ui/dist/modules/modal/classes/modal-controls";
import * as i13 from "../../_services/permission.service";
var styles_FilePickerModalComponent = [i0.styles];
var RenderType_FilePickerModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilePickerModalComponent, data: {} });
export { RenderType_FilePickerModalComponent as RenderType_FilePickerModalComponent };
function View_FilePickerModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["autofocus", ""], ["class", "ui primary button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload new"]))], null, null); }
export function View_FilePickerModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { picker: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Choose a file - ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-fs", [], null, [[null, "selectedResourcesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedResourcesChange" === en)) {
        var pd_0 = (_co.onSelectChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FSComponent_0, i2.RenderType_FSComponent)), i1.ɵdid(5, 114688, [[1, 4], ["picker", 4]], 0, i3.FSComponent, [i4.ModalService, i5.Router, i6.PreferencesService, i7.LoaderService, i8.NotificationService, i9.FilesystemService], { mode: [0, "mode"], type: [1, "type"], sessionToken: [2, "sessionToken"] }, { selectedResourcesChange: "selectedResourcesChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "ui button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.deny(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePickerModalComponent_1)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["autofocus", ""], ["class", "ui primary button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Select existing"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "filepicker"; var currVal_2 = _co.type; var currVal_3 = _co.sessionToken; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.showUploadNew; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 2, 0, currVal_0); var currVal_5 = !_co.readyToSelect(); _ck(_v, 11, 0, currVal_5); }); }
export function View_FilePickerModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-file-picker", [], null, null, null, View_FilePickerModalComponent_0, RenderType_FilePickerModalComponent)), i1.ɵdid(1, 49152, null, 0, i11.FilePickerModalComponent, [i12.Modal, i13.PermissionService], null, null)], null, null); }
var FilePickerModalComponentNgFactory = i1.ɵccf("app-modal-file-picker", i11.FilePickerModalComponent, View_FilePickerModalComponent_Host_0, {}, {}, []);
export { FilePickerModalComponentNgFactory as FilePickerModalComponentNgFactory };

import { naiveDeepCopy } from '../../../../_util/functions';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementType } from '../../builder.enum';
import { IElement, IElementProperty, IElementStyleSheet, IViewportDimensions } from '../../builder.interface';
import { v4 as uuidv4 } from 'uuid';

export abstract class GenericElement implements IElement {
	id: string;
	type: ElementType;
	value: string;
	properties: { [key: string]: string } = {};

	position: { x; y } = {
		x: 320,
		y: 240,
	};

	rotation: number = 0;

	size: { width; height } = {
		width: 240,
		height: 180,
	};

	background = '#FFFFFF';
	index = 0;

	constructor(id: string = '') {
		this.id = id || uuidv4();
	}

	abstract onPropertyChange(property: IElementProperty, value: string): void;

	clone(): IElement {
		const clonedElement: IElement = Object.create(this);

		naiveDeepCopy(clonedElement, this);
		clonedElement.index++;
		clonedElement.id = uuidv4();

		return clonedElement;
	}

	generateCSS(editorViewport: IViewportDimensions, outputViewport: IViewportDimensions): IElementStyleSheet {
		const style: IElementStyleSheet = {
			['position']: 'absolute',
		};

		const heightRatio = editorViewport.height / outputViewport.height;
		const widthRatio = editorViewport.width / outputViewport.width;

		if (this.index !== undefined) {
			style['z-index'] = this.index;
		}

		if (this.size) {
			if (this.size.width) {
				style['width'] = `${this.size.width * widthRatio}px`;
			} else {
				style['width'] = `0px`;
			}

			if (this.size.height) {
				style['height'] = `${this.size.height * heightRatio}px`;
			} else {
				style['width'] = `0px`;
			}
		}

		if (this.position) {
			if (!isNaN(this.position.x)) {
				style['left'] = `${this.position.x * widthRatio}px`;
			}

			if (!isNaN(this.position.y)) {
				style['top'] = `${this.position.y * heightRatio}px`;
			}
		}

		if (this.rotation) {
			// We don't handle the rotation here for various reasons. Check the __rotation element for more info.
			// if (!isNaN(this.rotation)) {
			// 	style['transform']= `rotate(${this.rotation}deg)`;
			// }
		}

		if (this.background) {
			style['background'] = this.background;
		}

		return style;
	}
}

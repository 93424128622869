import { ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class CloneToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'clone';
	icon: string = 'copy';
	tooltip: string = 'Clone an element';
  label: string = 'Clone';

  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const elementToClone = context.getActiveElement();
    const clonedElement = elementToClone.clone();
		context.getActiveSlide().elements.push(clonedElement);
    context.setActiveElement(clonedElement);
		return clonedElement;
	}
}

import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import Events from '../_util/events';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class BuilderService {
	constructor(private socketService: SocketService) {}

	public init(): Observable<any> {
		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}

	public generateVideo(config: any): Observable<any> {
		return this.socketService.call(Events.GENERATE_VIDEO, undefined, config);
	}

	public checkVideoStatus(id: string): Observable<any> {
		return this.socketService.call(Events.CHECK_VIDEO_STATUS, undefined, id);
	}

	public getVideoLink(id: string): Observable<any> {
		return this.socketService.call(Events.GET_VIDEO_LINK, undefined, id);
	}

	public getProjects(): Observable<any> {
		return this.socketService.call(Events.LIST_PROJECTS, undefined);
	}

	public createProject(name: string, body: any): Observable<any> {
		return this.socketService.call(Events.CREATE_PROJECT, undefined, name, body);
	}

	public deleteProject(projectId: string): Observable<any> {
		return this.socketService.call(Events.DELETE_PROJECT, undefined, projectId);
	}

	public editProject(projectId: string, name: string, body: any): Observable<any> {
		return this.socketService.call(Events.EDIT_PROJECT, undefined, projectId, name, body);
	}

	public getTemplates(): Observable<any> {
		return this.socketService.call(Events.LIST_TEMPLATES, undefined);
	}

	public createTemplate(name: string, body: any): Observable<any> {
		return this.socketService.call(Events.CREATE_TEMPLATE, undefined, name, body);
	}

	public deleteTemplate(templateId: string): Observable<any> {
		return this.socketService.call(Events.DELETE_TEMPLATE, undefined, templateId);
	}

	public editTemplate(templateId: string, name: string, body: any): Observable<any> {
		return this.socketService.call(Events.EDIT_TEMPLATE, undefined, templateId, name, body);
	}
}

import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class ToggleBoldToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'toggle_bold';
	icon: string = 'bold';
	tooltip: string = 'Toggle bold effect';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontWeightProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::weight"]');

		const currentPropertyValue = activeElement.properties['font::weight'];

		if (currentPropertyValue === 'bold') {
			context.setElementProperty(activeElement, fontWeightProperty, 'normal');
		} else {
			context.setElementProperty(activeElement, fontWeightProperty, 'bold');
		}
	}
}

import * as tslib_1 from "tslib";
import { SuiModal } from 'ng2-semantic-ui';
import { BuilderService } from '../../_services/builder.service';
import { NotificationService } from '../../_services/notification.service';
import { sleep } from '../../_util/functions';
var BuildingModalComponent = /** @class */ (function () {
    function BuildingModalComponent(builder, notification, modal) {
        this.builder = builder;
        this.notification = notification;
        this.modal = modal;
        this.steps = [
            { step: 'sending', complete: false },
            { step: 'queued', complete: false },
            { step: 'fetching', complete: false },
            { step: 'rendering', complete: false },
            { step: 'done', complete: false },
        ];
        this.creation = this.modal.context.creation;
        this.runStatusCheckLoop();
    }
    BuildingModalComponent.prototype.markStatusComplete = function (status) {
        var currentIndex = this.steps.findIndex(function (step) { return step.step === status; });
        for (var i = 0; i < currentIndex; i++) {
            this.steps[i].complete = true;
        }
    };
    BuildingModalComponent.prototype.getCurrentStepIndex = function () {
        var currentStep = this.steps.findIndex(function (step) { return !step.complete; });
        if (currentStep === -1) {
            return this.steps.length + 1;
        }
        else {
            return currentStep;
        }
    };
    BuildingModalComponent.prototype.runStatusCheckLoop = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.requestBuild(this.creation)
                    .then(function (id) {
                    _this.generateVideo(id);
                })
                    .catch(function (error) {
                    _this.modal.deny(undefined);
                    _this.notification.displayError(error);
                });
                return [2 /*return*/];
            });
        });
    };
    BuildingModalComponent.prototype.generateVideo = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var status, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.markStatusComplete('sending');
                        _a.label = 1;
                    case 1: return [4 /*yield*/, sleep(1000)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.getVideoBuildStatus(id)];
                    case 4:
                        status = _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        status = 'failed';
                        return [3 /*break*/, 6];
                    case 6:
                        this.markStatusComplete(status);
                        if (status === 'failed') {
                            this.notification.displayError('An error occurred processing your video');
                            this.modal.deny(undefined);
                            return [2 /*return*/];
                        }
                        _a.label = 7;
                    case 7:
                        if (status !== 'done') return [3 /*break*/, 1];
                        _a.label = 8;
                    case 8: return [4 /*yield*/, sleep(500)];
                    case 9:
                        _a.sent();
                        return [4 /*yield*/, this.submit(id)];
                    case 10:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BuildingModalComponent.prototype.requestBuild = function (config) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.builder.generateVideo(this.creation).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BuildingModalComponent.prototype.getVideoBuildStatus = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.builder.checkVideoStatus(id).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BuildingModalComponent.prototype.submit = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.builder.getVideoLink(id).toPromise()];
                    case 1:
                        url = _a.sent();
                        this.modal.approve(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    return BuildingModalComponent;
}());
export { BuildingModalComponent };

import * as i0 from "@angular/core";
var WaveformService = /** @class */ (function () {
    function WaveformService() {
        this.waveformInstances = [];
    }
    WaveformService.prototype.registerWaveformInstance = function (instance) {
        this.waveformInstances.push(instance);
    };
    WaveformService.prototype.stopAllPlayback = function () {
        for (var _i = 0, _a = this.waveformInstances; _i < _a.length; _i++) {
            var waveformInstance = _a[_i];
            if (!waveformInstance) {
                continue;
            }
            waveformInstance.stop();
        }
    };
    WaveformService.ngInjectableDef = i0.defineInjectable({ factory: function WaveformService_Factory() { return new WaveformService(); }, token: WaveformService, providedIn: "root" });
    return WaveformService;
}());
export { WaveformService };

import { ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';
import { ImageElement } from '../elements/image/image.element';

export class AddImageToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'add_image';
	icon: string = 'image';
	tooltip: string = 'Add a new image';
	label: string = 'Images';
  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext, url: string = 'https://citadel-video-builder-assets.s3.amazonaws.com/sample_image.png', width: number = 300, height: number = 300): IElement | void {
		const currentElementCount: number = context.getActiveSlide().elements.length;
		const defaultImage = new ImageElement(url, width, height);
		defaultImage.index = currentElementCount;
		context.getActiveSlide().elements.push(defaultImage);
		context.setActiveElement(defaultImage);
		return defaultImage;
	}
}

import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import IConfirmModalContext from './confirm.interface';
import { ConfirmModalComponent } from './confirm.modal';

export class ConfirmModal extends ComponentModalConfig<IConfirmModalContext, void, void> {
	constructor(title: string, description: string = '') {
		super(ConfirmModalComponent, { title, description });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

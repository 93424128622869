<div class="cdm-fs" [ngClass]="{ 'cdm-fs--file-picker': mode === 'filepicker' }">
  <table class="cdm-fs__table ui single line selectable celled table" [ngClass]="{ 'very compact': isCompact }">
    <thead>
      <tr [hidden]="mode !== 'admin'">
        <th class="cdm-fs__table-actions" colspan="7">
          <div>
            <button class="ui primary compact button" (click)="upload()">
              Upload File
              <input class="cdm-fs__file-upload" type="file" [accept]="type" (change)="onFileChange($event)" multiple
                #uploadInput />
            </button>
            <button class="ui primary compact button" (click)="makeDirectory()">Create Folder</button>
            <button class="ui green compact button" *ngIf="allowSystemFolderCreation"
              (click)="makeSystemDirectory()">Create System Folder</button>
            <button class="ui compact button" (click)="refresh()">
              {{ isShowingSearchResults ? 'Cancel search' : 'Refresh' }}
            </button>
            <button class="ui compact button" [disabled]="selectedResources.length === 0"
              (click)="openDeleteModal(selectedResources)">Delete</button>
            <div class="cdm-fs__views">
              <a class="cdm-fs__view-option" [ngClass]="{ 'cdm-fs__view-option--inactive': currentVisualMode !== 'table' }" (click)="setVisualMode('table')"><i class="list icon"></i></a>
              <a class="cdm-fs__view-option" [ngClass]="{ 'cdm-fs__view-option--inactive': currentVisualMode !== 'grid' }" (click)="setVisualMode('grid')"><i class="th icon"></i></a>
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th class="cdm-fs__table-path" colspan="5">
          <div class="ui labeled input">
            <div class="cdm-fs__traversal">
              <div class="ui icon buttons">
                <button class="ui button" [ngClass]="{ 'disabled': directoryHistoryIndex === -1 }" (click)="navigateBack()">
                  <i class="arrow left icon"></i>
                </button>
                <button class="ui button" [ngClass]="{ 'disabled': directoryHistoryIndex === directoryHistory.length - 1 }" (click)="navigateForward()">
                  <i class="arrow right icon"></i>
                </button>
              </div>
            </div>
            <div class="ui label">drive://</div>
            <div class="cdm-fs__table-path-container" *ngIf="!isShowingSearchResults">
              <div class="ui label" *ngFor="let path of getCurrentDirectoryPath()">
                {{ path }}
              </div>
            </div>
            <input type="text" *ngIf="isShowingSearchResults" disabled [ngModel]="'Showing search results'" />
          </div>
        </th>
        <th class="cdm-fs__table-search" colspan="2">
          <div class="ui left icon input">
            <input type="text" placeholder="Search for content" [(ngModel)]="searchFilter" />
            <i class="search icon"></i>
            <button class="ui compact primary button" (click)="search()">Search</button>
          </div>
        </th>
      </tr>
      <ng-container *ngIf="currentVisualMode === 'table'">
        <tr>
          <th class="collapsing cdm-fs__checkbox">
            <div *ngIf="mode === 'admin'">
              <sui-checkbox [ngModel]="areAllSelected()" (checkChange)="onAllCheckChange($event)"> </sui-checkbox>
            </div>
          </th>
          <th>Resource name</th>
          <th>Type</th>
          <th>Size</th>
          <th style="width: 150px">Last Modified</th>
          <th style="width: 150px">Created</th>
        </tr>
      </ng-container>
    </thead>
    <div class="ui placeholder segment cdm-fs__empty" *ngIf="!hasResourcesToDisplay()">
      <div class="ui icon header">
        <i class="search icon"></i>
        {{ !isShowingSearchResults ? 'No resources found' : 'No resources found with the provided filter' }}
      </div>
      <a class="cdm-fs__empty-clear" *ngIf="isShowingSearchResults" (click)="refresh()">Cancel search</a>
    </div>

    <ng-container *ngIf="currentVisualMode === 'table'">
      <tbody class="cdm-fs__table-body" *ngIf="hasResourcesToDisplay()">
        <div class="ui active inverted dimmer" *ngIf="refreshing">
          <div class="ui indeterminate loader"></div>
        </div>
        <ng-container *ngIf="mode === 'filepicker'">
          <tr *ngIf="currentDirectory" (click)="navigateToParent()">
            <td class="collapsing cdm-fs__checkbox-td"></td>
            <td class="collapsing"><i class="folder open icon"></i> ..</td>
            <td></td>
            <td></td>
            <td class="collapsing"></td>
            <td class="collapsing"></td>
          </tr>
          <tr [ngClass]="{ active: isSelected(subdirectory), disabled: isDisabled(subdirectory) }"
            (click)="navigateTo(subdirectory)" *ngFor="let subdirectory of activeSubdirectories">
            <td class="collapsing cdm-fs__checkbox-td" (click)="$event.stopPropagation()">
              <div>
                <sui-checkbox [ngModel]="isSelected(subdirectory)"
                  (checkChange)="onResourceCheckChange($event, subdirectory)"> </sui-checkbox>
              </div>
            </td>
            <td>
              <i class="{{ getIcon(subdirectory) }} icon"></i> {{ subdirectory.name }}
              <a class="ui basic label cdm-fs__shared" *ngIf="subdirectory.type === 'system'">Shared</a>
            </td>
            <td class="collapsing">Folder</td>
            <td class="collapsing">{{ subdirectory.size | bytes }}</td>
            <td class="collapsing">{{ subdirectory.lastModified | date : 'short' }}</td>
            <td class="collapsing">{{ subdirectory.created | date : 'short' }}</td>
          </tr>
          <tr [ngClass]="{ active: isSelected(file), disabled: isDisabled(file) }"
            *ngFor="let file of activeChildrenFiles">
            <td class="collapsing cdm-fs__checkbox-td" (click)="$event.stopPropagation()">
              <div>
                <sui-checkbox [ngModel]="isSelected(file)" (checkChange)="onResourceCheckChange($event, file)">
                </sui-checkbox>
              </div>
            </td>
            <td (click)="toggleResource(file)">
              <div class="cdm-fs__table-resource-name">
                <div class="cdm-fs__table-thumbnail">
                  <ng-container *ngIf="hasThumbnail(file)">
                    <img [src]="file.urls.thumbnail" />
                  </ng-container>
                  <ng-container *ngIf="!hasThumbnail(file)">
                    <i class="{{ getIcon(file) }} icon"></i>
                  </ng-container>
                </div>
                {{ file.name }}
                <a class="ui basic label cdm-fs__shared" *ngIf="file.type === 'system'">Shared</a>
              </div>
            </td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.mimeType }} file</td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.size | bytes }}</td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.lastModified | date : 'short' }}</td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.created | date : 'short' }}</td>
          </tr>
          <tr *ngFor="let upload of currentUploads" class="disabled">
            <td class="collapsing cdm-fs__checkbox-td" (click)="$event.stopPropagation()"></td>
            <td><i class="cloud upload icon"></i> {{ upload.name }}</td>
            <td class="collapsing">N/A</td>
            <td class="collapsing">Processing...</td>
            <td class="collapsing">N/A</td>
            <td class="collapsing">N/A</td>
            <div class="cdm-fs__progress">
              <sui-progress class="blue active" [value]="upload.progress" [showProgress]="true"> </sui-progress>
            </div>
          </tr>
        </ng-container>
        <ng-container *ngIf="mode !== 'filepicker'">
          <tr mwlDroppable (drop)="onDirectoryDrop(getPsuedoParentDirectory(), $event)"
            dragOverClass="cdm-fs__drop-over-active" [contextMenu]="parentMenu" [contextMenuSubject]="{}"
            *ngIf="currentDirectory" (click)="navigateToParent()">
            <td class="collapsing cdm-fs__checkbox-td"></td>
            <td><i class="folder open icon"></i> ..</td>
            <td class="collapsing"></td>
            <td class="collapsing"></td>
            <td class="collapsing"></td>
            <td class="collapsing"></td>
          </tr>
          <tr mwlDroppable (drop)="onDirectoryDrop(subdirectory, $event)" dragOverClass="cdm-fs__drop-over-active"
            mwlDraggable [validateDrag]="validateDrag" [dropData]="subdirectory" dragActiveClass="drag-active"
            [ngClass]="{ active: isSelected(subdirectory), disabled: isDisabled(subdirectory) }"
            [contextMenu]="directoryMenu" [contextMenuSubject]="subdirectory" (click)="navigateTo(subdirectory)"
            *ngFor="let subdirectory of activeSubdirectories">
            <td class="collapsing cdm-fs__checkbox-td" (click)="$event.stopPropagation()">
              <div>
                <sui-checkbox [ngModel]="isSelected(subdirectory)"
                  (checkChange)="onResourceCheckChange($event, subdirectory)"> </sui-checkbox>
              </div>
            </td>
            <td>
              <i class="{{ getIcon(subdirectory) }} icon"></i> {{ subdirectory.name }}
              <a class="ui basic label cdm-fs__shared" *ngIf="subdirectory.type === 'system'">Shared</a>
            </td>
            <td class="collapsing">Folder</td>
            <td class="collapsing">{{ subdirectory.size | bytes }}</td>
            <td class="collapsing">{{ subdirectory.lastModified | date : 'short' }}</td>
            <td class="collapsing">{{ subdirectory.created | date : 'short' }}</td>
          </tr>
          <tr mwlDraggable [validateDrag]="validateDrag" [dropData]="file" dragActiveClass="drag-active"
            [ngClass]="{ active: isSelected(file), disabled: isDisabled(file) }" [contextMenu]="fileMenu"
            [contextMenuSubject]="file" *ngFor="let file of activeChildrenFiles">
            <td class="collapsing cdm-fs__checkbox-td" (click)="$event.stopPropagation()">
              <div>
                <sui-checkbox [ngModel]="isSelected(file)" (checkChange)="onResourceCheckChange($event, file)">
                </sui-checkbox>
              </div>
            </td>
            <td (click)="toggleResource(file)">
              <div class="cdm-fs__table-resource-name">
                <div class="cdm-fs__table-thumbnail">
                  <ng-container *ngIf="hasThumbnail(file)">
                    <img [src]="file.urls.thumbnail" />
                  </ng-container>
                  <ng-container *ngIf="!hasThumbnail(file)">
                    <i class="{{ getIcon(file) }} icon"></i>
                  </ng-container>
                </div>
                {{ file.name }}
                <a class="ui basic label cdm-fs__shared" *ngIf="file.type === 'system'">Shared</a>
              </div>
            </td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.mimeType }} file</td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.size | bytes }}</td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.lastModified | date : 'short' }}</td>
            <td (click)="toggleResource(file)" class="collapsing">{{ file.created | date : 'short' }}</td>
          </tr>
          <tr *ngFor="let upload of currentUploads" class="disabled">
            <td class="collapsing cdm-fs__checkbox-td" (click)="$event.stopPropagation()"></td>
            <td><i class="cloud upload icon"></i> {{ upload.name }}</td>
            <td class="collapsing">N/A</td>
            <td class="collapsing">Processing...</td>
            <td class="collapsing">N/A</td>
            <td class="collapsing">N/A</td>
            <div class="cdm-fs__progress">
              <sui-progress class="blue active" [value]="upload.progress" [showProgress]="true"> </sui-progress>
            </div>
          </tr>
        </ng-container>
      </tbody>
    </ng-container>
    <ng-container *ngIf="currentVisualMode === 'grid'">
      <div class="ui segment cdm-fs__grid">
        <div class="ui active inverted dimmer" *ngIf="refreshing">
          <div class="ui indeterminate loader"></div>
        </div>
        <div class="ui active inverted dimmer" *ngIf="currentUploads.length !== 0">
          <div class="ui indeterminate loader"></div>
        </div>
        <div class="cdm-fs__grid-container">
          <div class="cdm-fs__grid-element" *ngFor="let subdirectory of activeSubdirectories" mwlDroppable
            (drop)="onDirectoryDrop(subdirectory, $event)" [contextMenu]="directoryMenu"
            [contextMenuSubject]="subdirectory" mwlDraggable [validateDrag]="validateDrag" [dropData]="subdirectory"
            dragActiveClass="drag-active" (click)="navigateTo(subdirectory)" [ngClass]="{'cdm-fs__grid-element--disabled': isGridViewElementDisabled(subdirectory) }">
            <div class="cdm-fs__grid-element-icon">
              <i class="primary folder icon" hover-class="open"></i>
            </div>
            <span class="cdm-fs__grid-element-name">
              {{ subdirectory.name }}
            </span>
            <div class="cdm-fs__grid-element-system" *ngIf="subdirectory.type === 'system'">
              <i class="primary shield alternate icon"></i>
            </div>
          </div>
          <div class="cdm-fs__grid-element" *ngFor="let file of activeChildrenFiles" [ngClass]="{ 'cdm-fs__grid-element--selected': isSelected(file), 'cdm-fs__grid-element--disabled': isGridViewElementDisabled(file) }" [contextMenu]="fileMenu"
            [contextMenuSubject]="file" mwlDraggable [validateDrag]="validateDrag" [dropData]="file"
            dragActiveClass="drag-active" (click)="onGridFileClick(file)">
            <div class="cdm-fs__grid-element-icon cdm-fs__grid-element-icon--hoverable">
              <ng-container *ngIf="hasThumbnail(file)">
                <img [src]="file.urls.thumbnail" />
              </ng-container>
              <i *ngIf="hasThumbnail(file) && isVideo(file)" class="play circle icon cdm-fs__grid-element-play"></i>
              <i *ngIf="file.cdnUrl && isAudio(file)" class="file audio icon"></i>
              <i *ngIf="file.cdnUrl && isPDF(file)" class="file pdf icon"></i>
              <i *ngIf="(!hasThumbnail(file) && !isAudio(file) && !isPDF(file)) || !file.cdnUrl" class="file icon"></i>

            </div>
            <span class="cdm-fs__grid-element-name">
              {{ file.name }}
            </span>
            <div class="cdm-fs__grid-element-system" *ngIf="file.type === 'system'">
              <i class="primary shield alternate icon"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </table>
</div>

<context-menu #fileMenu>
  <ng-template *ngIf="mode === 'admin'" contextMenuItem let-item (execute)="openEditModal($event.item)">
    <i class="edit icon"></i>
    Edit
  </ng-template>
  <ng-template contextMenuItem let-item (execute)="openPreviewModal($event.item)">
    <i class="eye icon"></i>
    Show Preview
  </ng-template>
  <ng-template contextMenuItem let-item (execute)="gotoBulkAction($event.item)">
    <i class="cogs icon"></i>
    Create bulk action
  </ng-template>
  <ng-template contextMenuItem let-item (execute)="download($event.item)">
    <i class="cloud download alternate icon"></i>
    Download
  </ng-template>
  <ng-template *ngIf="mode === 'admin' && onFileEntitlementChange" contextMenuItem let-item (execute)="onFileEntitlementChange.emit($event.item)">
    <i class="share square icon"></i>
    Share
  </ng-template>
  <ng-template contextMenuItem let-item (execute)="copyUrl($event.item)">
    <i class="copy outline icon"></i>
    Copy Link
  </ng-template>
  <ng-template *ngIf="mode === 'admin'" contextMenuItem let-item (execute)="openDeleteModal($event.item)">
    <i class="trash icon"></i>
    Delete
  </ng-template>
</context-menu>

<context-menu #directoryMenu>
  <ng-template contextMenuItem (execute)="navigateTo($event.item)">
    <i class="folder open icon"></i>
    Navigate to...
  </ng-template>
  <ng-template *ngIf="mode === 'admin'" contextMenuItem let-item (execute)="openEditModal($event.item)">
    <i class="edit icon"></i>
    Edit
  </ng-template>
  <ng-template *ngIf="mode === 'admin' && onDirectoryEntitlementChange" contextMenuItem let-item (execute)="onDirectoryEntitlementChange.emit($event.item)">
    <i class="share square icon"></i>
    Share
  </ng-template>
  <ng-template *ngIf="mode === 'admin'" contextMenuItem let-item (execute)="openDeleteModal($event.item)">
    <i class="trash icon"></i>
    Delete
  </ng-template>
</context-menu>

<context-menu #parentMenu>
  <ng-template contextMenuItem (execute)="navigateToParent()">
    <i class="folder open icon"></i>
    Navigate to...
  </ng-template>
</context-menu>
import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';
import { VideoElement } from '../elements/video/video.element';

export class LayerDownToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'layer_down';
	icon: string = 'angle down';
	tooltip: string = 'Move an element towards the back';
	label: string = 'Move down a layer';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const indexProperty = context.getPropertyType(ElementType.ANY, 'index');
		const currentIndex = activeElement.index;
		const newIndex = Math.max(0, currentIndex - 1);

		context.setElementProperty(activeElement, indexProperty, newIndex);
	}
}

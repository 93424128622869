import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private LOADER_FADE_TIME = 250;

	private loading = false;
	private loaderText: string;

	private pageLoaded = false;
	// before page load
	private unloadedStatus = false;
	private unloadedText: string;

	// percentage loader
	private isPercentageLoading = false;
	private percentageLoadingValue = 0;

	constructor() {}

	public initializeOnPageload() {
		this.pageLoaded = true;
		this.loading = this.unloadedStatus;
		this.loaderText = this.unloadedText;
	}

	public setLoading(value: boolean, loaderText: string = '') {
		if (this.pageLoaded) {
			this.loading = value;
			this.loaderText = loaderText;
		} else {
			this.unloadedStatus = value;
			this.unloadedText = loaderText;
		}
	}

	public createPercentageLoader(message: string): Function {
		this.isPercentageLoading = true;
		this.loaderText = message;
    this.percentageLoadingValue = 0;

		return (value) => {
			this.percentageLoadingValue = value;
			if (value >= 100) {
        this.stopPercentageLoader();
			}
		};
	}

	public stopPercentageLoader(): void {
    setTimeout(() => {
      this.isPercentageLoading = false;
    }, this.LOADER_FADE_TIME);
	}

	public isDeterminateLoading(): boolean {
		return this.isPercentageLoading;
	}

	public isIndeterminateLoading(): boolean {
		return this.isLoading && !this.isPercentageLoading;
	}

	public isLoading(): boolean {
		if (!this.pageLoaded) {
			return false;
		}
		return this.loading || this.isPercentageLoading;
	}

	public getLoadingText(): string {
		return this.loaderText;
	}

	public getPercentageLoadingValue(): number {
		// Clamp from 0-100
		return Math.min(Math.max(this.percentageLoadingValue, 0), 100);
	}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular2-notifications/angular2-notifications.ngfactory";
import * as i3 from "angular2-notifications";
import * as i4 from "./notifications.component";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-notifications", [], null, null, null, i2.View_SimpleNotificationsComponent_0, i2.RenderType_SimpleNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i3.SimpleNotificationsComponent, [i3.NotificationsService, i1.ChangeDetectorRef], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationOptions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 49152, null, 0, i4.NotificationsComponent, [], null, null)], null, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i4.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };

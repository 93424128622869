import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class VideoCropToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'font_effect';
	label: string = 'Video crop';
	icon: string = 'crop';
	tooltip: string = 'Adjust video crop';
	actionType: ToolbarActionType = ToolbarActionType.DROPDOWN;

	execute(context: IVideoBuilderContext, type: string): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const videoCropProperty = context.getPropertyType(ElementType.VIDEO, 'properties["video::crop"]');

		context.setElementProperty(activeElement, videoCropProperty, type);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const videoCropProperty = context.getPropertyType(ElementType.VIDEO, 'properties["video::crop"]');

		return context.getElementProperty(context.getActiveElement(), videoCropProperty);
	}

	getRelevantPropertyType(context: IVideoBuilderContext): any {
		return context.getPropertyType(ElementType.VIDEO, 'properties["video::crop"]')
	}
}

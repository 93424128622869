import { EventEmitter } from '@angular/core';

export enum SVGElementType {
    A = 'a',
    CIRCLE = 'circle',
    G = 'g',
    LINE = 'line',
    PATH = 'path',
    RECT = 'rect',
    STYLE = 'style',
    TEXT = 'text',
    TRIANGLE = 'triangle'
}

export enum SVGType {
    ARROW = 'arrow',
    BOX = 'box',
    CIRCLE = 'circle',
    STAR = 'star'
}

export enum SVGPathAction {
    LINE = 'line',
    MOVE = 'move',
}

export interface ISVGBounds {
    ax: number;
    ay: number;
    bx: number;
    by: number;
}

export interface ISVGPoint {
    x: number;
    y: number;
    transformable?: boolean;
}

export interface ISVGElement {
    type: SVGElementType;
    points: ISVGPoint[];

    generate(builder: any, parent: ISVG): any;
}

export interface ISVG {
    type: SVGType;
    points: ISVGPoint[];
    elements: ISVGElement[];
    rendered: string;

    properties: {
        [key: string]: any
    };

    validateBounds(width: number, height: number): void;
    getOriginPoint(): ISVGPoint;
    changeOrigin(x: number, y: number): void;
    getBounds(): ISVGBounds;
    getSize(): { width: number, height: number };
    getProperty(key: string): any;
    setProperty(key: string, value: any): void;
    getPoints(): ISVGPoint[];
    render(): string;
}
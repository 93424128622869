import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  private PREFERENCE_PREFIX = 'preference';

  constructor() { }

  public setPreference(key: string[], value: string): void {
    return localStorage.setItem(this.generateStorageKey(key), value);
  }

  public getPreference(key: string[], fallback: any = undefined): any {
    return localStorage.getItem(this.generateStorageKey(key)) || fallback;
  }

  private generateStorageKey(key: string[]): string {
    return [this.PREFERENCE_PREFIX, ...key.map(x => x.trim())].join('/');
  }
}

import { ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction
} from '../builder.interface';

export class DeleteToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'delete';
	icon: string = 'trash';
	tooltip: string = 'Remove an element';
  label: string = 'Delete';

  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const elementToRemove = context.getActiveElement();
		context.setActiveElement(null);

		return context.removeElement(elementToRemove);
	}
}

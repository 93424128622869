import * as tslib_1 from "tslib";
import { SVGElementType } from "../svg.definition";
import SVGElementGeneric from "./generic.definition";
var SVGRect = /** @class */ (function (_super) {
    tslib_1.__extends(SVGRect, _super);
    function SVGRect(pointA, pointB) {
        var _this = _super.call(this) || this;
        _this.type = SVGElementType.RECT;
        _this.points = [];
        _this.points = [pointA, pointB];
        return _this;
    }
    SVGRect.prototype.generate = function (builder, parent) {
        var _a;
        return builder.rect((_a = {},
            _a['x'] = Math.min(this.points[0].x, this.points[1].x) - parent.getBounds().ax,
            _a['y'] = Math.min(this.points[0].y, this.points[1].y) - parent.getBounds().ay,
            _a['width'] = Math.abs(this.points[1].x - this.points[0].x),
            _a['height'] = Math.abs(this.points[1].y - this.points[0].y),
            _a['stroke'] = parent.getProperty('stroke'),
            _a['stroke-width'] = parent.getProperty('width'),
            _a['fill'] = parent.getProperty('fill'),
            _a));
    };
    return SVGRect;
}(SVGElementGeneric));
export { SVGRect };

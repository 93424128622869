import { SuiModal } from 'ng2-semantic-ui';
import { catchError } from 'rxjs/internal/operators';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import IFile from '../../_interfaces/IFile';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import { downloadResourceToDisk, downloadResourceToMemory } from '../../_util/functions';
import { ResourceModal } from '../resource/resource.export';
import IBuilderModalContext from './builder.interface';
import { ModalService } from '@citadel/common-frontend/_services/modal.service';

@Component({
	selector: 'app-modal-builder',
	templateUrl: './builder.modal.html',
	styleUrls: ['./builder.modal.scss'],
})
export class BuilderModalComponent {
	@ViewChild('video')
	private video: ElementRef;

	private sessionToken: string;

	public url: string;
	public readyToPlay: boolean = false;

	public hasDownloaded: boolean = false;
	public hasAddedToLibrary: boolean = false;

	constructor(private loader: LoaderService, private modalService: ModalService, private filesystemService: FilesystemService, private notificationService: NotificationService, public modal: SuiModal<IBuilderModalContext, void, void>) {
		this.url = this.modal.context.url;
		this.sessionToken = this.modal.context.sessionToken;
	}

	public onCanPlay() {
		const target = this.video.nativeElement;
		target.play();
		this.readyToPlay = true;
	}

	public getCustomVideoName(): string {
		return `custom_video_${new Date().valueOf()}.mp4`;
	}

	public async download() {
		this.hasDownloaded = true;
		this.loader.setLoading(true, 'Downloading...');
		await downloadResourceToDisk(this.url, this.getCustomVideoName());
		this.loader.setLoading(false);
	}

	private async openEditModal(resource: IFile) {
		this.modalService.open(new ResourceModal(resource, true, []));
	}

	public async addToLibrary() {
		this.hasAddedToLibrary = true;
		this.loader.setLoading(true, 'Downloading...');
		const blob = await downloadResourceToMemory(this.url);

		this.loader.setLoading(true, 'Uploading...');
		this.filesystemService
			.uploadBlob(blob, this.getCustomVideoName(), null, this.sessionToken)
			.pipe(
				catchError(async (event) => {
					const errorMessage = event.error.error || event.error.data;
					this.notificationService.displayError(errorMessage);
					this.loader.setLoading(false);
				})
			)
			.subscribe(async (event) => {
				if (!event) {
					return;
				}

				if (event.type === HttpEventType.Response) {
					// get response
					const upload = event.body;
					if (upload.meta && upload.meta.length > 0) {
						this.openEditModal(upload);
						this.loader.setLoading(false);
					}
				}
			});
	}

	public submit() {
		this.modal.approve(undefined);
	}
}

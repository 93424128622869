<div class="content">
	<form class="ui form" [formGroup]="resourceFormGroup">
		<h4 class="ui dividing header">{{ type | titlecase }} Info</h4>
		<div class="field">
			<label>Name</label>
			<div class="ui right labeled input">
				<input type="text" name="name" formControlName="name" />
				<div class="ui label" *ngIf="resource.extension">.{{ resource.extension }}</div>
			</div>
		</div>
		<h4 class="ui dividing header">{{ type | titlecase }} Metadata</h4>
    <div class="field" *ngIf="availableTags && availableTags.length > 0">
      <label>Tags</label>
      <sui-multi-select class="selection" [options]="availableTags" formControlName="tags" labelField="name" [isSearchable]="true" [hasLabels]="true" #tagSelect>
        <ng-container>
          <sui-select-option *ngFor="let option of tagSelect.filteredOptions" [value]="option"> </sui-select-option>
        </ng-container>
      </sui-multi-select>
    </div>
		<div class="field">
			<label>{{ type | titlecase }} Size</label>
			<div class="ui input">
				<input disabled [ngModelOptions]="{ standalone: true }" [ngModel]="resource.size | bytes" (ngModelChange)="resource.size = $event" type="text" />
			</div>
		</div>
		<div>
      <div class="ui message" *ngIf="resourceFormGroup['controls'].meta['controls'].length === 0">
        <p>No extended metadata is currently available for this resource.</p>
      </div>
      <form class="ui form" formArrayName="meta">
        <ng-container *ngFor="let metadata of metadataFormControls; let i = index">
          <div class="field" [formGroup]="metadata">
            <label>{{ metadata.get('key').value | uncamel }}</label>
            <input type="text" name="value" formControlName="value" [required]="metadata.get('required').value" [attr.disabled]="metadata.get('readOnly').value ? true : null" />
          </div>
        </ng-container>
      </form>
		</div>
		<p *ngIf="uploadedPDF">{{ uploadedPDF.value.name }}</p>
	</form>
	<br />
	<br />
	<div style="margin: 0 auto; text-align: center" *ngIf="canUploadPDF">
		<button class="ui primary button" (click)="upload()" [disabled]="!!uploadedPDF">
			Upload PDF
			<input class="cdm-fs__file-upload" type="file" accept="application/pdf" #uploadInput (change)="onFileChange($event)" />
		</button>
		<button class="ui button button" (click)="downloadAttachedPdf()" *ngIf="!!uploadedPDF">Download PDF</button>
		<button class="ui red button" (click)="deleteAttachedPdf()" *ngIf="!!uploadedPDF">Delete PDF</button>
	</div>
</div>

<div class="actions">
	<button class="ui red button left" float="{left}" (click)="deleteResource(); modal.deny(undefined)" *ngIf="!justUploaded">Delete</button>
	<button class="ui button" (click)="modal.deny(undefined)" *ngIf="!justUploaded">Cancel</button>
	<button class="ui primary button" (click)="saveChanges()" autofocus>{{ justUploaded ? 'Upload' : 'Save' }}</button>
</div>

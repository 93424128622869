import { Observable } from 'rxjs';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
var BuilderService = /** @class */ (function () {
    function BuilderService(socketService) {
        this.socketService = socketService;
    }
    BuilderService.prototype.init = function () {
        return new Observable(function (observer) {
            observer.next();
            observer.complete();
        });
    };
    BuilderService.prototype.generateVideo = function (config) {
        return this.socketService.call(Events.GENERATE_VIDEO, undefined, config);
    };
    BuilderService.prototype.checkVideoStatus = function (id) {
        return this.socketService.call(Events.CHECK_VIDEO_STATUS, undefined, id);
    };
    BuilderService.prototype.getVideoLink = function (id) {
        return this.socketService.call(Events.GET_VIDEO_LINK, undefined, id);
    };
    BuilderService.prototype.getProjects = function () {
        return this.socketService.call(Events.LIST_PROJECTS, undefined);
    };
    BuilderService.prototype.createProject = function (name, body) {
        return this.socketService.call(Events.CREATE_PROJECT, undefined, name, body);
    };
    BuilderService.prototype.deleteProject = function (projectId) {
        return this.socketService.call(Events.DELETE_PROJECT, undefined, projectId);
    };
    BuilderService.prototype.editProject = function (projectId, name, body) {
        return this.socketService.call(Events.EDIT_PROJECT, undefined, projectId, name, body);
    };
    BuilderService.prototype.getTemplates = function () {
        return this.socketService.call(Events.LIST_TEMPLATES, undefined);
    };
    BuilderService.prototype.createTemplate = function (name, body) {
        return this.socketService.call(Events.CREATE_TEMPLATE, undefined, name, body);
    };
    BuilderService.prototype.deleteTemplate = function (templateId) {
        return this.socketService.call(Events.DELETE_TEMPLATE, undefined, templateId);
    };
    BuilderService.prototype.editTemplate = function (templateId, name, body) {
        return this.socketService.call(Events.EDIT_TEMPLATE, undefined, templateId, name, body);
    };
    BuilderService.ngInjectableDef = i0.defineInjectable({ factory: function BuilderService_Factory() { return new BuilderService(i0.inject(i1.SocketService)); }, token: BuilderService, providedIn: "root" });
    return BuilderService;
}());
export { BuilderService };

import { ElementPropertyDisplayType, ElementPropertyValueType } from '../../builder.enum';
import { IElement, IElementProperty, IElementPropertyEnumOption } from '../../builder.interface';

export class ImageValueProperty implements IElementProperty {
	label: string = 'Image';
	value: string = 'value';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.FILE;
	accept: string = 'image/*';
}

export class ImageCropProperty implements IElementProperty {
	label: string = 'Crop';
	value: string = 'properties["image::crop"]';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.ENUM;
	options: { label: string; value: string }[] = [
		{ label: 'Cover', value: 'cover' },
		{ label: 'Fit', value: 'fit' }
	];
}
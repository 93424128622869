import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { FilePickerModalComponent } from './filePicker.modal';
var FilePickerModal = /** @class */ (function (_super) {
    tslib_1.__extends(FilePickerModal, _super);
    function FilePickerModal(type, supportsMultiple, showUploadNew, sessionToken, size) {
        if (supportsMultiple === void 0) { supportsMultiple = true; }
        if (showUploadNew === void 0) { showUploadNew = true; }
        if (sessionToken === void 0) { sessionToken = ''; }
        if (size === void 0) { size = ModalSize.Normal; }
        var _this = _super.call(this, FilePickerModalComponent, { type: type, supportsMultiple: supportsMultiple, showUploadNew: showUploadNew, sessionToken: sessionToken }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return FilePickerModal;
}(ComponentModalConfig));
export { FilePickerModal };

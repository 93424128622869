import { AfterViewInit, Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';

// Import as ESM to placate typescript transpilation
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm';
import { WaveformService } from '../../_services/waveform.service';

@Component({
	selector: 'app-waveform',
	templateUrl: './waveform.component.html',
	styleUrls: ['./waveform.component.scss'],
})
export class WaveformComponent implements AfterViewInit {
    @ViewChild('musicContainer') musicContainerElement: ElementRef;

    @Input()
    public url: string;

    @Input()
    public controls: boolean = false;

    private _pxPerSecond: number;

    @Input() set pxPerSecond(value: number) {
        this._pxPerSecond = value;

        if (this.waveSurferInstance && !this.isDrawing) {
            this.isDrawing = true;
            this.waveSurferInstance.zoom(this._pxPerSecond);
        }
     }
     
     get pxPerSecond(): number {
         return this._pxPerSecond;
     }

    @Input()
    public interactable: boolean = false;

    @Input()
    public heightScalingFactor: number = 1;

    private waveSurferInstance: WaveSurfer;

    public isLoaded: boolean = false;

    private isDrawing: boolean = true;

    constructor(private waveformService: WaveformService) {

    }

    private initializeWavesurfer(): void {
        this.waveSurferInstance = WaveSurfer.create({
            container: this.musicContainerElement.nativeElement,
            waveColor: '#145070',
            progressColor: '#042e41',
            cursorWidth: 0,
            minPxPerSec: this.pxPerSecond,
            barHeight: this.heightScalingFactor,
            interact: this.interactable,
            url: this.url,
        });

        this.waveformService.registerWaveformInstance(this.waveSurferInstance);

        this.waveSurferInstance.on('redrawcomplete', () => {
            this.isDrawing = false;
        });

        this.waveSurferInstance.on('ready', () => {
            this.isLoaded = true;
        });
    }

    public isPlaying(): boolean {
        if (!this.waveSurferInstance) {
            return false;
        }

        return this.waveSurferInstance.isPlaying();
    }

    public play(): void {
        this.waveSurferInstance.play();
    }

    public stop(): void {
        this.waveSurferInstance.stop();
    }

    public onPlayClick(event: MouseEvent): void {
        event.stopPropagation();
        this.play();
    }

    public onStopClick(event: MouseEvent): void {
        event.stopPropagation();
        this.stop();
    }

    ngAfterViewInit(): void {
        this.initializeWavesurfer();
    }
}

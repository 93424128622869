import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import IDirectory from '../../_interfaces/IDirectory';
import IFile from '../../_interfaces/IFile';
import IResourceModalContext from './resource.interface';
import { ResourceModalComponent } from './resource.modal';

export class ResourceModal extends ComponentModalConfig<IResourceModalContext, void, void> {
	constructor(resource: IFile | IDirectory, justUploaded: boolean = false, availableTags: any[] = [], size = ModalSize.Small) {
		super(ResourceModalComponent, { resource, justUploaded, availableTags });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

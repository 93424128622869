<app-notifications></app-notifications>

<router-outlet></router-outlet>

<div class="ui active inverted dimmer cdm-loader" [ngClass]="{ 'cdm-loader--visible': loaderService.isLoading() }">
	<div class="ui text loader cdm-loader__indeterminate" *ngIf="loaderService.isIndeterminateLoading()">{{
		loaderService.getLoadingText() }}</div>
	<sui-progress class="active blue cdm-loader__determinate" *ngIf="loaderService.isDeterminateLoading()"
		[value]="loaderService.getPercentageLoadingValue()">{{ loaderService.getLoadingText() }}</sui-progress>
</div>

<div class="cdm-background"></div>
import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class CenterToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'center';
	icon: string = 'compress';
	tooltip: string = 'Center an element in the middle of the screen';
	label: string = 'Center element';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const elementWidth = activeElement.size.width;
		const elementHeight = activeElement.size.height;

		const positionXProperty = context.getPropertyType(ElementType.ANY, 'position.x');
		const positionYProperty = context.getPropertyType(ElementType.ANY, 'position.y');

		const centeredXPosition = (context.getResolution().width - elementWidth) / 2;
		const centeredYPosition = (context.getResolution().height - elementHeight) / 2;

		context.setElementProperty(activeElement, positionXProperty, centeredXPosition);
		context.setElementProperty(activeElement, positionYProperty, centeredYPosition);
	}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./builder.modal.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./builder.modal";
import * as i4 from "../../_services/loader.service";
import * as i5 from "../../_services/modal.service";
import * as i6 from "../../_services/filesystem.service";
import * as i7 from "../../_services/notification.service";
import * as i8 from "ng2-semantic-ui/dist/modules/modal/classes/modal-controls";
var styles_BuilderModalComponent = [i0.styles];
var RenderType_BuilderModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BuilderModalComponent, data: {} });
export { RenderType_BuilderModalComponent as RenderType_BuilderModalComponent };
export function View_BuilderModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { video: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Video generated"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "cdm-builder-green-screen"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "cdm-builder-green-screen--loading": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "../../assets/video-green-screen.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["video", 1]], null, 1, "video", [["autoplay", ""], ["loop", ""], ["muted", ""], ["onloadedmetadata", "this.muted = true"]], null, [[null, "canplay"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("canplay" === en)) {
        var pd_0 = (_co.onCanPlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "ui buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "ui button primary cdm-builder-download"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "download icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download Video"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "ui button primary cdm-builder-download"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addToLibrary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "upload icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add to library"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["autofocus", ""], ["class", "ui button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cdm-builder-green-screen"; var currVal_1 = _ck(_v, 7, 0, !_co.readyToPlay); _ck(_v, 6, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.url; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.hasDownloaded; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.hasAddedToLibrary; _ck(_v, 15, 0, currVal_4); }); }
export function View_BuilderModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-builder", [], null, null, null, View_BuilderModalComponent_0, RenderType_BuilderModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.BuilderModalComponent, [i4.LoaderService, i5.ModalService, i6.FilesystemService, i7.NotificationService, i8.Modal], null, null)], null, null); }
var BuilderModalComponentNgFactory = i1.ɵccf("app-modal-builder", i3.BuilderModalComponent, View_BuilderModalComponent_Host_0, {}, {}, []);
export { BuilderModalComponentNgFactory as BuilderModalComponentNgFactory };

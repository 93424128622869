import { ElementType, ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';
import { VideoElement } from '../elements/video/video.element';

export class ExpandToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'expand';
	icon: string = 'expand';
	tooltip: string = 'Resize an element to fit the screen';
  label: string = 'Expand element';

  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const elementWidth = activeElement.size.width;
		const elementHeight = activeElement.size.height;

		const positionXProperty = context.getPropertyType(ElementType.ANY, 'position.x');
		const positionYProperty = context.getPropertyType(ElementType.ANY, 'position.y');

		const sizeWidthProperty = context.getPropertyType(ElementType.ANY, 'size.width');
		const sizeHeightProperty = context.getPropertyType(ElementType.ANY, 'size.height');

		if (elementWidth === context.getResolution().width && elementHeight === context.getResolution().height) {
			context.setElementProperty(activeElement, sizeWidthProperty, 320);
			context.setElementProperty(activeElement, sizeHeightProperty, 240);
		} else {
			context.setElementProperty(activeElement, positionXProperty, 0);
			context.setElementProperty(activeElement, positionYProperty, 0);

			context.setElementProperty(activeElement, sizeWidthProperty, context.getResolution().width);
			context.setElementProperty(activeElement,sizeHeightProperty, context.getResolution().height);
		}
	}
}

import { SuiModal } from 'ng2-semantic-ui';

import { Component } from '@angular/core';

import IInputModalContext from './input.interface';

@Component({
	selector: 'app-modal-input',
	templateUrl: './input.modal.html',
	styleUrls: ['./input.modal.scss'],
})
export class InputModalComponent {
	public header: string;
	public message: { header: string, text: string } | undefined;
	public input: { label: string };

	public value: string = '';

	constructor(public modal: SuiModal<IInputModalContext, string, void>) {
		this.header = modal.context.header;
		this.message = modal.context.message;
		this.input = modal.context.input;
	}

	public hasValue(): boolean {
		return !!this.value && this.value.length > 0;
	}

	public async save() {
		this.modal.approve(this.value);
	}
}

import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class LayerBackToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'layer_back';
	icon: string = 'angle double down';
	tooltip: string = 'Move an element all the way to the back';
	label: string = 'Move to back';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const indexProperty = context.getPropertyType(ElementType.ANY, 'index');
		const minimumIndex = context.getActiveSlide()!.elements.map(element => element.index);
		const newIndex = Math.max(0, Math.min(...minimumIndex) - 1);

		context.setElementProperty(activeElement, indexProperty, newIndex);
	}
}

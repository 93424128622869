import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class BackgroundColorToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'background_color';
	label: string = 'Background';
	icon: string = 'paint brush';
	tooltip: string = 'Assign a color to the background';
	actionType: ToolbarActionType = ToolbarActionType.COLOR;

	execute(context: IVideoBuilderContext, color: string): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const backgroundColorProperty = context.getPropertyType(ElementType.ANY, 'background');

		context.setElementProperty(activeElement, backgroundColorProperty, color);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const backgroundColorProperty = context.getPropertyType(ElementType.ANY, 'background');

		return context.getElementProperty(context.getActiveElement(), backgroundColorProperty);
	}
}

import * as tslib_1 from "tslib";
import { catchError } from 'rxjs/internal/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ParallelHasher } from 'ts-md5';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpEventType } from '@angular/common/http';
import { ApplicationRef, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { BuilderModal } from '../../_modals/builder/builder.export';
import { BuildingModal } from '../../_modals/building/building.export';
import { ConfirmModal } from '../../_modals/confirm/confirm.export';
import { FilePickerModal } from '../../_modals/filePicker/filePicker.export';
import { BuilderService } from '../../_services/builder.service';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import { LibraryService } from '../../_services/library.service';
import { checkForClamps, clamp, createClampInterval, naiveDeepCopy, roundToNearest, sleep } from '../../_util/functions';
import { ElementPropertyDisplayType, ElementType, TransformHandle, ToolbarActionType, AnimationTriggerType, AnimationType, SlideTransition, SavedProjectType, ProjectOrientation, ProjectResolution, AnimationSpeed } from './builder.enum';
import { BackgroundProperty, HeightProperty, LayerProperty, PositionXProperty, PositionYProperty, RotationProperty, WidthProperty } from './elements/generic/generic.properties';
import { ImageElement } from './elements/image/image.element';
import { ImageCropProperty, ImageValueProperty } from './elements/image/image.properties';
import { ShapeElement } from './elements/shape/shape.element';
import { TextElement } from './elements/text/text.element';
import { TextAligmentProperty, TextFontColorProperty, TextFontProperty, TextFontEffectColorProperty, TextFontEffectSizeProperty, TextFontSizeProperty, TextFontStyleProperty, TextFontUnderlineProperty, TextFontWeightProperty, TextValueProperty, TextFontEffectProperty } from './elements/text/text.properties';
import { VideoElement } from './elements/video/video.element';
import { VideoCropProperty, VideoMutedProperty, VideoValueProperty } from './elements/video/video.properties';
import { AddImageToolbarAction } from './toolbar/add-image.toolbar';
import { AddShapeToolbarAction } from './toolbar/add-shape.toolbar';
import { AddTextToolbarAction } from './toolbar/add-text.toolbar';
import { AddVideoToolbarAction } from './toolbar/add-video.toolbar';
import { AlignTextCenterToolbarAction } from './toolbar/align-text-center.toolbar';
import { AlignTextLeftToolbarAction } from './toolbar/align-text-left.toolbar';
import { AlignTextRightToolbarAction } from './toolbar/align-text-right.toolbar';
import { CenterToolbarAction } from './toolbar/center.toolbar.toolbar';
import { DeleteToolbarAction } from './toolbar/delete.toolbar';
import { LayerDownToolbarAction } from './toolbar/layer-down.toolbar';
import { LayerUpToolbarAction } from './toolbar/layer-up.toolbar';
import { LayerFrontToolbarAction } from './toolbar/layer-front.toolbar';
import { LayerBackToolbarAction } from './toolbar/layer-back.toolbar';
import { ToggleBoldToolbarAction } from './toolbar/toggle-bold.toolbar';
import { ToggleItalicToolbarAction } from './toolbar/toggle-italic.toolbar';
import { ToggleUnderlineToolbarAction } from './toolbar/toggle-underline.toolbar';
import { FontColorToolbarAction } from './toolbar/font-color.toolbar';
import { BackgroundColorToolbarAction } from './toolbar/background-color.toolbar';
import { FontTypeToolbarAction } from './toolbar/font-type.toolbar';
import { CloneToolbarAction } from './toolbar/clone.toolbar';
import { FontSizeToolbarAction } from './toolbar/font-size.toolbar';
import { SelectImageToolbarAction } from './toolbar/select-image.toolbar';
import { SelectVideoToolbarAction } from './toolbar/select-video.toolbar';
import { SlideColorToolbarAction } from './toolbar/slide-color.toolbar';
import { FontEffectColorToolbarAction } from './toolbar/font-effect-color.toolbar';
import { FontEffectSizeToolbarAction } from './toolbar/font-effect-radius.toolbar';
import { FontEffectToolbarAction } from './toolbar/font-effect.toolbar';
import { ExpandToolbarAction } from './toolbar/expand.toolbar';
import { AddAnimationToolbarAction } from './toolbar/add-animation.toolbar';
import { WaveformService } from '../../_services/waveform.service';
import { InputModal } from '../../_modals/input/input.export';
import { ModalService } from '@citadel/common-frontend/_services/modal.service';
import SVGBox from './svg/types/box.definition';
import SVGArrow from './svg/types/arrow.definition';
import { SVGType } from './svg/svg.definition';
import { ShapeFillProperty, ShapeStrokeProperty, ShapeThicknessProperty } from './elements/shape/shape.properties';
import { ShapeFillToolbarAction } from './toolbar/shape-fill.toolbar';
import { ShapeStrokeToolbarAction } from './toolbar/shape-stroke.toolbar';
import { ShapeThicknessToolbarAction } from './toolbar/shape-thickness.toolbar';
import SVGCircle from './svg/types/circle.definition';
import { ImageCropToolbarAction } from './toolbar/image-crop.toolbar.';
import { VideoCropToolbarAction } from './toolbar/video-crop.toolbar';
var BuilderComponent = /** @class */ (function () {
    function BuilderComponent(sanitizer, loader, modal, filesystem, waveform, notification, builder, library, ref) {
        var _a, _b, _c, _d;
        var _this = this;
        this.sanitizer = sanitizer;
        this.loader = loader;
        this.modal = modal;
        this.filesystem = filesystem;
        this.waveform = waveform;
        this.notification = notification;
        this.builder = builder;
        this.library = library;
        this.ref = ref;
        this.DEFAULT_TRANSFORM_CLAMP_THRESHOLD = 8;
        this.DEFAULT_ROTATION_CLAMP_THRESHOLD = 2;
        this.MINIMUM_SELECTION_THRESHOLD = 8;
        this.ROTATION_WIDGET_OFFSET = 58;
        this.MEDIA_LIBRARY_MAX_DEFAULT_WIDTH = 480;
        this.DEFAULT_SLIDE_LENGTH = 10;
        this.LIBRARY_SUMMARY_VIEW_PAGE_SIZE = 4;
        this.LIBRARY_DETAILED_VIEW_PAGE_SIZE = 20;
        this.isEditorDirty = false;
        this.stateHistory = {
            index: 0,
            history: [],
        };
        this.defaultSlide = {
            transition: {
                in: '',
                out: '',
                crossfade: false
            },
            background: '#f2f2f2',
            duration: this.DEFAULT_SLIDE_LENGTH,
            animations: [],
            elements: [],
        };
        this.types = [
            {
                type: ElementType.SHAPE,
                properties: [
                    new ShapeFillProperty(),
                    new ShapeThicknessProperty(),
                    new ShapeStrokeProperty()
                ]
            },
            {
                type: ElementType.IMAGE,
                properties: [new ImageValueProperty(), new ImageCropProperty()],
            },
            {
                type: ElementType.VIDEO,
                properties: [new VideoValueProperty(), new VideoMutedProperty(), new VideoCropProperty()],
            },
            {
                type: ElementType.TEXT,
                properties: [
                    new TextValueProperty(),
                    new TextAligmentProperty(),
                    new TextFontProperty(),
                    new TextFontSizeProperty(),
                    new TextFontColorProperty(),
                    new TextFontStyleProperty(),
                    new TextFontWeightProperty(),
                    new TextFontUnderlineProperty(),
                    new TextFontEffectColorProperty(),
                    new TextFontEffectSizeProperty(),
                    new TextFontEffectProperty()
                ],
            },
            {
                type: ElementType.ANY,
                properties: [new BackgroundProperty(), new RotationProperty(), new WidthProperty(), new HeightProperty(), new PositionXProperty(), new PositionYProperty(), new LayerProperty()],
            },
        ];
        this.elements = [
            {
                group: 'Media',
                visible: function () { return true; },
                actions: [new AddImageToolbarAction(), new AddVideoToolbarAction(), new AddTextToolbarAction()],
            },
            {
                group: 'Shapes',
                visible: function () { return true; },
                actions: [new AddShapeToolbarAction()],
            },
        ];
        this.hotbar = [
            {
                group: 'Edit Video',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.VIDEO; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new SelectVideoToolbarAction(),
                ],
            },
            {
                group: 'Edit Image',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.IMAGE; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new SelectImageToolbarAction(),
                ],
            },
            {
                group: 'Clone',
                visible: function () { return !!_this.selectedElement; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new CloneToolbarAction(),
                ],
            },
            {
                group: 'Font Style',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new ToggleBoldToolbarAction(),
                    new ToggleItalicToolbarAction(),
                    new ToggleUnderlineToolbarAction()
                ],
            },
            {
                group: 'Center',
                visible: function () { return !!_this.selectedElement; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new CenterToolbarAction(),
                ],
            },
            {
                group: 'Expand',
                visible: function () { return !!_this.selectedElement; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new ExpandToolbarAction(),
                ],
            },
            {
                group: 'Delete',
                visible: function () { return !!_this.selectedElement; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new DeleteToolbarAction(),
                ],
            }
        ];
        this.contextMenu = [
            {
                group: 'Add Elements',
                visible: function (element) { return false; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new AddImageToolbarAction(),
                    new AddVideoToolbarAction(),
                    new AddTextToolbarAction(),
                    new AddShapeToolbarAction()
                ],
            },
            {
                group: 'Clone',
                visible: function (element) { return _this.checkContextMenuElement(element); },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new CloneToolbarAction(),
                ],
            },
            {
                group: 'Image File',
                visible: function (element) { return _this.checkContextMenuElement(element, ElementType.IMAGE); },
                type: ToolbarActionType.FILE,
                actions: [
                    new SelectImageToolbarAction()
                ]
            },
            {
                group: 'Video File',
                visible: function (element) { return _this.checkContextMenuElement(element, ElementType.VIDEO); },
                type: ToolbarActionType.FILE,
                actions: [
                    new SelectVideoToolbarAction()
                ]
            },
            {
                group: 'Sizing',
                visible: function (element) { return _this.checkContextMenuElement(element); },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new CenterToolbarAction()
                ],
            },
            {
                group: 'Animate',
                visible: function (element) { return _this.checkContextMenuElement(element); },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new AddAnimationToolbarAction()
                ],
            },
            {
                group: 'Layers',
                visible: function (element) { return _this.checkContextMenuElement(element); },
                actions: [
                    new LayerFrontToolbarAction(),
                    new LayerBackToolbarAction()
                ],
            },
            {
                group: 'Delete',
                visible: function (element) { return _this.checkContextMenuElement(element); },
                actions: [
                    new DeleteToolbarAction()
                ],
            }
        ];
        this.globalToolbar = [
        // {
        //   group: 'State management',
        //   visible: () => true,
        //   buttons: [new UndoToolbarAction(), new RedoToolbarAction()],
        // },
        ];
        this.toolbar = [
            {
                group: 'Slide Background',
                visible: function () { return !!_this.currentSlide; },
                type: ToolbarActionType.COLOR,
                actions: [
                    new SlideColorToolbarAction()
                ],
            },
            {
                group: 'Layers',
                visible: function () { return !!_this.selectedElement; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new LayerFrontToolbarAction(),
                    new LayerUpToolbarAction(),
                    new LayerDownToolbarAction(),
                    new LayerBackToolbarAction()
                ],
            },
            {
                group: 'Alignment',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT; },
                type: ToolbarActionType.BUTTON,
                actions: [
                    new AlignTextLeftToolbarAction(),
                    new AlignTextCenterToolbarAction(),
                    new AlignTextRightToolbarAction()
                ],
            },
            {
                group: 'Font Color',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT; },
                type: ToolbarActionType.COLOR,
                actions: [
                    new FontColorToolbarAction()
                ],
            },
            {
                group: 'Font Type',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT; },
                type: ToolbarActionType.DROPDOWN,
                actions: [
                    new FontTypeToolbarAction()
                ],
            },
            {
                group: 'Font Size',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT; },
                type: ToolbarActionType.INPUT,
                actions: [
                    new FontSizeToolbarAction()
                ]
            },
            {
                group: 'Font Effect',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT; },
                type: ToolbarActionType.DROPDOWN,
                actions: [
                    new FontEffectToolbarAction()
                ]
            },
            {
                group: 'Font Effect Color',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT && _this.selectedElement.properties['font::effect'] !== 'none'; },
                type: ToolbarActionType.COLOR,
                actions: [
                    new FontEffectColorToolbarAction()
                ]
            },
            {
                group: 'Font Effect Size',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.TEXT && _this.selectedElement.properties['font::effect'] !== 'none'; },
                type: ToolbarActionType.INPUT,
                actions: [
                    new FontEffectSizeToolbarAction()
                ]
            },
            {
                group: 'Background',
                visible: function () { return !!_this.selectedElement; },
                type: ToolbarActionType.COLOR,
                actions: [
                    new BackgroundColorToolbarAction()
                ]
            },
            {
                group: 'Image crop',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.IMAGE; },
                type: ToolbarActionType.DROPDOWN,
                actions: [
                    new ImageCropToolbarAction()
                ]
            },
            {
                group: 'Video crop',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.VIDEO; },
                type: ToolbarActionType.DROPDOWN,
                actions: [
                    new VideoCropToolbarAction()
                ]
            },
            {
                group: 'Image File',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.IMAGE; },
                type: ToolbarActionType.FILE,
                actions: [
                    new SelectImageToolbarAction()
                ]
            },
            {
                group: 'Video File',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.VIDEO; },
                type: ToolbarActionType.FILE,
                actions: [
                    new SelectVideoToolbarAction()
                ]
            },
            {
                group: 'Shape Fill',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.SHAPE; },
                type: ToolbarActionType.COLOR,
                actions: [
                    new ShapeFillToolbarAction(),
                    new ShapeStrokeToolbarAction(),
                ]
            },
            {
                group: 'Shape Thickness',
                visible: function () { return !!_this.selectedElement && _this.selectedElement.type === ElementType.SHAPE; },
                type: ToolbarActionType.INPUT,
                actions: [
                    new ShapeThicknessToolbarAction(),
                ]
            }
        ];
        this.transitions = [
            {
                label: 'None', value: AnimationType.NONE
            },
            {
                label: 'Fade', value: AnimationType.FADE
            },
            {
                label: 'Reveal', value: AnimationType.REVEAL
            },
            {
                label: 'Wipe',
                options: [
                    {
                        label: 'Wipe Right',
                        value: AnimationType.WIPE_RIGHT
                    },
                    {
                        label: 'Wipe Left',
                        value: AnimationType.WIPE_LEFT
                    }
                ]
            },
            {
                label: 'Slide',
                options: [
                    {
                        label: 'Slide Right',
                        value: AnimationType.SLIDE_RIGHT
                    },
                    {
                        label: 'Slide Left',
                        value: AnimationType.SLIDE_LEFT
                    },
                    {
                        label: 'Slide Up',
                        value: AnimationType.SLIDE_UP
                    },
                    {
                        label: 'Slide Down',
                        value: AnimationType.SLIDE_DOWN
                    }
                ]
            },
            {
                label: 'Carousel',
                options: [
                    {
                        label: 'Carousel Right',
                        value: AnimationType.CAROUSEL_RIGHT
                    },
                    {
                        label: 'Carousel Left',
                        value: AnimationType.CAROUSEL_LEFT
                    },
                    {
                        label: 'Carousel Up',
                        value: AnimationType.CAROUSEL_RIGHT
                    },
                    {
                        label: 'Carousel Down',
                        value: AnimationType.CAROUSEL_DOWN
                    }
                ]
            },
            {
                label: 'Zoom', value: AnimationType.ZOOM
            }
        ];
        this.slideTransitions = [
            { label: 'None', value: SlideTransition.NONE },
            { label: 'Blocks', value: SlideTransition.BLOCKS },
            { label: 'Lines', value: SlideTransition.LINES },
            { label: 'Radial', value: SlideTransition.RADIAL }
        ];
        this.animationTriggers = [
            { label: 'When the slide begins', value: AnimationTriggerType.INITIAL },
            { label: 'With previous animation', value: AnimationTriggerType.CONCURRENT },
            { label: 'After previous animation', value: AnimationTriggerType.DELAY },
        ];
        this.animationSpeed = [
            { label: 'Slow', value: AnimationSpeed.SLOW },
            { label: 'Normal', value: AnimationSpeed.NORMAL },
            { label: 'Fast', value: AnimationSpeed.FAST }
        ];
        this.music = [{ name: 'No music', value: undefined, duration: 0 }];
        this.multiSelectedElements = [];
        this.dragging = false;
        this.transforming = false;
        this.rotating = false;
        this.editingText = false;
        this.shaping = false;
        this.mousePivotPointX = 0;
        this.mousePivotPointY = 0;
        this.initialRotation = 0;
        this.timelineZoomFactor = 1;
        this.isControlPressed = false;
        this.isExpandingSlideDurationLeft = false;
        this.isExpandingSlideDurationRight = false;
        this.isDraggingSelectionBox = false;
        this.isDraggingSelectionMoveBox = false;
        this.croppingMusic = false;
        this.viewportOptions = (_a = {},
            _a[ProjectOrientation.HORIZONTAL] = {
                editor: {
                    width: 1024,
                    height: 576,
                },
                thumbnail: {
                    width: 288,
                    height: 162,
                },
                track: {
                    width: 192,
                    height: 108,
                },
                template: {
                    width: 140,
                    height: 78.7,
                },
            },
            _a[ProjectOrientation.VERTICAL] = {
                editor: {
                    width: 405,
                    height: 720
                },
                thumbnail: {
                    width: 162,
                    height: 288
                },
                track: {
                    width: 60,
                    height: 108
                },
                template: {
                    width: 44.3,
                    height: 78.7
                },
            },
            _a);
        this.resolutionOptions = (_b = {},
            _b[ProjectResolution.HD] = (_c = {},
                _c[ProjectOrientation.HORIZONTAL] = {
                    width: 1280,
                    height: 720
                },
                _c[ProjectOrientation.VERTICAL] = {
                    width: 720,
                    height: 1280
                },
                _c),
            _b[ProjectResolution.FULL_HD] = (_d = {},
                _d[ProjectOrientation.HORIZONTAL] = {
                    width: 1920,
                    height: 1080
                },
                _d[ProjectOrientation.VERTICAL] = {
                    width: 1080,
                    height: 1920
                },
                _d),
            _b);
        this.creation = {
            music: {
                name: '',
                value: undefined,
                duration: 0,
            },
            resources: {},
            slides: [],
            output: {
                orientation: ProjectOrientation.HORIZONTAL,
                resolution: {
                    type: ProjectResolution.HD,
                    width: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].width,
                    height: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].height
                }
            }
        };
        this.templates = [];
        this.projects = [];
        this.sidebarIsActive = false;
        this.sidebarActivity = '';
        this.shapes = [];
        this.librarySearchTypes = [];
        this.librarySearchResults = {};
        this.librarySearchPage = 1;
        this.librarySearchHasMore = true;
        this.librarySearchIsLoading = false;
        this.librarySearchQuery = 'landscape';
        this.librarySearchFormControl = new FormControl();
        this.templateSearchQuery = '';
        this.templateSearchFormControl = new FormControl();
        this.activityBarIsActive = false;
        this.activityBarActivity = '';
    }
    BuilderComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loadShapes();
                this.resetCreation();
                this.loadProjects();
                this.loadTemplates();
                this.registerLibrarySearchListener();
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.loadShapes = function () {
        this.shapes = [
            {
                label: 'Box',
                type: SVGType.BOX,
                model: new SVGBox(0, 0, 100),
                generator: function (x, y) { return new SVGBox(x, y, 100); }
            },
            {
                label: 'Arrow',
                type: SVGType.ARROW,
                model: new SVGArrow(10, 10, 90, 90),
                generator: function (x, y) { return new SVGArrow(x, y, x + 80, y + 80); }
            },
            {
                label: 'Circle',
                type: SVGType.CIRCLE,
                model: new SVGCircle(0, 0, 50),
                generator: function (x, y) { return new SVGCircle(x, y, 50); }
            }
        ];
    };
    BuilderComponent.prototype.registerLibrarySearchListener = function () {
        var _this = this;
        this.librarySearchFormControl.valueChanges.
            pipe(debounceTime(750), distinctUntilChanged())
            .subscribe(function (searchQuery) {
            _this.triggerLibrarySearch(searchQuery);
        });
    };
    BuilderComponent.prototype.getOrientation = function (project) {
        if (!project || !project.output || !project.output.orientation) {
            return ProjectOrientation.HORIZONTAL;
        }
        return project.output.orientation;
    };
    BuilderComponent.prototype.getResolution = function (project) {
        if (!project || !project.output || !project.output.resolution || !project.output.resolution.type) {
            return ProjectResolution.HD;
        }
        return project.output.resolution.type;
    };
    BuilderComponent.prototype.setOrientation = function (orientation) {
        var _this = this;
        if (orientation === this.getOrientation(this.creation)) {
            return;
        }
        var confirmModal = this.modal.open(new ConfirmModal('Confirmation', 'Are you sure you would like to change your project orientation? Any existing elements may be rearranged to fit the new format.'));
        confirmModal.onApprove(function () {
            _this.creation.output = {
                orientation: orientation,
                resolution: {
                    type: _this.getResolution(_this.creation),
                    width: _this.resolutionOptions[_this.getResolution(_this.creation)][orientation].width,
                    height: _this.resolutionOptions[_this.getResolution(_this.creation)][orientation].height,
                }
            };
            _this.validateAndCorrectAllElements();
        });
    };
    BuilderComponent.prototype.setResolution = function (resolution) {
        var _this = this;
        if (resolution === this.getResolution(this.creation)) {
            return;
        }
        var confirmModal = this.modal.open(new ConfirmModal('Confirmation', 'Are you sure you would like to change your project resolution? Any existing elements may be rearranged to fit the new format.'));
        confirmModal.onApprove(function () {
            _this.creation.output = {
                orientation: _this.getOrientation(_this.creation),
                resolution: {
                    type: resolution,
                    width: _this.resolutionOptions[resolution][_this.getOrientation(_this.creation)].width,
                    height: _this.resolutionOptions[resolution][_this.getOrientation(_this.creation)].height,
                }
            };
            _this.validateAndCorrectAllElements();
        });
    };
    BuilderComponent.prototype.validateAndCorrectAllElements = function () {
        for (var _i = 0, _a = this.creation.slides; _i < _a.length; _i++) {
            var slide = _a[_i];
            for (var _b = 0, _c = slide.elements; _b < _c.length; _b++) {
                var element = _c[_b];
                var widthProperty = this.getPropertyType(ElementType.ANY, 'size.width');
                var heightProperty = this.getPropertyType(ElementType.ANY, 'size.height');
                var positionXProperty = this.getPropertyType(ElementType.ANY, 'position.x');
                var positionYProperty = this.getPropertyType(ElementType.ANY, 'position.y');
                this.setElementProperty(element, widthProperty, element.size.width);
                this.setElementProperty(element, heightProperty, element.size.height);
                this.setElementProperty(element, positionXProperty, element.position.x);
                this.setElementProperty(element, positionYProperty, element.position.y);
            }
        }
    };
    BuilderComponent.prototype.closeSidebar = function () {
        this.waveform.stopAllPlayback();
        this.sidebarIsActive = false;
    };
    BuilderComponent.prototype.openSidebar = function (activity) {
        this.waveform.stopAllPlayback();
        this.sidebarActivity = activity;
        this.sidebarIsActive = true;
    };
    BuilderComponent.prototype.toggleSidebar = function (activity) {
        this.waveform.stopAllPlayback();
        if (this.sidebarActivity === activity && this.sidebarIsActive) {
            this.closeSidebar();
            return;
        }
        this.openSidebar(activity);
    };
    BuilderComponent.prototype.openLibrary = function (types) {
        this.librarySearchTypes = types;
        this.searchMediaLibrary();
    };
    BuilderComponent.prototype.getCurrentLibraryResultTags = function () {
        var tags = {};
        for (var _i = 0, _a = Object.keys(this.librarySearchResults); _i < _a.length; _i++) {
            var type = _a[_i];
            for (var _b = 0, _c = this.librarySearchResults[type]; _b < _c.length; _b++) {
                var result = _c[_b];
                if (result.tags && result.tags.length > 0) {
                    for (var _d = 0, _e = result.tags; _d < _e.length; _d++) {
                        var tag = _e[_d];
                        if (!tags[tag]) {
                            tags[tag] = 0;
                        }
                        tags[tag]++;
                    }
                }
            }
        }
        var sortedTags = Object.keys(tags).sort(function (a, b) { return tags[b] - tags[a]; });
        return sortedTags;
    };
    BuilderComponent.prototype.triggerLibrarySearch = function (query) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.librarySearchQuery = query;
                this.searchMediaLibrary();
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.isViewingElements = function () {
        var types = this.getLibraryResultKeys();
        return types.indexOf('image') !== -1 || types.indexOf('graphic') !== -1 || types.indexOf('video') !== -1;
    };
    BuilderComponent.prototype.openActivityBar = function (activity) {
        this.activityBarActivity = activity;
        this.activityBarIsActive = true;
    };
    BuilderComponent.prototype.closeActivityBar = function () {
        this.activityBarIsActive = false;
    };
    BuilderComponent.prototype.toggleActivityBar = function (activity) {
        if (this.activityBarActivity === activity && this.activityBarIsActive) {
            this.closeActivityBar();
            return;
        }
        this.openActivityBar(activity);
    };
    BuilderComponent.prototype.getNestedOptionLabel = function (collection, value) {
        var search;
        search = function (array) {
            for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
                var item = array_1[_i];
                if (item.options) {
                    var found = search(item.options);
                    if (found) {
                        return found;
                    }
                }
                if (item.value === value) {
                    return item.label;
                }
            }
            return null;
        };
        return search(collection);
    };
    BuilderComponent.prototype.createTextElement = function () {
        this.closeSidebar();
        var addTextToolbarAction = new AddTextToolbarAction();
        this.executeToolbarAction(addTextToolbarAction.execute);
    };
    BuilderComponent.prototype.createShapeElement = function () {
        this.closeSidebar();
        var addShapeToolbarAction = new AddShapeToolbarAction();
        this.executeToolbarAction(addShapeToolbarAction.execute);
    };
    BuilderComponent.prototype.getMediaLibraryResults = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, count, results, _i, _b, type, maxResultLength;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.librarySearchHasMore || this.librarySearchIsLoading) {
                            return [2 /*return*/];
                        }
                        this.librarySearchIsLoading = true;
                        this.librarySearchPage++;
                        count = (this.librarySearchTypes.length > 1) ? this.LIBRARY_SUMMARY_VIEW_PAGE_SIZE : this.LIBRARY_DETAILED_VIEW_PAGE_SIZE;
                        return [4 /*yield*/, this.library.queryMediaLibraries(this.librarySearchQuery, this.librarySearchPage, count, this.librarySearchTypes).toPromise()];
                    case 1:
                        results = _c.sent();
                        for (_i = 0, _b = Object.keys(results); _i < _b.length; _i++) {
                            type = _b[_i];
                            if (!this.librarySearchResults[type]) {
                                this.librarySearchResults[type] = [];
                            }
                            (_a = this.librarySearchResults[type]).push.apply(_a, results[type]);
                        }
                        maxResultLength = Math.max.apply(Math, Object.keys(results).map(function (key) { return results[key].length; }));
                        if (maxResultLength === 0) {
                            this.librarySearchHasMore = false;
                        }
                        this.librarySearchIsLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderComponent.prototype.getTotalLibraryResultCount = function () {
        var _this = this;
        var sum = 0;
        for (var _i = 0, _a = Object.keys(this.librarySearchResults).map(function (key) { return _this.librarySearchResults[key].length; }); _i < _a.length; _i++) {
            var amount = _a[_i];
            sum += amount;
        }
        return sum;
    };
    BuilderComponent.prototype.getLibraryResultKeys = function () {
        return Object.keys(this.librarySearchResults);
    };
    BuilderComponent.prototype.searchMediaLibrary = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.librarySearchPage = 1;
                        this.librarySearchResults = {};
                        this.librarySearchHasMore = true;
                        return [4 /*yield*/, this.getMediaLibraryResults()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderComponent.prototype.getViewport = function (orientation) {
        if (orientation === void 0) { orientation = undefined; }
        if (orientation) {
            return this.viewportOptions[orientation];
        }
        return this.viewportOptions[this.getOrientation(this.creation)];
    };
    BuilderComponent.prototype.addShape = function (svg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var centerX, centerY, addShapeAction;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        centerX = (this.creation.output.resolution.width / 2);
                        centerY = (this.creation.output.resolution.height / 2);
                        addShapeAction = new AddShapeToolbarAction();
                        return [4 /*yield*/, this.executeToolbarAction(addShapeAction.execute, [svg.generator(centerX, centerY)])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderComponent.prototype.addMediaFromLibrary = function (result, x, y) {
        if (x === void 0) { x = -1; }
        if (y === void 0) { y = -1; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mediaAspectRatio, scaledWidth, scaledHeight, element, _a, addImageAction, addVideoAction, positionXProperty, positionYProperty, centerX, centerY, centerX, centerY;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        mediaAspectRatio = result.width / result.height;
                        scaledWidth = this.MEDIA_LIBRARY_MAX_DEFAULT_WIDTH;
                        scaledHeight = this.MEDIA_LIBRARY_MAX_DEFAULT_WIDTH / mediaAspectRatio;
                        element = undefined;
                        _a = result.type;
                        switch (_a) {
                            case 'image': return [3 /*break*/, 1];
                            case 'graphic': return [3 /*break*/, 1];
                            case 'video': return [3 /*break*/, 3];
                            case 'music': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 6];
                    case 1:
                        addImageAction = new AddImageToolbarAction();
                        return [4 /*yield*/, this.executeToolbarAction(addImageAction.execute, [result.url, scaledWidth, scaledHeight])];
                    case 2:
                        element = _b.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        addVideoAction = new AddVideoToolbarAction();
                        return [4 /*yield*/, this.executeToolbarAction(addVideoAction.execute, [result.url, scaledWidth, scaledHeight])];
                    case 4:
                        element = _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        this.setMusicFromURL(result.description, result.url, 60);
                        return [3 /*break*/, 6];
                    case 6:
                        if (element) {
                            this.setActiveElement(element);
                            positionXProperty = this.getPropertyType(ElementType.ANY, 'position.x');
                            positionYProperty = this.getPropertyType(ElementType.ANY, 'position.y');
                            if (element && x !== -1 && y !== -1) {
                                centerX = x - (element.size.width / 2);
                                centerY = y - (element.size.height / 2);
                                this.setElementProperty(this.selectedElement, positionXProperty, centerX);
                                this.setElementProperty(this.selectedElement, positionYProperty, centerY);
                            }
                            else {
                                centerX = (this.creation.output.resolution.width / 2) - (element.size.width / 2);
                                centerY = (this.creation.output.resolution.height / 2) - (element.size.height / 2);
                                this.setElementProperty(this.selectedElement, positionXProperty, centerX);
                                this.setElementProperty(this.selectedElement, positionYProperty, centerY);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderComponent.prototype.checkContextMenuElement = function (element, type) {
        if (type === void 0) { type = undefined; }
        if (element) {
            if (type && element.type !== type) {
                return false;
            }
            this.setActiveElement(element);
            return true;
        }
        return false;
    };
    BuilderComponent.prototype.onContainerDrop = function (e) {
        var event = e.event, data = e.data;
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var mouseX = event.clientX - contentElementBoundingBox.x;
        var mouseY = event.clientY - contentElementBoundingBox.y;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentAdjustedX = mouseX / contentRatioX;
        var contentAdjustedY = mouseY / contentRatioY;
        this.addMediaFromLibrary(data, contentAdjustedX, contentAdjustedY);
    };
    BuilderComponent.prototype.onContainerDragover = function (e) {
        var mouseX = e.clientX;
        var mouseY = e.clientY;
    };
    BuilderComponent.prototype.getElementsSnappingPoints = function (exclude) {
        if (exclude === void 0) { exclude = undefined; }
        if (!this.currentSlide) {
            return { upper: { x: [], y: [] }, lower: { x: [], y: [] } };
        }
        var elements = this.currentSlide.elements;
        var upperPoints = { x: [], y: [] };
        var lowerPoints = { x: [], y: [] };
        var editorWidth = this.creation.output.resolution.width;
        var editorHeight = this.creation.output.resolution.height;
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            if (element === exclude) {
                continue;
            }
            var positionX = element['position']['x'];
            var positionY = element['position']['y'];
            var sizeX = element['size']['width'];
            var sizeY = element['size']['height'];
            var lowerX = (positionX) / editorWidth;
            var upperX = (positionX + sizeX) / editorWidth;
            var lowerY = (positionY) / editorHeight;
            var upperY = (positionY + sizeY) / editorHeight;
            lowerPoints.x.push(lowerX);
            lowerPoints.y.push(lowerY);
            upperPoints.x.push(upperX);
            upperPoints.y.push(upperY);
        }
        return {
            upper: upperPoints,
            lower: lowerPoints
        };
    };
    BuilderComponent.prototype.onClickMultiselectTransformer = function (e) {
        e.stopPropagation();
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var transformerBoundingBox = this.selectorTransformerElement.nativeElement.getBoundingClientRect();
        this.isDraggingSelectionMoveBox = true;
        var mouseX = e.clientX - transformerBoundingBox.x;
        var mouseY = e.clientY - transformerBoundingBox.y;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentAdjustedX = mouseX / contentRatioX;
        var contentAdjustedY = mouseY / contentRatioY;
        var elementGrabPointX = contentAdjustedX;
        var elementGrabPointY = contentAdjustedY;
        this.mousePivotPointX = elementGrabPointX;
        this.mousePivotPointY = elementGrabPointY;
    };
    BuilderComponent.prototype.onStartElementRotate = function (element, e) {
        this.onClickElement(element, e);
        this.initialRotation = element.rotation;
        this.rotating = true;
    };
    BuilderComponent.prototype.onClickElement = function (element, e) {
        this.setActiveElement(element);
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var mouseX = e.clientX - contentElementBoundingBox.x;
        var mouseY = e.clientY - contentElementBoundingBox.y;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentAdjustedX = mouseX / contentRatioX;
        var contentAdjustedY = mouseY / contentRatioY;
        var positionX = this.selectedElement.type === 'shape' ? this.selectedElement.svg.getOriginPoint().x : this.selectedElement['position']['x'];
        var positionY = this.selectedElement.type === 'shape' ? this.selectedElement.svg.getOriginPoint().y : this.selectedElement['position']['y'];
        var elementGrabPointX = contentAdjustedX - positionX;
        var elementGrabPointY = contentAdjustedY - positionY;
        this.mousePivotPointX = elementGrabPointX;
        this.mousePivotPointY = elementGrabPointY;
    };
    BuilderComponent.prototype.handleShapeDragging = function (mouseX, mouseY) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentRatioX, contentRatioY, contentAdjustedX, contentAdjustedY, newPositionX, newPositionY, shapeElement;
            return tslib_1.__generator(this, function (_a) {
                contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
                contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
                contentAdjustedX = mouseX / contentRatioX;
                contentAdjustedY = mouseY / contentRatioY;
                newPositionX = contentAdjustedX - this.mousePivotPointX;
                newPositionY = contentAdjustedY - this.mousePivotPointY;
                shapeElement = this.selectedElement;
                shapeElement.svg.changeOrigin(newPositionX, newPositionY);
                shapeElement.svg.validateBounds(this.creation.output.resolution.width, this.creation.output.resolution.height);
                this.renderShape(shapeElement);
                return [2 /*return*/, true];
            });
        });
    };
    BuilderComponent.prototype.handleDragging = function (mouseX, mouseY) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentRatioX, contentRatioY, contentAdjustedX, contentAdjustedY, newPositionX, newPositionY, positionXProperty, positionYProperty, sizeX, sizeY, clampThreshold, elementClamps, elementsLowerXMinClamp, elementsLowerYMinClamp, elementsLowerXMaxClamp, elementsLowerYMaxClamp, elementsUpperXMinClamp, elementsUpperYMinClamp, elementsUpperXMaxClamp, elementsUpperYMaxClamp, positionXMinClamp, positionYMinClamp, positionXMaxClamp, positionYMaxClamp, centerXClamp, centerYClamp, xClamps, yClamps, _a, clampedPositionX, clampedPointValueX, _b, clampedPositionY, clampedPointValueY, setPositionXResult, setPositionYResult;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
                        contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
                        contentAdjustedX = mouseX / contentRatioX;
                        contentAdjustedY = mouseY / contentRatioY;
                        newPositionX = contentAdjustedX - this.mousePivotPointX;
                        newPositionY = contentAdjustedY - this.mousePivotPointY;
                        positionXProperty = this.getPropertyType(ElementType.ANY, 'position.x');
                        positionYProperty = this.getPropertyType(ElementType.ANY, 'position.y');
                        sizeX = this.selectedElement['size']['width'];
                        sizeY = this.selectedElement['size']['height'];
                        clampThreshold = (this.isControlPressed) ? 0 : this.DEFAULT_TRANSFORM_CLAMP_THRESHOLD;
                        elementClamps = this.getElementsSnappingPoints(this.selectedElement);
                        elementsLowerXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x);
                        elementsLowerYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y);
                        elementsLowerXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x, sizeX);
                        elementsLowerYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y, sizeY);
                        elementsUpperXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x);
                        elementsUpperYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y);
                        elementsUpperXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x, sizeX);
                        elementsUpperYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y, sizeY);
                        positionXMinClamp = createClampInterval(0, this.creation.output.resolution.width, [0]);
                        positionYMinClamp = createClampInterval(0, this.creation.output.resolution.height, [0]);
                        positionXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, [1], sizeX);
                        positionYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, [1], sizeY);
                        centerXClamp = createClampInterval(0, this.creation.output.resolution.width, [0, 0.25, 0.5, 0.75, 1], sizeX / 2);
                        centerYClamp = createClampInterval(0, this.creation.output.resolution.height, [0, 0.25, 0.5, 0.75, 1], sizeY / 2);
                        xClamps = [positionXMinClamp, positionXMaxClamp, centerXClamp, elementsLowerXMinClamp, elementsLowerXMaxClamp, elementsUpperXMinClamp, elementsUpperXMaxClamp];
                        yClamps = [positionYMinClamp, positionYMaxClamp, centerYClamp, elementsLowerYMinClamp, elementsLowerYMaxClamp, elementsUpperYMinClamp, elementsUpperYMaxClamp];
                        _a = checkForClamps(xClamps, newPositionX, clampThreshold), clampedPositionX = _a[0], clampedPointValueX = _a[1];
                        _b = checkForClamps(yClamps, newPositionY, clampThreshold), clampedPositionY = _b[0], clampedPointValueY = _b[1];
                        this.clampLineX = clampedPointValueX;
                        this.clampLineY = clampedPointValueY;
                        clampedPositionX = clamp(clampedPositionX, 0, this.creation.output.resolution.width - sizeX);
                        clampedPositionY = clamp(clampedPositionY, 0, this.creation.output.resolution.height - sizeY);
                        return [4 /*yield*/, this.setElementProperty(this.selectedElement, positionXProperty, clampedPositionX)];
                    case 1:
                        setPositionXResult = _c.sent();
                        return [4 /*yield*/, this.setElementProperty(this.selectedElement, positionYProperty, clampedPositionY)];
                    case 2:
                        setPositionYResult = _c.sent();
                        return [2 /*return*/, setPositionXResult && setPositionYResult];
                }
            });
        });
    };
    BuilderComponent.prototype.handleTransform = function (mouseX, mouseY) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentRatioX, contentRatioY, contentAdjustedX, contentAdjustedY, positionXProperty, positionYProperty, sizeWidthProperty, sizeHeightProperty, sizeX, sizeY, positionX, positionY, currentPointTopLeft, currentPointBottomLeft, currentPointTopRight, currentPointBottomRight, mousePoint, pivotPoint, clampThreshold, elementClamps, elementsLowerXMinClamp, elementsLowerYMinClamp, elementsLowerXMaxClamp, elementsLowerYMaxClamp, elementsUpperXMinClamp, elementsUpperYMinClamp, elementsUpperXMaxClamp, elementsUpperYMaxClamp, positionXClamp, positionYClamp, xClamps, yClamps, _a, clampedMousePositionX, clampedMousePointValueX, _b, clampedMousePositionY, clampedMousePointValueY, _c, clampedPivotPositionX, clampedPivotPointValueX, _d, clampedPivotPositionY, clampedPivotPointValueY, newPositionX, newPositionY, newSizeX, newSizeY;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
                        contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
                        contentAdjustedX = mouseX / contentRatioX;
                        contentAdjustedY = mouseY / contentRatioY;
                        positionXProperty = this.getPropertyType(ElementType.ANY, 'position.x');
                        positionYProperty = this.getPropertyType(ElementType.ANY, 'position.y');
                        sizeWidthProperty = this.getPropertyType(ElementType.ANY, 'size.width');
                        sizeHeightProperty = this.getPropertyType(ElementType.ANY, 'size.height');
                        sizeX = this.selectedElement['size']['width'];
                        sizeY = this.selectedElement['size']['height'];
                        positionX = this.selectedElement['position']['x'];
                        positionY = this.selectedElement['position']['y'];
                        currentPointTopLeft = { x: positionX, y: positionY };
                        currentPointBottomLeft = { x: positionX, y: positionY + sizeY };
                        currentPointTopRight = { x: positionX + sizeX, y: positionY };
                        currentPointBottomRight = { x: positionX + sizeX, y: positionY + sizeY };
                        mousePoint = { x: contentAdjustedX, y: contentAdjustedY };
                        switch (this.transformHandle) {
                            case TransformHandle.TOP_LEFT:
                                pivotPoint = currentPointBottomRight;
                                break;
                            case TransformHandle.TOP_RIGHT:
                                pivotPoint = currentPointBottomLeft;
                                break;
                            case TransformHandle.BOTTOM_LEFT:
                                pivotPoint = currentPointTopRight;
                                break;
                            case TransformHandle.BOTTOM_RIGHT:
                                pivotPoint = currentPointTopLeft;
                                break;
                        }
                        clampThreshold = (this.isControlPressed) ? 0 : this.DEFAULT_TRANSFORM_CLAMP_THRESHOLD;
                        elementClamps = this.getElementsSnappingPoints(this.selectedElement);
                        elementsLowerXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x);
                        elementsLowerYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y);
                        elementsLowerXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x, sizeX);
                        elementsLowerYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y, sizeY);
                        elementsUpperXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x);
                        elementsUpperYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y);
                        elementsUpperXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x, sizeX);
                        elementsUpperYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y, sizeY);
                        positionXClamp = createClampInterval(0, this.creation.output.resolution.width, [0, 0.5, 1]);
                        positionYClamp = createClampInterval(0, this.creation.output.resolution.height, [0, 0.5, 1]);
                        xClamps = [positionXClamp, elementsLowerXMinClamp, elementsLowerXMaxClamp, elementsUpperXMinClamp, elementsUpperXMaxClamp];
                        yClamps = [positionYClamp, elementsLowerYMinClamp, elementsLowerYMaxClamp, elementsUpperYMinClamp, elementsUpperYMaxClamp];
                        _a = checkForClamps(xClamps, mousePoint.x, clampThreshold), clampedMousePositionX = _a[0], clampedMousePointValueX = _a[1];
                        _b = checkForClamps(yClamps, mousePoint.y, clampThreshold), clampedMousePositionY = _b[0], clampedMousePointValueY = _b[1];
                        _c = checkForClamps(xClamps, pivotPoint.x, clampThreshold), clampedPivotPositionX = _c[0], clampedPivotPointValueX = _c[1];
                        _d = checkForClamps(yClamps, pivotPoint.y, clampThreshold), clampedPivotPositionY = _d[0], clampedPivotPointValueY = _d[1];
                        mousePoint.x = clampedMousePositionX;
                        mousePoint.y = clampedMousePositionY;
                        pivotPoint.x = clampedPivotPositionX;
                        pivotPoint.y = clampedPivotPositionY;
                        this.clampLineX = [clampedMousePointValueX, clampedPivotPointValueX].find(function (value) { return value !== undefined; });
                        this.clampLineY = [clampedMousePointValueY, clampedPivotPointValueY].find(function (value) { return value !== undefined; });
                        newPositionX = Math.min(mousePoint.x, pivotPoint.x);
                        newPositionY = Math.min(mousePoint.y, pivotPoint.y);
                        newSizeX = Math.max(mousePoint.x, pivotPoint.x) - newPositionX;
                        newSizeY = Math.max(mousePoint.y, pivotPoint.y) - newPositionY;
                        newPositionX = clamp(newPositionX, 0, this.creation.output.resolution.width - sizeX);
                        newPositionY = clamp(newPositionY, 0, this.creation.output.resolution.height - sizeY);
                        return [4 /*yield*/, this.setElementProperty(this.selectedElement, positionXProperty, newPositionX)];
                    case 1:
                        _e.sent();
                        return [4 /*yield*/, this.setElementProperty(this.selectedElement, positionYProperty, newPositionY)];
                    case 2:
                        _e.sent();
                        return [4 /*yield*/, this.setElementProperty(this.selectedElement, sizeWidthProperty, newSizeX)];
                    case 3:
                        _e.sent();
                        return [4 /*yield*/, this.setElementProperty(this.selectedElement, sizeHeightProperty, newSizeY)];
                    case 4:
                        _e.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    BuilderComponent.prototype.onMouseUp = function (e) {
        this.stopDragging(e);
        this.stopTransforming(e);
        this.stopShaping(e);
        this.isExpandingSlideDurationLeft = false;
        this.isExpandingSlideDurationRight = false;
        this.croppingMusic = false;
        this.lastMouseXonMusicBar = 0;
        this.isDraggingSelectionBox = false;
        this.isDraggingSelectionMoveBox = false;
        this.rotating = false;
        this.clampLineX = undefined;
        this.clampLineY = undefined;
    };
    BuilderComponent.prototype.onMouseDown = function (e) {
        this.mouseDownX = e.clientX;
        this.mouseDownY = e.clientY;
        var composedPath = e.composedPath();
        if (composedPath) {
            var videoContainerIndexInClick = composedPath.indexOf(this.videoContainerElement.nativeElement);
            // kinda hacky
            if (videoContainerIndexInClick >= 0 && videoContainerIndexInClick <= 2) {
                this.clearElement();
                this.multiSelectedElements = [];
                this.isDraggingSelectionBox = true;
            }
        }
    };
    BuilderComponent.prototype.onKeyControlDown = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isControlPressed = true;
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyControlUp = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isControlPressed = false;
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyControlX = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.selectedElement && this.currentSlide) {
                    this.currentlyCopiedElement = this.selectedElement;
                    this.currentSlide.elements = this.currentSlide.elements.filter(function (element) { return element !== _this.selectedElement; });
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyControlC = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.editingText) {
                    return [2 /*return*/];
                }
                if (this.selectedElement) {
                    this.notification.displayInfo('Copied!', { timeOut: 1000 });
                    this.currentlyCopiedElement = this.selectedElement;
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyControlV = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clonedElement;
            return tslib_1.__generator(this, function (_a) {
                if (this.editingText) {
                    return [2 /*return*/];
                }
                if (this.currentlyCopiedElement && this.currentSlide) {
                    if (this.currentlyCopiedElement.type === 'shape') {
                        this.notification.displayError('Cannot paste shape, coming soon!');
                        return [2 /*return*/];
                    }
                    clonedElement = this.currentlyCopiedElement.clone();
                    this.currentSlide.elements.push(clonedElement);
                    this.setActiveElement(clonedElement);
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyControlS = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                e.preventDefault();
                if (!this.currentProject) {
                    return [2 /*return*/];
                }
                switch (this.currentProject.type) {
                    case (SavedProjectType.PROJECT):
                        this.saveProject(false);
                        break;
                    case (SavedProjectType.TEMPLATE):
                        this.saveTemplate(false);
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyControlShiftS = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                e.preventDefault();
                if (!this.currentProject) {
                    return [2 /*return*/];
                }
                switch (this.currentProject.type) {
                    case (SavedProjectType.PROJECT):
                        this.saveProject(true);
                        break;
                    case (SavedProjectType.TEMPLATE):
                        this.saveTemplate(true);
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onKeyDelete = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, entry, element;
            return tslib_1.__generator(this, function (_b) {
                if (this.editingText) {
                    return [2 /*return*/];
                }
                if (this.selectedElement) {
                    this.removeElement(this.selectedElement);
                    this.clearElement();
                }
                if (this.multiSelectedElements.length > 0) {
                    for (_i = 0, _a = this.multiSelectedElements; _i < _a.length; _i++) {
                        entry = _a[_i];
                        element = entry.element;
                        this.removeElement(element);
                    }
                    this.multiSelectedElements = [];
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onMouseMove = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.croppingMusic) {
                    this.onCroppingMusic(e);
                }
                if (this.isExpandingSlideDurationLeft || this.isExpandingSlideDurationRight) {
                    this.onDraggingSlideDuration(e, this.isExpandingSlideDurationLeft ? 'left' : 'right');
                }
                if (this.selectedElement && this.rotating) {
                    this.onElementRotate(e);
                }
                if (this.selectedElement && (this.dragging || this.transforming)) {
                    this.onElementDrag(e);
                }
                if (this.selectedElement && (this.shaping)) {
                    this.onElementShape(e);
                }
                if (this.isDraggingSelectionBox) {
                    this.onMultiselectDrag(e);
                }
                if (this.isDraggingSelectionMoveBox) {
                    this.onMultiselectTransformDrag(e);
                }
                this.lastRawMouseX = e.clientX;
                this.lastRawMouseY = e.clientY;
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onElementRotate = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentElementBoundingBox, rawX, rawY, clampThreshold, contentRatioX, contentRatioY, centerX, centerY, contentAdjustedX, contentAdjustedY, offsetX, offsetY, rotation, rotationClamp, rotationClamps, _a, clampedRotation, clampedRotationValue, rotationProperty;
            return tslib_1.__generator(this, function (_b) {
                contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
                rawX = e.clientX - contentElementBoundingBox.x;
                rawY = e.clientY - contentElementBoundingBox.y;
                clampThreshold = (this.isControlPressed) ? 0 : this.DEFAULT_ROTATION_CLAMP_THRESHOLD;
                contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
                contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
                centerX = this.selectedElement.position.x + (this.selectedElement.size.width / 2);
                centerY = this.selectedElement.position.y + (this.selectedElement.size.height / 2);
                contentAdjustedX = rawX / contentRatioX;
                contentAdjustedY = rawY / contentRatioY;
                offsetX = contentAdjustedX - centerX;
                offsetY = contentAdjustedY - centerY;
                rotation = ((Math.atan2(offsetY, offsetX) * 180) / Math.PI) + 90;
                rotation = rotation < 0 ? 360 - Math.abs(rotation) : rotation;
                rotationClamp = createClampInterval(0, 360, [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875]);
                rotationClamps = [rotationClamp];
                _a = checkForClamps(rotationClamps, rotation, clampThreshold), clampedRotation = _a[0], clampedRotationValue = _a[1];
                rotationProperty = this.getPropertyType(ElementType.ANY, 'rotation');
                this.setElementProperty(this.selectedElement, rotationProperty, clampedRotation);
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onMultiselectTransformDrag = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentElementBoundingBox, transformerBoundingBox, rawX, rawY, clampedX, clampedY, contentRatioX, contentRatioY, contentAdjustedX, contentAdjustedY, contentAdjustedTransformerWidth, contentAdjustedTransformerHeight, transformOriginX, transformOriginY, clampedTransformOriginX, clampedTransformOriginY, minimumInitialX, minimumInitialY, _i, _a, entry, element, elementOffsetX, elementOffsetY, newPositionX, newPositionY;
            return tslib_1.__generator(this, function (_b) {
                contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
                transformerBoundingBox = this.selectorTransformerElement.nativeElement.getBoundingClientRect();
                rawX = e.clientX - contentElementBoundingBox.x;
                rawY = e.clientY - contentElementBoundingBox.y;
                clampedX = clamp(rawX, 0, contentElementBoundingBox.width);
                clampedY = clamp(rawY, 0, contentElementBoundingBox.height);
                contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
                contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
                contentAdjustedX = clampedX / contentRatioX;
                contentAdjustedY = clampedY / contentRatioY;
                contentAdjustedTransformerWidth = transformerBoundingBox.width / contentRatioX;
                contentAdjustedTransformerHeight = transformerBoundingBox.height / contentRatioY;
                transformOriginX = contentAdjustedX - this.mousePivotPointX;
                transformOriginY = contentAdjustedY - this.mousePivotPointY;
                clampedTransformOriginX = clamp(0, transformOriginX, this.creation.output.resolution.width - contentAdjustedTransformerWidth);
                clampedTransformOriginY = clamp(0, transformOriginY, this.creation.output.resolution.height - contentAdjustedTransformerHeight);
                minimumInitialX = Math.min.apply(Math, this.multiSelectedElements.map(function (entry) { return entry.element; }).map(function (element) { return element.position.x; }));
                minimumInitialY = Math.min.apply(Math, this.multiSelectedElements.map(function (entry) { return entry.element; }).map(function (element) { return element.position.y; }));
                for (_i = 0, _a = this.multiSelectedElements; _i < _a.length; _i++) {
                    entry = _a[_i];
                    element = entry.element;
                    elementOffsetX = entry.element.position.x - minimumInitialX;
                    elementOffsetY = entry.element.position.y - minimumInitialY;
                    newPositionX = clampedTransformOriginX + elementOffsetX;
                    newPositionY = clampedTransformOriginY + elementOffsetY;
                    element.position.x = newPositionX;
                    element.position.y = newPositionY;
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.isMultiselected = function (element) {
        return !!this.multiSelectedElements.find(function (entry) { return entry.element === element; });
    };
    BuilderComponent.prototype.onElementShape = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentElementBoundingBox, contentRatioX, contentRatioY, rawX, rawY, clampedX, clampedY;
            return tslib_1.__generator(this, function (_a) {
                contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
                contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
                contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
                rawX = e.clientX - contentElementBoundingBox.x;
                rawY = e.clientY - contentElementBoundingBox.y;
                clampedX = clamp(rawX, 0, contentElementBoundingBox.width);
                clampedY = clamp(rawY, 0, contentElementBoundingBox.height);
                this.shapingPoint.x = clampedX / contentRatioX;
                this.shapingPoint.y = clampedY / contentRatioY;
                this.renderShape(this.selectedElement);
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.renderShape = function (element) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                element.svg.render();
                element.onRendered();
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.onElementDrag = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentElementBoundingBox, rawX, rawY, clampedX, clampedY, mouseX, mouseY;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
                        rawX = e.clientX - contentElementBoundingBox.x;
                        rawY = e.clientY - contentElementBoundingBox.y;
                        clampedX = clamp(rawX, 0, contentElementBoundingBox.width);
                        clampedY = clamp(rawY, 0, contentElementBoundingBox.height);
                        mouseX = clampedX;
                        mouseY = clampedY;
                        if (!this.dragging) return [3 /*break*/, 5];
                        if (!(this.selectedElement.type === 'shape')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.handleShapeDragging(mouseX, mouseY)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.handleDragging(mouseX, mouseY)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        if (!this.transforming) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.handleTransform(mouseX, mouseY)];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        this.lastMouseX = mouseX;
                        this.lastMouseY = mouseY;
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderComponent.prototype.onMultiselectDrag = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentElementBoundingBox, selectorBoundingBox, lowerSelectedX, lowerSelectedY, upperSelectedX, upperSelectedY, normalizedLowerSelectedX, normalizedLowerSelectedY, normalizedUpperSelectedX, normalizedUpperSelectedY, outputWidth, outputHeight, selectedElements, _i, _a, element, positionX, positionY, sizeX, sizeY, centerX, centerY, normalizedCenterX, normalizedCenterY;
            return tslib_1.__generator(this, function (_b) {
                if (!this.currentSlide) {
                    return [2 /*return*/];
                }
                contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
                selectorBoundingBox = this.selectorElement.nativeElement.getBoundingClientRect();
                lowerSelectedX = Math.max(selectorBoundingBox.x, contentElementBoundingBox.x);
                lowerSelectedY = Math.max(selectorBoundingBox.y, contentElementBoundingBox.y);
                upperSelectedX = Math.min(selectorBoundingBox.x + selectorBoundingBox.width, contentElementBoundingBox.x + contentElementBoundingBox.width);
                upperSelectedY = Math.min(selectorBoundingBox.y + selectorBoundingBox.height, contentElementBoundingBox.y + contentElementBoundingBox.height);
                normalizedLowerSelectedX = (lowerSelectedX - contentElementBoundingBox.x) / contentElementBoundingBox.width;
                normalizedLowerSelectedY = (lowerSelectedY - contentElementBoundingBox.y) / contentElementBoundingBox.height;
                normalizedUpperSelectedX = (upperSelectedX - contentElementBoundingBox.x) / contentElementBoundingBox.width;
                normalizedUpperSelectedY = (upperSelectedY - contentElementBoundingBox.y) / contentElementBoundingBox.height;
                outputWidth = this.creation.output.resolution.width;
                outputHeight = this.creation.output.resolution.height;
                selectedElements = [];
                for (_i = 0, _a = this.currentSlide.elements; _i < _a.length; _i++) {
                    element = _a[_i];
                    positionX = element.position.x;
                    positionY = element.position.y;
                    sizeX = element.size.width;
                    sizeY = element.size.height;
                    centerX = positionX + (sizeX / 2);
                    centerY = positionY + (sizeY / 2);
                    normalizedCenterX = centerX / outputWidth;
                    normalizedCenterY = centerY / outputHeight;
                    if (normalizedCenterX > normalizedLowerSelectedX &&
                        normalizedCenterX < normalizedUpperSelectedX &&
                        normalizedCenterY > normalizedLowerSelectedY &&
                        normalizedCenterY < normalizedUpperSelectedY) {
                        selectedElements.push({
                            element: element,
                            initialX: positionX,
                            initialY: positionY
                        });
                    }
                }
                this.multiSelectedElements = selectedElements;
                if (selectedElements.length === 1) {
                    this.setActiveElement(selectedElements[0].element);
                }
                else {
                    // if we have nothing selected, or more than one item, remove this
                    this.clearElement();
                }
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.startDragging = function (e) {
        this.dragging = true;
    };
    BuilderComponent.prototype.stopDragging = function (e) {
        this.dragging = false;
    };
    BuilderComponent.prototype.addAnimation = function (element) {
        if (!this.currentSlide) {
            return;
        }
        var existingAnimation = this.currentSlide.animations.find(function (animation) { return animation.elementId === element.id; });
        if (existingAnimation) {
            return;
        }
        this.currentSlide.animations.push({
            elementId: element.id,
            trigger: AnimationTriggerType.NONE,
            transition: {
                in: AnimationType.NONE,
                out: AnimationType.NONE,
            },
            speed: AnimationSpeed.NORMAL,
            delay: undefined,
            duration: undefined
        });
        this.openActivityBar('animate');
    };
    BuilderComponent.prototype.removeAnimation = function (element) {
        if (!this.currentSlide) {
            return;
        }
        var existingAnimationIndex = this.currentSlide.animations.findIndex(function (animation) { return animation.elementId === element.id; });
        if (existingAnimationIndex === -1) {
            return;
        }
        this.currentSlide.animations.splice(existingAnimationIndex, 1);
    };
    BuilderComponent.prototype.getElementById = function (id) {
        if (!this.currentSlide) {
            return;
        }
        return this.currentSlide.elements.find(function (element) { return element.id === id; });
    };
    BuilderComponent.prototype.getAllElements = function () {
        if (!this.currentSlide) {
            return [];
        }
        return [].concat.apply([], this.creation.slides.map(function (slide) { return slide.elements; }));
    };
    BuilderComponent.prototype.startTransforming = function (e, transform) {
        this.transforming = true;
        this.transformHandle = transform;
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var mouseX = e.clientX - contentElementBoundingBox.x;
        var mouseY = e.clientY - contentElementBoundingBox.y;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentAdjustedX = mouseX / contentRatioX;
        var contentAdjustedY = mouseY / contentRatioY;
        var elementGrabPointX = contentAdjustedX;
        var elementGrabPointY = contentAdjustedY;
        this.mousePivotPointX = elementGrabPointX;
        this.mousePivotPointY = elementGrabPointY;
    };
    BuilderComponent.prototype.startShaping = function (e, point) {
        this.shaping = true;
        this.shapingPoint = point;
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var mouseX = e.clientX - contentElementBoundingBox.x;
        var mouseY = e.clientY - contentElementBoundingBox.y;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentAdjustedX = mouseX / contentRatioX;
        var contentAdjustedY = mouseY / contentRatioY;
        var elementGrabPointX = contentAdjustedX;
        var elementGrabPointY = contentAdjustedY;
        this.mousePivotPointX = elementGrabPointX;
        this.mousePivotPointY = elementGrabPointY;
    };
    BuilderComponent.prototype.stopTransforming = function (e) {
        this.transforming = false;
    };
    BuilderComponent.prototype.stopShaping = function (e) {
        this.shaping = false;
    };
    BuilderComponent.prototype.getPropertyType = function (elementType, propertyType) {
        var categoryTypeInstance = this.types.find(function (category) { return category.type === elementType; });
        if (!categoryTypeInstance) {
            throw new Error("Invalid property type requested - " + propertyType + " not found on " + elementType + "!");
        }
        var propertyTypeInstance = categoryTypeInstance.properties.find(function (property) { return property.value === propertyType; });
        if (!propertyTypeInstance) {
            throw new Error("Invalid property type requested - " + propertyType + " not found on " + elementType + "!");
        }
        return propertyTypeInstance;
    };
    BuilderComponent.prototype.getElementRotationCSS = function (element) {
        return {
            'transform': "rotate(" + element.rotation + "deg)"
        };
    };
    BuilderComponent.prototype.getRotatorBoxCSS = function (element) {
        var _a, _b;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        if (!this.rotating) {
            return _a = {}, _a['display'] = 'none', _a;
        }
        var contentAdjustedWidth = (element.size.width * contentRatioX);
        var diameter = (element.size.height * contentRatioY) + this.ROTATION_WIDGET_OFFSET;
        return _b = {},
            _b['width'] = diameter + "px",
            _b['height'] = diameter + "px",
            _b['transform'] = "translate(" + ((-diameter / 2) + contentAdjustedWidth / 2) + "px, 0) rotate(" + element.rotation + "deg)",
            _b;
    };
    BuilderComponent.prototype.getClampXLineCSS = function () {
        var _a, _b;
        if (this.clampLineX === undefined) {
            return _a = {}, _a['display'] = 'none', _a;
        }
        return _b = {}, _b['left'] = this.clampLineX * 100 + "%", _b;
    };
    BuilderComponent.prototype.getClampYLineCSS = function () {
        var _a, _b;
        if (this.clampLineY === undefined) {
            return _a = {}, _a['display'] = 'none', _a;
        }
        return _b = {}, _b['top'] = this.clampLineY * 100 + "%", _b;
    };
    BuilderComponent.prototype.filterAnimationTriggerOptions = function (option, index) {
        if (index === 0 && option.value === AnimationTriggerType.CONCURRENT) {
            return false;
        }
        return true;
    };
    BuilderComponent.prototype.executeToolbarAction = function (func, parameters) {
        var _this = this;
        if (!func) {
            return null;
        }
        var context = {
            setElementProperty: function (element, property, value) { return _this.setElementProperty(element, property, value); },
            getElementProperty: function (element, property) { return _this.getElementProperty(element, property); },
            getPropertyType: function (elementType, propertyType) { return _this.getPropertyType(elementType, propertyType); },
            getActiveSlide: function () { return _this.currentSlide; },
            getActiveElement: function () { return _this.selectedElement; },
            setActiveElement: function (element) { return _this.setActiveElement(element); },
            removeElement: function (element) { return _this.removeElement(element); },
            getViewportConfiguration: function () { return _this.getViewport(); },
            getModalService: function () { return _this.modal; },
            getSessionToken: function () { return _this.sessionToken; },
            getResolution: function () { return _this.creation.output.resolution; },
            addAnimation: function (element) { return _this.addAnimation(element); },
            removeAnimation: function (element) { return _this.removeAnimation(element); },
            resetCreation: function () { return _this.resetCreation(); }
        };
        var result = func.apply(this, [context].concat(parameters));
        return result;
    };
    BuilderComponent.prototype.generateVideo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buildingModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                buildingModal = this.modal.open(new BuildingModal(this.creation));
                buildingModal.onApprove(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.modal.open(new BuilderModal(result, this.sessionToken));
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    BuilderComponent.prototype.addSlide = function () {
        var slide = JSON.parse(JSON.stringify(this.defaultSlide));
        this.creation.slides.push(slide);
        this.selectSlide(slide);
        this.validateSlideTransitions();
    };
    BuilderComponent.prototype.selectSlide = function (slide) {
        if (slide === this.currentSlide) {
            return;
        }
        this.currentSlide = slide;
        this.clearElement();
    };
    BuilderComponent.prototype.removeSlide = function (slide) {
        var _this = this;
        var slideIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === slide; });
        var currentIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === _this.currentSlide; });
        if (slideIndex === -1) {
            return;
        }
        this.creation.slides.splice(slideIndex, 1);
        var newIndex = Math.abs(currentIndex >= slideIndex ? currentIndex - 1 : currentIndex) % this.creation.slides.length;
        if (this.creation.slides[newIndex]) {
            this.selectSlide(this.creation.slides[newIndex]);
        }
        else {
            this.selectSlide(undefined);
        }
        this.validateSlideTransitions();
    };
    BuilderComponent.prototype.validateSlideTransitions = function () {
        for (var _i = 0, _a = this.creation.slides; _i < _a.length; _i++) {
            var slide = _a[_i];
            this.setSlideIntroTransition(slide, slide.transition.in);
            this.setSlideOutroTransition(slide, slide.transition.out);
        }
    };
    BuilderComponent.prototype.setSlideCrossfade = function (slide, crossfade) {
        var slideIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === slide; });
        slide.transition.crossfade = crossfade;
        // Ensure that the corresponding transitions between crossfaders works
        if (slideIndex !== 0) {
            var previousSlide = this.creation.slides[slideIndex - 1];
            if (previousSlide.transition.crossfade && previousSlide.transition.out) {
                slide.transition.in = previousSlide.transition.out;
            }
            else {
                previousSlide.transition.out = slide.transition.in;
            }
        }
        if (slideIndex !== this.creation.slides.length - 1) {
            var nextSlide = this.creation.slides[slideIndex + 1];
            if (nextSlide.transition.crossfade && nextSlide.transition.in) {
                slide.transition.out = nextSlide.transition.in;
            }
            else {
                nextSlide.transition.in = slide.transition.out;
            }
        }
    };
    BuilderComponent.prototype.setSlideIntroTransition = function (slide, transition) {
        // This ensures that Luma masks are not overlayed on each other
        var slideIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === slide; });
        slide.transition.in = transition;
        if (slideIndex === -1 || slideIndex === 0) {
            return;
        }
        var previousSlide = this.creation.slides[slideIndex - 1];
        if (slide.transition.crossfade || previousSlide.transition.crossfade) {
            previousSlide.transition.out = transition;
        }
    };
    BuilderComponent.prototype.setSlideOutroTransition = function (slide, transition) {
        var slideIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === slide; });
        slide.transition.out = transition;
        if (slideIndex === -1 || slideIndex === this.creation.slides.length - 1) {
            return;
        }
        var nextSlide = this.creation.slides[slideIndex + 1];
        if (slide.transition.crossfade || nextSlide.transition.crossfade) {
            nextSlide.transition.in = transition;
        }
    };
    BuilderComponent.prototype.isSlideIntroTransitionLinked = function (slide) {
        var slideIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === slide; });
        if (slideIndex === -1 || slideIndex === 0) {
            return false;
        }
        var previousSlide = this.creation.slides[slideIndex - 1];
        return slide.transition.crossfade || previousSlide.transition.crossfade;
    };
    BuilderComponent.prototype.isSlideOutroTransitionLinked = function (slide) {
        var slideIndex = this.creation.slides.findIndex(function (innerSlide) { return innerSlide === slide; });
        if (slideIndex === -1 || slideIndex === this.creation.slides.length - 1) {
            return;
        }
        var nextSlide = this.creation.slides[slideIndex + 1];
        return slide.transition.crossfade || nextSlide.transition.crossfade;
    };
    BuilderComponent.prototype.getElementEditProperties = function () {
        if (!this.selectedElement) {
            return [];
        }
        var relevantTypeNames = ['*', this.selectedElement.type];
        var relevantTypes = this.types.filter(function (type) { return relevantTypeNames.indexOf(type.type) !== -1; });
        var properties = [];
        for (var _i = 0, relevantTypes_1 = relevantTypes; _i < relevantTypes_1.length; _i++) {
            var type = relevantTypes_1[_i];
            properties.push.apply(properties, type.properties.filter(function (property) { return property.width !== ElementPropertyDisplayType.NONE; }));
        }
        return properties;
    };
    BuilderComponent.prototype.getElementProperty = function (element, property) {
        // tslint:disable-next-line:no-eval
        var scopedElement = element;
        return eval("scopedElement." + property.value);
    };
    BuilderComponent.prototype.setElementProperty = function (element, property, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var scopedElement, validation, evalString, _a, asset, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isEditorDirty = true;
                        scopedElement = element;
                        if (property.validate) {
                            validation = property.validate(this.selectedElement, this.resolutionOptions[this.creation.output.resolution.type][this.creation.output.orientation], value);
                            if (validation === undefined) {
                                this.ref.tick();
                                return [2 /*return*/, false];
                            }
                            else {
                                value = validation;
                            }
                        }
                        evalString = '';
                        _a = property.type;
                        switch (_a) {
                            case 'number': return [3 /*break*/, 1];
                            case 'string': return [3 /*break*/, 2];
                            case 'enum': return [3 /*break*/, 2];
                            case 'color': return [3 /*break*/, 2];
                            case 'file': return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 9];
                    case 1:
                        evalString = "scopedElement." + property.value + " = " + value + ";";
                        return [3 /*break*/, 9];
                    case 2:
                        evalString = "scopedElement." + property.value + " = \"" + value + "\";";
                        return [3 /*break*/, 9];
                    case 3:
                        asset = (value && value.cdnUrl) ? value : undefined;
                        if (!!asset) return [3 /*break*/, 8];
                        this.loader.setLoading(true, 'Uploading file...');
                        _b.label = 4;
                    case 4:
                        _b.trys.push([4, 6, 7, 8]);
                        return [4 /*yield*/, this.uploadAsset(value)];
                    case 5:
                        asset = _b.sent();
                        return [3 /*break*/, 8];
                    case 6:
                        e_1 = _b.sent();
                        this.notification.displayError(e_1);
                        return [2 /*return*/, false];
                    case 7:
                        this.loader.setLoading(false);
                        return [7 /*endfinally*/];
                    case 8:
                        evalString = "scopedElement." + property.value + " = \"" + asset.cdnUrl + "\";";
                        return [3 /*break*/, 9];
                    case 9:
                        // DANGER ZONE !!!!!!!!!!!!!
                        // tslint:disable-next-line:no-eval
                        eval(evalString);
                        // END DANGER ZONE !!!!!!!!!
                        if (element.onPropertyChange) {
                            element.onPropertyChange(property, value);
                        }
                        // this.ref.tick();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    BuilderComponent.prototype.uploadAsset = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var directory, parallelHasher, fileHash, fileName, directoryDetails, fileNameNoExt, existingFile;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.filesystem.getDirectoryByPath('Video Builder').toPromise()];
                    case 1:
                        directory = _a.sent();
                        if (!!directory) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.filesystem.makeDirectory('Video Builder', undefined, 'system').toPromise()];
                    case 2:
                        directory = _a.sent();
                        _a.label = 3;
                    case 3:
                        parallelHasher = new ParallelHasher('/assets/workers/md5.js');
                        return [4 /*yield*/, parallelHasher.hash(file)];
                    case 4:
                        fileHash = _a.sent();
                        fileName = fileHash + "_" + file.name;
                        return [4 /*yield*/, this.filesystem.getDirectoryDetails(directory).toPromise()];
                    case 5:
                        directoryDetails = _a.sent();
                        fileNameNoExt = file.name.substring(0, file.name.lastIndexOf('.'));
                        existingFile = directoryDetails.files.find(function (innerFile) { return fileNameNoExt === innerFile.name && file.size === innerFile.size; });
                        if (existingFile) {
                            return [2 /*return*/, existingFile];
                        }
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                _this.filesystem
                                    .upload(file, directory, _this.sessionToken)
                                    .pipe(catchError(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    var errorMessage;
                                    return tslib_1.__generator(this, function (_a) {
                                        errorMessage = event.error.error || event.error.data;
                                        reject(errorMessage);
                                        return [2 /*return*/];
                                    });
                                }); }))
                                    .subscribe(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    var upload, uploadingFile;
                                    return tslib_1.__generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!event) {
                                                    return [2 /*return*/];
                                                }
                                                if (!(event.type === HttpEventType.Response)) return [3 /*break*/, 6];
                                                upload = event.body;
                                                uploadingFile = void 0;
                                                _a.label = 1;
                                            case 1: return [4 /*yield*/, this.filesystem.getFileDetails(upload._id).toPromise()];
                                            case 2:
                                                uploadingFile = _a.sent();
                                                return [4 /*yield*/, sleep(5000)];
                                            case 3:
                                                _a.sent();
                                                _a.label = 4;
                                            case 4:
                                                if (!uploadingFile.cdnUrl) return [3 /*break*/, 1];
                                                _a.label = 5;
                                            case 5:
                                                resolve(uploadingFile);
                                                _a.label = 6;
                                            case 6: return [2 /*return*/];
                                        }
                                    });
                                }); });
                            })];
                }
            });
        });
    };
    BuilderComponent.prototype.getDuration = function (asset) {
        var metadata = asset.meta;
        if (metadata && metadata.length) {
            var duration = metadata.find(function (item) { return item.key === 'duration'; });
            if (duration && duration.value) {
                return Math.round(duration.value * 10) / 10;
            }
        }
        return 0;
    };
    BuilderComponent.prototype.getMusicCSS = function () {
        var _a;
        var duration = this.creation.music.duration || 0;
        var adjustedViewportWidth = this.getViewport().track.width * this.timelineZoomFactor;
        var style = (_a = {},
            _a['width'] = adjustedViewportWidth * (duration / this.DEFAULT_SLIDE_LENGTH) + "px",
            _a);
        return style;
    };
    BuilderComponent.prototype.getMusicPxPerSecond = function () {
        return (this.getViewport().track.width / this.DEFAULT_SLIDE_LENGTH) * this.timelineZoomFactor;
    };
    BuilderComponent.prototype.onSliderHandlePull = function (e, slide, direction) {
        e.stopPropagation();
        if (direction === 'right') {
            this.isExpandingSlideDurationRight = true;
        }
        else {
            this.isExpandingSlideDurationLeft = true;
        }
        this.selectSlide(slide);
    };
    BuilderComponent.prototype.onCroppingMusic = function (e) {
        if (!this.croppingMusic)
            return;
        var snapValue = 4;
        var mouseX = roundToNearest(e.clientX, snapValue);
        if (this.lastMouseXonMusicBar) {
            var deltaX = (mouseX - this.lastMouseXonMusicBar) / this.timelineZoomFactor;
            var musicDuration = this.creation.music.duration || 0;
            var newDuration = Math.round((musicDuration + (deltaX * this.DEFAULT_SLIDE_LENGTH) / this.getViewport().track.width) * 10) / 10;
            if (newDuration > 0) {
                this.creation.music.duration = newDuration;
            }
        }
        this.lastMouseXonMusicBar = mouseX;
    };
    BuilderComponent.prototype.onDraggingSlideDuration = function (e, direction) {
        if (!this.currentSlide) {
            return;
        }
        var mouseDeltaX = e.clientX - this.lastRawMouseX;
        var slidePreviewWidth = this.getViewport().track.width;
        // 10 is the default slide duration (192px, or whatver is in viewport.track.width)
        var changeInDuration = (mouseDeltaX / slidePreviewWidth) * this.DEFAULT_SLIDE_LENGTH / this.timelineZoomFactor;
        var newDuration;
        if (direction === 'right') {
            newDuration = this.currentSlide.duration + changeInDuration;
        }
        else {
            newDuration = this.currentSlide.duration - changeInDuration;
        }
        this.currentSlide.duration = clamp(newDuration, 3, Number.POSITIVE_INFINITY);
    };
    BuilderComponent.prototype.setActiveElement = function (element) {
        this.selectedElement = element;
    };
    BuilderComponent.prototype.clearElement = function () {
        this.selectedElement = null;
    };
    BuilderComponent.prototype.removeElement = function (element) {
        if (!this.currentSlide) {
            return;
        }
        var elementIndex = this.currentSlide.elements.findIndex(function (innerElement) { return innerElement.id === element.id; });
        if (elementIndex === -1) {
            return;
        }
        // In case there was an associated animation
        this.removeAnimation(element);
        this.currentSlide.elements.splice(elementIndex, 1);
        return element;
    };
    BuilderComponent.prototype.onStartEditingText = function (element, htmlElement) {
        this.editingText = true;
    };
    BuilderComponent.prototype.onEndEditingText = function (element, htmlElement) {
        var innerText = htmlElement.innerText;
        element.value = innerText;
        this.editingText = false;
    };
    BuilderComponent.prototype.getEditorCSS = function () {
        var _a;
        return _a = {},
            _a['width'] = this.getViewport().editor.width + "px",
            _a['min-width'] = this.getViewport().editor.width + "px",
            _a['max-width'] = this.getViewport().editor.width + "px",
            _a['height'] = this.getViewport().editor.height + "px",
            _a['min-height'] = this.getViewport().editor.height + "px",
            _a['max-height'] = this.getViewport().editor.height + "px",
            _a;
    };
    BuilderComponent.prototype.getTrackSlideCSS = function () {
        var _a;
        return _a = {},
            _a['max-width'] = this.getViewport().track.width + "px",
            _a['height'] = this.getViewport().track.height + "px",
            _a['min-height'] = this.getViewport().track.height + "px",
            _a['max-height'] = this.getViewport().track.height + "px",
            _a;
    };
    BuilderComponent.prototype.getTemplateCSS = function (template) {
        var _a;
        return _a = {},
            _a['width'] = this.getViewport(template.body.output.orientation).template.width + "px",
            _a['min-width'] = this.getViewport(template.body.output.orientation).template.width + "px",
            _a['max-width'] = this.getViewport(template.body.output.orientation).template.width + "px",
            _a['height'] = this.getViewport(template.body.output.orientation).template.height + "px",
            _a['min-height'] = this.getViewport(template.body.output.orientation).template.height + "px",
            _a['max-height'] = this.getViewport(template.body.output.orientation).template.height + "px",
            _a;
    };
    BuilderComponent.prototype.getSlideCSS = function (slide, slideViewport) {
        var _a;
        var duration = slide.duration;
        var adjustedViewportWidth = slideViewport.width * this.timelineZoomFactor;
        var slideWidthStyle = adjustedViewportWidth * (duration / this.DEFAULT_SLIDE_LENGTH) + "px";
        var style = (_a = {},
            _a['width'] = slideWidthStyle,
            _a['max-width'] = slideWidthStyle,
            _a['background'] = slide.background,
            _a);
        return style;
    };
    BuilderComponent.prototype.getMultiselectCSS = function () {
        if (!this.isDraggingSelectionBox) {
            return { 'display': 'none' };
        }
        ;
        var editorBoundingBox = this.videoEditorElement.nativeElement.getBoundingClientRect();
        var editorBoundingBoxMinX = editorBoundingBox.x;
        var editorBoundingBoxMaxX = editorBoundingBox.x + editorBoundingBox.width;
        var editorBoundingBoxMinY = editorBoundingBox.y;
        var editorBoundingBoxMaxY = editorBoundingBox.y + editorBoundingBox.height;
        var pointAX = this.mouseDownX || 0;
        var pointAY = this.mouseDownY || 0;
        var pointBX = this.lastRawMouseX || 0;
        var pointBY = this.lastRawMouseY || 0;
        var lowerX = clamp(editorBoundingBoxMinX, Math.min(pointAX, pointBX), editorBoundingBoxMaxX);
        var lowerY = clamp(editorBoundingBoxMinY, Math.min(pointAY, pointBY), editorBoundingBoxMaxY);
        var upperX = clamp(editorBoundingBoxMinX, Math.max(pointAX, pointBX), editorBoundingBoxMaxX);
        var upperY = clamp(editorBoundingBoxMinY, Math.max(pointAY, pointBY), editorBoundingBoxMaxY);
        var height = upperY - lowerY;
        var width = upperX - lowerX;
        if (height < this.MINIMUM_SELECTION_THRESHOLD || width < this.MINIMUM_SELECTION_THRESHOLD) {
            return { 'display': 'none' };
        }
        ;
        return {
            'top': lowerY + "px",
            'height': upperY - lowerY + "px",
            'left': lowerX + "px",
            'width': upperX - lowerX + "px",
        };
    };
    BuilderComponent.prototype.getMultiselectTransformerCSS = function () {
        if (this.multiSelectedElements.length < 2) {
            return { 'display': 'none' };
        }
        ;
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var contentElementBoundingBoxMinX = contentElementBoundingBox.x;
        var contentElementBoundingBoxMaxX = contentElementBoundingBox.x + contentElementBoundingBox.width;
        var contentElementBoundingBoxMinY = contentElementBoundingBox.y;
        var contentElementBoundingBoxMaxY = contentElementBoundingBox.y + contentElementBoundingBox.height;
        var elementPointsAX = [];
        var elementPointsAY = [];
        var elementPointsBX = [];
        var elementPointsBY = [];
        for (var _i = 0, _a = this.multiSelectedElements; _i < _a.length; _i++) {
            var entry = _a[_i];
            var element = entry.element;
            var clientPointAX = contentElementBoundingBoxMinX + (element.position.x * contentRatioX);
            var clientPointAY = contentElementBoundingBoxMinY + (element.position.y * contentRatioY);
            var clientPointBX = contentElementBoundingBoxMinX + ((element.position.x + element.size.width) * contentRatioX);
            var clientPointBY = contentElementBoundingBoxMinY + ((element.position.y + element.size.height) * contentRatioY);
            elementPointsAX.push(clientPointAX);
            elementPointsAY.push(clientPointAY);
            elementPointsBX.push(clientPointBX);
            elementPointsBY.push(clientPointBY);
        }
        var pointAX = Math.min.apply(Math, elementPointsAX);
        var pointAY = Math.min.apply(Math, elementPointsAY);
        var pointBX = Math.max.apply(Math, elementPointsBX);
        var pointBY = Math.max.apply(Math, elementPointsBY);
        var lowerX = clamp(contentElementBoundingBoxMinX, pointAX, contentElementBoundingBoxMaxX);
        var lowerY = clamp(contentElementBoundingBoxMinY, pointAY, contentElementBoundingBoxMaxY);
        var upperX = clamp(contentElementBoundingBoxMinX, pointBX, contentElementBoundingBoxMaxX);
        var upperY = clamp(contentElementBoundingBoxMinY, pointBY, contentElementBoundingBoxMaxY);
        return {
            'top': lowerY + "px",
            'height': upperY - lowerY + "px",
            'left': lowerX + "px",
            'width': upperX - lowerX + "px",
        };
    };
    BuilderComponent.prototype.getShapePointTransformerCSS = function (element, point) {
        var transformerSize = 8;
        var contentElementBoundingBox = this.videoContentElement.nativeElement.getBoundingClientRect();
        var contentRatioX = this.getViewport().editor.width / this.creation.output.resolution.width;
        var contentRatioY = this.getViewport().editor.height / this.creation.output.resolution.height;
        var contentAdjustedX = contentElementBoundingBox.x + (point.x * contentRatioX);
        var contentAdjustedY = contentElementBoundingBox.y + (point.y * contentRatioY);
        return {
            'top': contentAdjustedY - (transformerSize) + "px",
            'left': contentAdjustedX - (transformerSize) + "px"
        };
    };
    BuilderComponent.prototype.getRenderedSVGCSS = function (viewport) {
        var _a;
        var contentRatioX = viewport.width / this.creation.output.resolution.width;
        var contentRatioY = viewport.height / this.creation.output.resolution.height;
        return _a = {},
            _a['transform'] = "scale(" + contentRatioX + ", " + contentRatioY + ")",
            _a;
    };
    BuilderComponent.prototype.canGenerateVideo = function () {
        if (this.creation.slides.length === 0) {
            return false;
        }
        for (var _i = 0, _a = this.creation.slides; _i < _a.length; _i++) {
            var slide = _a[_i];
            for (var _b = 0, _c = slide.elements; _b < _c.length; _b++) {
                var element = _c[_b];
                if (element.type !== 'shape' && !element.value) {
                    return false;
                }
            }
        }
        var minimumElementCount = Math.min.apply(Math, this.creation.slides.map(function (slide) { return slide.elements.length; }));
        return minimumElementCount > 0;
    };
    BuilderComponent.prototype.dropSlide = function (event) {
        moveItemInArray(this.creation.slides, event.previousIndex, event.currentIndex);
    };
    BuilderComponent.prototype.dropAnimation = function (event) {
        if (!this.currentSlide) {
            return;
        }
        moveItemInArray(this.currentSlide.animations, event.previousIndex, event.currentIndex);
    };
    BuilderComponent.prototype.droppedFile = function (event) {
        var _this = this;
        var droppedFile = event.files[0];
        if (droppedFile.fileEntry.isFile) {
            var fileEntry = droppedFile.fileEntry;
            fileEntry.file(function (file) {
                var result;
                if (file.type.includes('image')) {
                    var action = new AddImageToolbarAction();
                    result = _this.executeToolbarAction(action.execute);
                }
                else if (file.type.includes('video')) {
                    var action = new AddVideoToolbarAction();
                    result = _this.executeToolbarAction(action.execute);
                }
                if (result) {
                    var reader_1 = new FileReader();
                    reader_1.onload = function () {
                        _this.selectedElement.value = undefined;
                        _this.selectedElement.previewUrl = reader_1.result;
                    };
                    reader_1.readAsDataURL(file);
                    _this.uploadAsset(file).then(function (asset) {
                        _this.selectedElement.value = asset.cdnUrl;
                    });
                }
            });
        }
    };
    BuilderComponent.prototype.droppedFileOnElement = function (event) {
        var _this = this;
        var droppedFile = event.files[0];
        if (droppedFile.fileEntry.isFile) {
            var fileEntry = droppedFile.fileEntry;
            fileEntry.file(function (file) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var asset, e_2;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!file.type.includes(this.selectedElement.type)) return [3 /*break*/, 5];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            this.loader.setLoading(true, 'Uploading file...');
                            return [4 /*yield*/, this.uploadAsset(file)];
                        case 2:
                            asset = _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_2 = _a.sent();
                            this.notification.displayError(e_2);
                            this.loader.setLoading(false);
                            return [2 /*return*/];
                        case 4:
                            this.loader.setLoading(false);
                            this.selectedElement.value = asset.cdnUrl;
                            _a.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    BuilderComponent.prototype.hydrateElementFromSchema = function (element) {
        var hydratedElement;
        switch (element.type) {
            case 'image':
                hydratedElement = new ImageElement(element.value);
                break;
            case 'video':
                hydratedElement = new VideoElement(element.value);
                break;
            case 'text':
                hydratedElement = new TextElement(element.value);
                break;
            case 'shape':
                var castShapeElement_1 = element;
                var elementSvg = this.shapes.find(function (shape) { return shape.type === castShapeElement_1.svg.type; });
                var generatedSvg = elementSvg.generator(0, 0);
                generatedSvg.points = castShapeElement_1.svg.points;
                generatedSvg.properties = castShapeElement_1.svg.properties;
                hydratedElement = new ShapeElement(generatedSvg);
                break;
            default:
                throw new Error("Cannot hydrate element of type " + element.type + "!");
        }
        // TODO: double check and ensure the ID gets set here
        for (var _i = 0, _a = Object.entries(element); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (key === 'svg') {
                continue;
            }
            hydratedElement[key] = value;
        }
        return hydratedElement;
    };
    BuilderComponent.prototype.loadTemplates = function () {
        var _this = this;
        this.builder.getTemplates().subscribe(function (templates) {
            _this.templates = [];
            for (var _i = 0, templates_1 = templates; _i < templates_1.length; _i++) {
                var content = templates_1[_i];
                var item = tslib_1.__assign({}, content, { body: JSON.parse(content.body) });
                for (var _a = 0, _b = item.body.slides; _a < _b.length; _a++) {
                    var slide = _b[_a];
                    slide.elements = slide.elements.map(function (element) { return _this.hydrateElementFromSchema(element); });
                }
                _this.templates.push(item);
            }
        });
    };
    BuilderComponent.prototype.loadProjects = function () {
        var _this = this;
        this.builder.getProjects().subscribe(function (projects) {
            _this.projects = [];
            for (var _i = 0, projects_1 = projects; _i < projects_1.length; _i++) {
                var content = projects_1[_i];
                var item = tslib_1.__assign({}, content, { body: JSON.parse(content.body) });
                for (var _a = 0, _b = item.body.slides; _a < _b.length; _a++) {
                    var slide = _b[_a];
                    slide.elements = slide.elements.map(function (element) { return _this.hydrateElementFromSchema(element); });
                }
                _this.projects.push(item);
            }
        });
    };
    BuilderComponent.prototype.saveProject = function (saveAs) {
        var _this = this;
        if (saveAs === void 0) { saveAs = false; }
        if (this.currentProject && this.currentProject._id && !saveAs) {
            this.builder.editProject(this.currentProject._id, this.currentProject.name, this.creation).subscribe(function (project) {
                _this.currentProject = project;
                _this.loadProjects();
                _this.isEditorDirty = false;
                _this.notification.displaySuccess('Project saved!');
            });
        }
        else {
            var projectNameModal = this.modal.open(new InputModal('Save project', { header: 'Info', text: 'After saving, you can access your projects from any computer!' }, { label: 'Project name' }));
            projectNameModal.onApprove(function (result) {
                _this.builder.createProject(result, _this.creation).subscribe(function (project) {
                    _this.currentProject = tslib_1.__assign({}, project);
                    _this.loadProjects();
                });
                _this.isEditorDirty = false;
                _this.notification.displaySuccess('Project saved!');
            });
        }
    };
    BuilderComponent.prototype.saveTemplate = function (saveAs) {
        var _this = this;
        if (saveAs === void 0) { saveAs = false; }
        if (this.currentProject && this.currentProject._id && !saveAs) {
            this.builder.editTemplate(this.currentProject._id, this.currentProject.name, this.creation).subscribe(function (template) {
                _this.currentProject = template;
                _this.loadTemplates();
                _this.isEditorDirty = false;
                _this.notification.displaySuccess('Template saved!');
            });
        }
        else {
            var projectNameModal = this.modal.open(new InputModal('Save template', { header: 'Info', text: 'You are saving this project as a template. It will be visible to all users on Citadel.' }, { label: 'Template name' }));
            projectNameModal.onApprove(function (result) {
                _this.builder.createTemplate(result, _this.creation).subscribe(function (template) {
                    _this.currentProject = tslib_1.__assign({}, template);
                    _this.loadTemplates();
                });
                _this.isEditorDirty = false;
                _this.notification.displaySuccess('Template saved!');
            });
        }
    };
    BuilderComponent.prototype.hasUnsavedChanges = function () {
        if (!this.currentProject) {
            return false;
        }
        if (this.getAllElements().length === 0) {
            return false;
        }
        return this.isEditorDirty;
    };
    BuilderComponent.prototype.deleteProject = function (project) {
        var _this = this;
        if (!project._id) {
            this.resetCreation();
            this.notification.displaySuccess('Project deleted!');
            return;
        }
        var confirmModal = this.modal.open(new ConfirmModal("Deleting " + project.name, "Are you sure you would like to delete this project?"));
        confirmModal.onApprove(function () {
            _this.builder.deleteProject(project._id).subscribe(function () {
                if (_this.currentProject && _this.currentProject._id === project._id) {
                    _this.resetCreation();
                }
                _this.loadProjects();
                _this.notification.displaySuccess('Project deleted!');
            });
        });
    };
    BuilderComponent.prototype.deleteTemplate = function (template) {
        var _this = this;
        if (!template._id) {
            this.resetCreation();
            this.notification.displaySuccess('Template deleted!');
            return;
        }
        var confirmModal = this.modal.open(new ConfirmModal("Deleting " + template.name, "Are you sure you would like to delete this template?"));
        confirmModal.onApprove(function () {
            _this.builder.deleteTemplate(template._id).subscribe(function () {
                if (_this.currentProject && _this.currentProject._id === template._id) {
                    _this.resetCreation();
                }
                _this.loadTemplates();
                _this.notification.displaySuccess('Template deleted!');
            });
        });
    };
    BuilderComponent.prototype.isContentOwner = function (content) {
        return true;
        // TODO: REFACTOR
        // return content && this.userService.getUser()._id === content.user;
    };
    BuilderComponent.prototype.canDelete = function (content) {
        return false;
        // TODO: REFACTOR
        // return this.isContentOwner(content) || this.userService.getUser().type === 'admin' || this.userService.getUser().type === 'internal';
    };
    BuilderComponent.prototype.canUpdateContent = function () {
        return this.canGenerateVideo() && this.isContentOwner(this.currentProject);
    };
    BuilderComponent.prototype.onSelectProject = function (content) {
        var _this = this;
        content = tslib_1.__assign({}, content);
        var confirmModal = this.modal.open(new ConfirmModal('Confirmation', "Are you sure you want to open this project? Your unsaved changes will be lost."));
        confirmModal.onApprove(function () {
            _this.currentProject = content;
            // Get a clone of the body so we don't modify it further
            _this.loadCreation(content.body);
            _this.currentSlide = _this.creation.slides[0];
        });
    };
    BuilderComponent.prototype.onSelectTemplate = function (content) {
        var _this = this;
        content.body = tslib_1.__assign({}, content.body);
        var confirmModal = this.modal.open(new ConfirmModal('Confirmation', "Are you sure you want to import this template? Your unsaved changes will be lost."));
        confirmModal.onApprove(function () {
            _this.loadCreation(content.body);
            _this.currentSlide = _this.creation.slides[0];
        });
    };
    BuilderComponent.prototype.onCreateNewProject = function () {
        var _this = this;
        var confirmModal = this.modal.open(new ConfirmModal('Are you sure you would like to create a new project?', "You will lose your unsaved changes on your current project."));
        confirmModal.onApprove(function () {
            _this.resetCreation();
        });
    };
    BuilderComponent.prototype.loadCreation = function (scaffold) {
        // These changes ensure backwards compatability with old projects without animations or orientation data
        if (!scaffold.output) {
            var output = {
                orientation: ProjectOrientation.HORIZONTAL,
                resolution: {
                    type: ProjectResolution.HD,
                    width: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].width,
                    height: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].height
                }
            };
            scaffold.output = output;
        }
        for (var _i = 0, _a = scaffold.slides; _i < _a.length; _i++) {
            var slide = _a[_i];
            if (!slide.animations) {
                slide.animations = [];
            }
        }
        this.creation = scaffold;
    };
    BuilderComponent.prototype.resetCreation = function () {
        this.creation = {
            music: {
                name: '',
                value: undefined,
                duration: 0,
            },
            resources: {},
            slides: [],
            output: {
                orientation: ProjectOrientation.HORIZONTAL,
                resolution: {
                    type: ProjectResolution.HD,
                    width: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].width,
                    height: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].height
                }
            }
        };
        this.addSlide();
        var creationCopy = {};
        naiveDeepCopy(creationCopy, this.creation);
        this.currentProject = {
            name: 'Untitled project',
            type: SavedProjectType.PROJECT,
            body: creationCopy
        };
    };
    BuilderComponent.prototype.openContentLibrary = function () {
        var _this = this;
        var filter = '';
        switch (this.sidebarActivity) {
            case 'elements':
                filter = 'image/*,video/*';
                break;
            case 'music':
                filter = 'audio/*';
                break;
        }
        this.modal.open(new FilePickerModal(filter, false, true, this.sessionToken)).onApprove(function (files) {
            var file = files[0];
            if (!file.mimeType) {
                return;
            }
            if (file.mimeType.startsWith('video')) {
                var action = new AddVideoToolbarAction();
                _this.executeToolbarAction(action.execute, [file.cdnUrl]);
            }
            else if (file.mimeType.startsWith('image')) {
                var action = new AddImageToolbarAction();
                _this.executeToolbarAction(action.execute, [file.cdnUrl]);
            }
            else if (file.mimeType.startsWith('audio')) {
                _this.setMusicFromFile(file);
            }
        });
    };
    BuilderComponent.prototype.clearMusic = function () {
        this.creation.music = {
            name: '',
            value: undefined,
            duration: 0,
        };
    };
    BuilderComponent.prototype.setMusicFromURL = function (name, url, duration) {
        if (duration === void 0) { duration = 0; }
        var newAsset = {
            name: name,
            value: url,
            duration: duration
        };
        this.creation.music = tslib_1.__assign({}, newAsset);
    };
    BuilderComponent.prototype.setMusicFromFile = function (file) {
        var newAsset = {
            name: file.name,
            value: file.cdnUrl,
            duration: this.getDuration(file),
        };
        this.creation.music = tslib_1.__assign({}, newAsset);
    };
    BuilderComponent.prototype.zoomTimelineInBy = function (amount) {
        this.timelineZoomFactor = clamp(this.timelineZoomFactor + amount, 0.25, 4);
    };
    BuilderComponent.prototype.zoomTimelineOutBy = function (amount) {
        this.timelineZoomFactor = clamp(this.timelineZoomFactor - amount, 0.25, 4);
    };
    BuilderComponent.prototype.onTimelineScroll = function (event) {
        event.stopImmediatePropagation();
        var deltaY = event.deltaY;
        if (deltaY < 0) {
            this.zoomTimelineInBy(0.0625);
        }
        else if (deltaY > 0) {
            this.zoomTimelineOutBy(0.0625);
        }
    };
    return BuilderComponent;
}());
export { BuilderComponent };

import { SVGElementType, ISVGPoint, ISVG } from "../svg.definition";
import SVGElementGeneric from "./generic.definition";

export class SVGCircle extends SVGElementGeneric {
    type: SVGElementType = SVGElementType.CIRCLE;
    points: ISVGPoint[] = [];

    constructor(point: ISVGPoint, radialPoint?: ISVGPoint) {
        super();

        this.points = [point];

        if (radialPoint) {
            this.points.push(radialPoint);
        }
    }

    private calculateRadius(): number {
        return Math.sqrt(
            Math.pow(this.points[0].x - this.points[1].x, 2) + 
            Math.pow(this.points[0].y - this.points[1].y, 2)
        );
    }

    generate(builder: any, parent: ISVG) {
        return builder.circle({
            ['cx']: this.points[0].x - parent.getBounds().ax,
            ['cy']: this.points[0].y - parent.getBounds().ay,
            ['r']: this.points[1] ? this.calculateRadius() : parent.getProperty('radius') || (parent.getProperty('width') / 2),
            ['fill']: parent.getProperty('fill'),
        });
    }
}
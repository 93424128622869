import * as tslib_1 from "tslib";
import { SuiModal } from 'ng2-semantic-ui';
import { UserService } from 'src/_services/user.service';
import { ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
var ResourceModalComponent = /** @class */ (function () {
    function ResourceModalComponent(modal, notificationService, filesystemService, loaderService, fb, userService) {
        this.modal = modal;
        this.notificationService = notificationService;
        this.filesystemService = filesystemService;
        this.loaderService = loaderService;
        this.fb = fb;
        this.userService = userService;
        this.availableTags = [];
        this.resource = this.modal.context.resource;
        this.justUploaded = this.modal.context.justUploaded;
        this.availableTags = this.modal.context.availableTags;
        if (this.resource['path']) {
            this.type = 'directory';
        }
        else {
            this.type = 'file';
        }
        this.currentProgress = 0;
        this.initializeForm();
    }
    Object.defineProperty(ResourceModalComponent.prototype, "uploadedPDF", {
        get: function () {
            return this.resourceFormGroup['controls'].meta['controls'].find(function (item) { return item.value.key === 'pdfId' && item.value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResourceModalComponent.prototype, "metadataFormControls", {
        get: function () {
            return this.resourceFormGroup['controls'].meta['controls'].filter(function (item) { return item.value.key !== 'pdfId'; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResourceModalComponent.prototype, "canUploadPDF", {
        get: function () {
            return this.resource.type !== 'system';
        },
        enumerable: true,
        configurable: true
    });
    ResourceModalComponent.prototype.initializeForm = function () {
        this.resourceFormGroup = this.fb.group({
            name: [this.resource.name, Validators.compose([Validators.required])],
            tags: [this.mapAvailableTags(), Validators.compose([])],
            meta: this.fb.array([]),
        });
        if (this.resource.meta) {
            for (var _i = 0, _a = this.resource.meta; _i < _a.length; _i++) {
                var metadata = _a[_i];
                this.initializeMetadata(metadata);
            }
        }
    };
    ResourceModalComponent.prototype.mapAvailableTags = function () {
        var _this = this;
        if (!this.resource.tags || !this.availableTags || this.availableTags.length === 0) {
            return [];
        }
        return this.resource.tags.map(function (tagId) { return _this.availableTags.find(function (innerTag) { return innerTag._id === tagId; }); }).filter(function (tag) { return tag; });
    };
    ResourceModalComponent.prototype.deleteResource = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loaderService.setLoading(true, 'Updating resource');
                        if (!(this.type === 'file')) return [3 /*break*/, 2];
                        file = this.modal.context.resource;
                        return [4 /*yield*/, this.filesystemService.delete(this.type, file).toPromise()];
                    case 1:
                        _a.sent();
                        this.loaderService.setLoading(false);
                        this.notificationService.displaySuccess('Successfully deleted video: ' + file.name);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ResourceModalComponent.prototype.getSessionToken = function () {
        return this.userService.getSession().token;
    };
    ResourceModalComponent.prototype.uploadFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uploadedFile, resourceFormGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.filesystemService.upload(file, this.resource, this.getSessionToken()).toPromise()];
                    case 1:
                        uploadedFile = (_a.sent()).body;
                        this.loaderService.setLoading(true, 'Uploading PDF');
                        return [4 /*yield*/, this.initializeMetadata({
                                key: 'pdfId',
                                value: uploadedFile._id,
                                name: uploadedFile.name + "." + uploadedFile.extension,
                                required: false,
                                readOnly: true,
                            })];
                    case 2:
                        _a.sent();
                        resourceFormGroup = this.resourceFormGroup.value;
                        return [4 /*yield*/, this.filesystemService.edit(this.type, this.resource, resourceFormGroup).toPromise()];
                    case 3:
                        _a.sent();
                        this.loaderService.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    ResourceModalComponent.prototype.upload = function () {
        if (this.uploadInput) {
            this.uploadInput.nativeElement.click();
        }
    };
    ResourceModalComponent.prototype.onFileChange = function (e) {
        if (!e.target.files[0]) {
            return;
        }
        var file = e.target.files[0];
        var blob = file.slice(0, file.size, file.type);
        var newFile = new File([blob], Date.now() + '-' + file.name, { type: file.type });
        this.uploadFile(newFile);
        e.target.value = '';
    };
    ResourceModalComponent.prototype.downloadAttachedPdf = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var item, fileDetails, link;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        item = this.resourceFormGroup.controls['meta'].value.find(function (innerItem) { return innerItem.key === 'pdfId' && innerItem.value; });
                        if (!item.value) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.filesystemService.getFileDetails(item.value).toPromise()];
                    case 1:
                        fileDetails = _a.sent();
                        if (fileDetails.cdnUrl) {
                            link = document.createElement('a');
                            link.href = fileDetails.cdnUrl;
                            link.download = fileDetails.name;
                            link.target = '_blank';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ResourceModalComponent.prototype.deleteAttachedPdf = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resourceFormGroup, formArray;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loaderService.setLoading(true, 'Deleting PDF');
                        resourceFormGroup = this.resourceFormGroup.value;
                        return [4 /*yield*/, this.filesystemService
                                .edit(this.type, this.resource, {
                                name: resourceFormGroup.name,
                                meta: resourceFormGroup.meta.filter(function (item) { return item.key !== 'pdfId'; }),
                            })
                                .toPromise()];
                    case 1:
                        _a.sent();
                        this.loaderService.setLoading(false);
                        formArray = this.resourceFormGroup.controls['meta'];
                        formArray.removeAt(formArray.controls.findIndex(function (item) { return item.value.key === 'pdfId'; }));
                        return [2 /*return*/];
                }
            });
        });
    };
    ResourceModalComponent.prototype.initializeMetadata = function (metadata) {
        var formArray = this.resourceFormGroup.controls['meta'];
        var fbGroup = this.fb.group({
            key: [metadata.key, Validators.required],
            value: [metadata.value, []],
            name: [metadata.name, []],
            required: [metadata.required, []],
            readOnly: [metadata.readOnly, []],
        });
        formArray.push(fbGroup);
        return fbGroup;
    };
    ResourceModalComponent.prototype.saveChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loaderService.setLoading(true, 'Updating resource');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.filesystemService.edit(this.type, this.resource, this.resourceFormGroup.value).toPromise()];
                    case 2:
                        _a.sent();
                        this.modal.approve(undefined);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.notificationService.displayError(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.loaderService.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ResourceModalComponent;
}());
export { ResourceModalComponent };

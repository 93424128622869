import { ElementType, ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class SlideColorToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'slide_color';
  label: string = 'Slide Background';
	icon: string = 'paint brush';
	tooltip: string = 'Assign a color to the slide background';
  actionType: ToolbarActionType = ToolbarActionType.COLOR;

	execute(context: IVideoBuilderContext, color: string): IElement | void {
		context.getActiveSlide().background = color;
	}

  getCurrentValue(context: IVideoBuilderContext): any {
		return context.getActiveSlide().background;
  }
}

import { AfterViewInit, ElementRef } from '@angular/core';
// Import as ESM to placate typescript transpilation
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm';
import { WaveformService } from '../../_services/waveform.service';
var WaveformComponent = /** @class */ (function () {
    function WaveformComponent(waveformService) {
        this.waveformService = waveformService;
        this.controls = false;
        this.interactable = false;
        this.heightScalingFactor = 1;
        this.isLoaded = false;
        this.isDrawing = true;
    }
    Object.defineProperty(WaveformComponent.prototype, "pxPerSecond", {
        get: function () {
            return this._pxPerSecond;
        },
        set: function (value) {
            this._pxPerSecond = value;
            if (this.waveSurferInstance && !this.isDrawing) {
                this.isDrawing = true;
                this.waveSurferInstance.zoom(this._pxPerSecond);
            }
        },
        enumerable: true,
        configurable: true
    });
    WaveformComponent.prototype.initializeWavesurfer = function () {
        var _this = this;
        this.waveSurferInstance = WaveSurfer.create({
            container: this.musicContainerElement.nativeElement,
            waveColor: '#145070',
            progressColor: '#042e41',
            cursorWidth: 0,
            minPxPerSec: this.pxPerSecond,
            barHeight: this.heightScalingFactor,
            interact: this.interactable,
            url: this.url,
        });
        this.waveformService.registerWaveformInstance(this.waveSurferInstance);
        this.waveSurferInstance.on('redrawcomplete', function () {
            _this.isDrawing = false;
        });
        this.waveSurferInstance.on('ready', function () {
            _this.isLoaded = true;
        });
    };
    WaveformComponent.prototype.isPlaying = function () {
        if (!this.waveSurferInstance) {
            return false;
        }
        return this.waveSurferInstance.isPlaying();
    };
    WaveformComponent.prototype.play = function () {
        this.waveSurferInstance.play();
    };
    WaveformComponent.prototype.stop = function () {
        this.waveSurferInstance.stop();
    };
    WaveformComponent.prototype.onPlayClick = function (event) {
        event.stopPropagation();
        this.play();
    };
    WaveformComponent.prototype.onStopClick = function (event) {
        event.stopPropagation();
        this.stop();
    };
    WaveformComponent.prototype.ngAfterViewInit = function () {
        this.initializeWavesurfer();
    };
    return WaveformComponent;
}());
export { WaveformComponent };

import * as tslib_1 from "tslib";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import { environment } from '../environments/environment';
var UserService = /** @class */ (function () {
    function UserService(router, socketService, cookies) {
        this.router = router;
        this.socketService = socketService;
        this.cookies = cookies;
    }
    UserService.prototype.init = function (authenticated) {
        var _this = this;
        if (authenticated) {
            var cookie = this.getSessionCookie();
            if (!cookie) {
                throw new Error('invalid session');
            }
            this.session = JSON.parse(atob(cookie));
            this.socketService.getSocketConnection().on('reconnect', function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.sendToken().toPromise()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
            return this.sendToken();
        }
        return new Observable(function (observer) { return observer.complete(); });
    };
    UserService.prototype.sendToken = function () {
        var _this = this;
        if (!this.session) {
            throw new Error('session is not defined');
        }
        return this.socketService.call(Events.AUTHENTICATE_TOKEN_EVENT, function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = user;
                return [2 /*return*/];
            });
        }); }, this.session.token);
    };
    UserService.prototype.login = function (config) {
        var _this = this;
        return this.socketService.call(Events.LOGIN, function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var session, redirection;
            return tslib_1.__generator(this, function (_a) {
                if (response.type === 'session') {
                    session = response.data;
                    this.user = session.user;
                    this.setSession(session);
                    this.router.navigate(['/dashboard/account']);
                }
                else {
                    redirection = response.data;
                    window.location.href = redirection.targetUrl;
                }
                return [2 /*return*/];
            });
        }); }, config);
    };
    UserService.prototype.register = function (config, promoCode) {
        var _this = this;
        config.promoCode = promoCode;
        console.log('cc-trace-afee-cddfe38d1502', { config: config, promoCode: promoCode });
        return this.socketService.call(Events.REGISTER, function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var session;
            return tslib_1.__generator(this, function (_a) {
                session = response.data;
                this.user = session.user;
                this.setSession(session);
                return [2 /*return*/];
            });
        }); }, config, promoCode);
    };
    UserService.prototype.listLoginOptions = function (email) {
        return this.socketService.call(Events.LIST_LOGIN_OPTIONS, undefined, { email: email });
    };
    UserService.prototype.completeRegistration = function (config, promoCode) {
        config.promoCode = promoCode;
        console.log('cc-trace-ae88-de3f562495fc', { config: config, promoCode: promoCode });
        return this.socketService.call(Events.COMPLETE_REGISTRATION, undefined, config, promoCode);
    };
    UserService.prototype.resetPassword = function (_a) {
        var _this = this;
        var email = _a.email, password = _a.password, key = _a.key;
        return this.socketService.call(Events.RESET_PASSWORD, function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(['/']);
                return [2 /*return*/];
            });
        }); }, { email: email, password: password, key: key });
    };
    UserService.prototype.requestPasswordReset = function (email) {
        return this.socketService.call(Events.REQUEST_PASSWORD_RESET, null, email);
    };
    UserService.prototype.changePassword = function (oldPassword, newPassword) {
        return this.socketService.call(Events.CHANGE_PASSWORD, null, oldPassword, newPassword);
    };
    UserService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.clearSession();
                setTimeout(function () {
                    _this.router.navigate(['/authentication/login']);
                }, 100);
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.getUser = function () {
        return this.user;
    };
    UserService.prototype.setSession = function (session) {
        this.session = session;
        // tslint:disable-next-line:max-line-length
        this.cookies.set("session", btoa(JSON.stringify(session)), new Date(session.expires), '/', environment.cookie.url, environment.cookie.secure, environment.cookie.sameSite);
    };
    UserService.prototype.clearSession = function () {
        this.user = null;
        this.session = null;
        this.cookies.deleteAll('/', environment.cookie.url);
    };
    UserService.prototype.getSession = function () {
        return this.session;
    };
    UserService.prototype.getSessionCookie = function () {
        return this.cookies.get("session");
    };
    return UserService;
}());
export { UserService };

import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import IPermission from '../_interfaces/IPermission';
import IEntitlement from '../_interfaces/IEntitlement';
import IDatabaseObject from '../_interfaces/IDatabaseObject';

import Events from '../_util/events';
import { SocketService } from './socket.service';
import { AccessLevel } from '../_enum/IAccessLevel';
import { EntitlementResourceType } from '../_enum/IEntitlementResourceType';

import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export interface IEvaluatedAccessLevel {
	resourceId: string;
	resourceType: EntitlementResourceType;
	accessLevel: AccessLevel;
	entitlement?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
	private myPermissions: IPermission[] = [];
	private permissions: IPermission[] = [];
	private entitlements: IEntitlement[] = [];

	constructor(private socketService: SocketService) {}

	public init(): Observable<any> {
		return new Observable<any>((observer) => {
			// nested observables - slightly hacky
			this.socketService
				.call(Events.GET_PERMISSIONS, (myPermissions) => {
					this.myPermissions = myPermissions;
				})
				.subscribe((_) => {
					this.socketService
						.call(Events.LIST_PERMISSION_TYPES, (permissions) => {
							this.permissions = permissions;
						})
						.subscribe((__) => {
							this.socketService
								.call(Events.LIST_ENTITLEMENTS, (entitlements) => {
									this.entitlements = entitlements;
								}).subscribe((_) => {
									observer.next();
									observer.complete();
								});
						});
				});
		});
	}

	public getMyPermissions(): IPermission[] {
		return this.myPermissions;
	}

	public getPermissions(): IPermission[] {
		return this.permissions;
	}

	public getEntitlements(): IEntitlement[] {
		return this.entitlements;
	}

	public getEntitlementsForResource(resource: IDatabaseObject): IEntitlement[] {
		return this.entitlements.filter(entitlement => entitlement.target.resource === resource._id);
	}

	public getEntitlementsForUser(userId: string): IEntitlement[] {
		return this.entitlements.filter(entitlement => entitlement.user === userId);
	}

	public getEvaluatedEntitlementsForUser(userId: string): Observable<any> {
		return this.socketService
		.call(Events.GET_EVALUATED_ENTITLEMENT_ACCESS, undefined, userId);
	}

	public createEntitlement(resource: IDatabaseObject, resourceType: EntitlementResourceType, userId: string, accessLevel: AccessLevel): Observable<IEntitlement> {
		return this.socketService
			.call(Events.CREATE_ENTITLEMENT, (entitlement) => {
				this.entitlements.push(entitlement);
			}, resource, resourceType, userId, accessLevel)
	}

//   public createEntitlementWithSubdirectories(
//     resource: IDatabaseObject,
//     resourceType: EntitlementResourceType,
//     userId: string,
//     accessLevel: AccessLevel,
//     shareChildren: boolean = true // Hardcoded to always share children
//   ): Observable<IEntitlement> {
//     return this.socketService.call(
//         Events.CREATE_ENTITLEMENT,
//         (entitlement) => {
//           this.entitlements.push(entitlement);
//         },
//         resource,
//         resourceType,
//         userId,
//         accessLevel
//       )
//       .pipe(
//         switchMap((entitlement) => {
//           // If not a directory or shareChildren is false, return the entitlement Observable
//           if (!shareChildren || resourceType !== EntitlementResourceType.DIRECTORY) {
//             return of(entitlement);
//           }
//
//           // Share all children and wait for completion
//           return this.shareAllChildren(resource, userId, accessLevel).pipe(
//             map(() => entitlement) // Pass the original entitlement through
//           );
//         }),
//         catchError((error) => {
//           console.error(`Failed to create entitlement for resource ${resource._id}:`, error);
//           return throwError(() => error); // Propagate the error
//         })
//       );
//   }
//
// // Helper method to share all children as Observables and use `forkJoin` to wait for them
//   private shareAllChildren(
//     directory: IDatabaseObject,
//     userId: string,
//     accessLevel: AccessLevel
//   ): Observable<void> {
//     return this.socketService
//       .call(Events.LIST_ENTITLEMENTS, (entitlements) => {
//         return entitlements.filter(
//           (entitlement) => entitlement.target.parent === directory._id
//         );
//       })
//       .pipe(
//         switchMap((children) => {
//           // Map each child to a createEntitlement call
//           const childObservables = children.map((child) =>
//             this.createEntitlementWithSubdirectories(
//               child.target,
//               EntitlementResourceType.DIRECTORY,
//               userId,
//               accessLevel,
//               true // Ensure recursive sharing
//             )
//           );
//
//           // Use `forkJoin` to wait for all child entitlements to complete
//           return forkJoin(childObservables).pipe(
//             map(() => undefined) // Return void to match method signature
//           );
//         }),
//         catchError((error) => {
//           console.error(`Failed to share children for directory ${directory._id}:`, error);
//           return throwError(() => error); // Propagate the error
//         })
//       );
//   }

	public editEntitlement(entitlement: IEntitlement, config: any): Observable<IEntitlement> {
		return this.socketService
			.call(Events.EDIT_ENTITLEMENT, (editedEntitlement) => {
				Object.assign(entitlement, editedEntitlement);
			}, entitlement._id, config)
	}

	public deleteEntitlement(entitlement: IEntitlement): Observable<IEntitlement> {
		return this.socketService
			.call(Events.DELETE_ENTITLEMENT, (entitlement) => {
				this.entitlements = this.entitlements.filter((innerEntitlement) => innerEntitlement._id !== entitlement._id);
			}, entitlement._id)
	}

	public hasAccessLevelOnResource(resource: IDatabaseObject, accessLevel: AccessLevel): boolean {
		if (!resource['@permission']) {
			return undefined;
		}

		const currentAccessLevel = resource['@permission'] as AccessLevel;

		switch (accessLevel) {
			case AccessLevel.OWNER:
				return currentAccessLevel === AccessLevel.OWNER;
			case AccessLevel.EDITOR:
				return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR;
			case AccessLevel.VIEWER:
				return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR || currentAccessLevel === AccessLevel.VIEWER;
		}

		return false;
	}

	public isAdmin(userType: string): boolean {
		return (userType === 'admin' || userType === 'internal');
	}

	public checkForPermission(userType: string, permissionType: string): boolean {
		if (this.isAdmin(userType)) {
			return true;
		}
		return !!this.myPermissions.find((permission) => permission.type === permissionType);
	}

	public getPermissionById(id: string): IPermission {
		return this.permissions.find((permission) => permission._id === id);
	}
}

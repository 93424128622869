import * as tslib_1 from "tslib";
import { SuiModal } from 'ng2-semantic-ui';
import { FormBuilder, Validators } from '@angular/forms';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
var NewDirectoryModalComponent = /** @class */ (function () {
    function NewDirectoryModalComponent(modal, notificationService, filesystemService, loaderService, fb) {
        this.modal = modal;
        this.notificationService = notificationService;
        this.filesystemService = filesystemService;
        this.loaderService = loaderService;
        this.fb = fb;
        this.parent = this.modal.context.parent;
        this.type = this.modal.context.type;
        this.initializeForm();
    }
    NewDirectoryModalComponent.prototype.initializeForm = function () {
        this.newDirectoryFormGroup = this.fb.group({
            name: ['', Validators.compose([Validators.required])],
        });
    };
    NewDirectoryModalComponent.prototype.createDirectory = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loaderService.setLoading(true, 'Creating directory...');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.filesystemService.makeDirectory(this.newDirectoryFormGroup.value.name, this.parent, this.type).toPromise()];
                    case 2:
                        _a.sent();
                        this.modal.approve(undefined);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.notificationService.displayError(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.loaderService.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    return NewDirectoryModalComponent;
}());
export { NewDirectoryModalComponent };

import { clamp } from '../../../../_util/functions';
import {
    ElementPropertyDisplayType, ElementPropertyValueType, ElementType
} from '../../builder.enum';
import { IElement, IElementProperty, IViewportDimensions } from '../../builder.interface';

export class BackgroundProperty implements IElementProperty {
	label: string = 'Background';
	value: string = 'background';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.COLOR;
}


export class RotationProperty implements IElementProperty {
	label: string = 'Rotation';
	value: string = 'rotation';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;
	unit: 'deg';

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let roundedValue = Math.round(value);

		return roundedValue % 360;
	}
}

export class WidthProperty implements IElementProperty {
	label: string = 'Width';
	value: string = 'size.width';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;
	unit: 'px';

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let minimumValue: number;
		let maximumValue: number;

		switch (element.type) {
			case ElementType.TEXT:
				minimumValue = 10;
				maximumValue = viewport.width - element.position.x;
				break;
			case ElementType.IMAGE:
			case ElementType.VIDEO:
			default:
				minimumValue = 10;
				maximumValue = viewport.width - element.position.x;
		}

		return clamp(value, minimumValue, maximumValue);
	}
}

export class HeightProperty implements IElementProperty {
	label: string = 'Height';
	value: string = 'size.height';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;
	unit: 'px';

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let minimumValue: number;
		let maximumValue: number;

		switch (element.type) {
			case ElementType.TEXT:
				minimumValue = 10;
				maximumValue = viewport.height - element.position.y;
				break;
			case ElementType.IMAGE:
			case ElementType.VIDEO:
			default:
				minimumValue = 10;
				maximumValue = viewport.height - element.position.y;
		}

		return clamp(value, minimumValue, maximumValue);
	}
}

export class PositionXProperty implements IElementProperty {
	label: string = 'Position X';
	value: string = 'position.x';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;
	unit: 'px';

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let minimumValue: number;
		let maximumValue: number;

		switch (element.type) {
			case ElementType.TEXT:
			case ElementType.IMAGE:
			case ElementType.VIDEO:
			default:
				minimumValue = 0;
				maximumValue = viewport.width - element.size.width;
		}

		return clamp(value, minimumValue, maximumValue);
	}
}

export class PositionYProperty implements IElementProperty {
	label: string = 'Position Y';
	value: string = 'position.y';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;
	unit: 'px';

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number | undefined {
		let minimumValue: number;
		let maximumValue: number;

		switch (element.type) {
			case ElementType.TEXT:
			case ElementType.IMAGE:
			case ElementType.VIDEO:
			default:
				minimumValue = 0;
				maximumValue = viewport.height - element.size.height;
		}

		return clamp(value, minimumValue, maximumValue);
	}
}

export class LayerProperty implements IElementProperty {
	label: string = 'Layer';
	value: string = 'index';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;
}

<div class="header">Create new folder</div>
<div class="content">
	<form class="ui form" [formGroup]="newDirectoryFormGroup">
		<h4 class="ui dividing header">Folder Info</h4>
		<div class="field">
			<label>Name</label>
			<input type="text" name="name" formControlName="name" />
		</div>
	</form>
</div>
<div class="actions">
	<button class="ui button" (click)="modal.deny(undefined)">Cancel</button>
	<button class="ui primary button" (click)="createDirectory()" autofocus>Create</button>
</div>

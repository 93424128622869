import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import INewDirectoryModalContext from './delete.interface';
import { DeleteModalComponent } from './delete.modal';

export class DeleteModal extends ComponentModalConfig<INewDirectoryModalContext, void, void> {
	constructor(label: string, type: string, requiresConfirmation: boolean, size = ModalSize.Small) {
		super(DeleteModalComponent, { label, type, requiresConfirmation });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

import * as tslib_1 from "tslib";
import { SVGElementType } from "../svg.definition";
import SVGElementGeneric from "./generic.definition";
var SVGLine = /** @class */ (function (_super) {
    tslib_1.__extends(SVGLine, _super);
    function SVGLine(pointA, pointB) {
        var _this = _super.call(this) || this;
        _this.type = SVGElementType.LINE;
        _this.points = [];
        _this.points = [pointA, pointB];
        return _this;
    }
    SVGLine.prototype.generate = function (builder, parent) {
        var _a;
        return builder.line((_a = {},
            _a['x1'] = this.points[0].x - parent.getBounds().ax,
            _a['y1'] = this.points[0].y - parent.getBounds().ay,
            _a['x2'] = this.points[1].x - parent.getBounds().ax,
            _a['y2'] = this.points[1].y - parent.getBounds().ay,
            _a['stroke'] = parent.getProperty('fill'),
            _a['stroke-width'] = parent.getProperty('width'),
            _a));
    };
    return SVGLine;
}(SVGElementGeneric));
export { SVGLine };

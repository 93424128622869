import { FilePickerModal } from '../../../_modals/filePicker/filePicker.export';
import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction
} from '../builder.interface';

export class SelectVideoToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'select_video';
	label: string = 'Select video';
	icon: string = 'file video';
	tooltip: string = 'Select a new video';
	actionType: ToolbarActionType = ToolbarActionType.FILE;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fileProperty = context.getPropertyType(ElementType.VIDEO, 'value');

		context.getModalService().open(new FilePickerModal('video/mp4', false, true, context.getSessionToken())).onApprove((files: any) => {
			if (files.length) {
				context.setElementProperty(activeElement, fileProperty, files[0]);
			}
		});
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const fileProperty = context.getPropertyType(ElementType.VIDEO, 'value');

		return context.getElementProperty(context.getActiveElement(), fileProperty);
	}
}

import * as tslib_1 from "tslib";
import { SuiModal } from 'ng2-semantic-ui';
import { catchError } from 'rxjs/internal/operators';
import { HttpEventType } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import { downloadResourceToDisk, downloadResourceToMemory } from '../../_util/functions';
import { ResourceModal } from '../resource/resource.export';
import { ModalService } from '@citadel/common-frontend/_services/modal.service';
var BuilderModalComponent = /** @class */ (function () {
    function BuilderModalComponent(loader, modalService, filesystemService, notificationService, modal) {
        this.loader = loader;
        this.modalService = modalService;
        this.filesystemService = filesystemService;
        this.notificationService = notificationService;
        this.modal = modal;
        this.readyToPlay = false;
        this.hasDownloaded = false;
        this.hasAddedToLibrary = false;
        this.url = this.modal.context.url;
        this.sessionToken = this.modal.context.sessionToken;
    }
    BuilderModalComponent.prototype.onCanPlay = function () {
        var target = this.video.nativeElement;
        target.play();
        this.readyToPlay = true;
    };
    BuilderModalComponent.prototype.getCustomVideoName = function () {
        return "custom_video_" + new Date().valueOf() + ".mp4";
    };
    BuilderModalComponent.prototype.download = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.hasDownloaded = true;
                        this.loader.setLoading(true, 'Downloading...');
                        return [4 /*yield*/, downloadResourceToDisk(this.url, this.getCustomVideoName())];
                    case 1:
                        _a.sent();
                        this.loader.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderModalComponent.prototype.openEditModal = function (resource) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalService.open(new ResourceModal(resource, true, []));
                return [2 /*return*/];
            });
        });
    };
    BuilderModalComponent.prototype.addToLibrary = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blob;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.hasAddedToLibrary = true;
                        this.loader.setLoading(true, 'Downloading...');
                        return [4 /*yield*/, downloadResourceToMemory(this.url)];
                    case 1:
                        blob = _a.sent();
                        this.loader.setLoading(true, 'Uploading...');
                        this.filesystemService
                            .uploadBlob(blob, this.getCustomVideoName(), null, this.sessionToken)
                            .pipe(catchError(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var errorMessage;
                            return tslib_1.__generator(this, function (_a) {
                                errorMessage = event.error.error || event.error.data;
                                this.notificationService.displayError(errorMessage);
                                this.loader.setLoading(false);
                                return [2 /*return*/];
                            });
                        }); }))
                            .subscribe(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var upload;
                            return tslib_1.__generator(this, function (_a) {
                                if (!event) {
                                    return [2 /*return*/];
                                }
                                if (event.type === HttpEventType.Response) {
                                    upload = event.body;
                                    if (upload.meta && upload.meta.length > 0) {
                                        this.openEditModal(upload);
                                        this.loader.setLoading(false);
                                    }
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    BuilderModalComponent.prototype.submit = function () {
        this.modal.approve(undefined);
    };
    return BuilderModalComponent;
}());
export { BuilderModalComponent };

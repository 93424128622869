import { Observable } from 'rxjs';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
var LibraryService = /** @class */ (function () {
    function LibraryService(socketService) {
        this.socketService = socketService;
    }
    LibraryService.prototype.init = function () {
        return new Observable(function (observer) {
            observer.next();
            observer.complete();
        });
    };
    LibraryService.prototype.queryMediaLibraries = function (query, page, count, types) {
        return this.socketService.call(Events.QUERY_MEDIA_LIBRARIES, undefined, { query: query, page: page, count: count, types: types });
    };
    LibraryService.prototype.reportDownload = function (resourceId, provider) {
        return this.socketService.call(Events.REPORT_MEDIA_DOWNLOAD, undefined, { resourceId: resourceId, provider: provider });
    };
    LibraryService.ngInjectableDef = i0.defineInjectable({ factory: function LibraryService_Factory() { return new LibraryService(i0.inject(i1.SocketService)); }, token: LibraryService, providedIn: "root" });
    return LibraryService;
}());
export { LibraryService };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./waveform.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./waveform.component";
import * as i4 from "../../_services/waveform.service";
var styles_WaveformComponent = [i0.styles];
var RenderType_WaveformComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WaveformComponent, data: {} });
export { RenderType_WaveformComponent as RenderType_WaveformComponent };
function View_WaveformComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ui placeholder cdm-waveform__placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "image"]], null, null, null, null, null))], null, null); }
function View_WaveformComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "play circle icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPlayClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_WaveformComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "stop circle icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onStopClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_WaveformComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "cdm-waveform__controls"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaveformComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaveformComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPlaying(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isPlaying(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_WaveformComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { musicContainerElement: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["musicContainer", 1]], null, 4, "div", [["class", "cdm-waveform"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaveformComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaveformComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoaded; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.controls; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_WaveformComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-waveform", [], null, null, null, View_WaveformComponent_0, RenderType_WaveformComponent)), i1.ɵdid(1, 4243456, null, 0, i3.WaveformComponent, [i4.WaveformService], null, null)], null, null); }
var WaveformComponentNgFactory = i1.ɵccf("app-waveform", i3.WaveformComponent, View_WaveformComponent_Host_0, { url: "url", controls: "controls", pxPerSecond: "pxPerSecond", interactable: "interactable", heightScalingFactor: "heightScalingFactor" }, {}, []);
export { WaveformComponentNgFactory as WaveformComponentNgFactory };

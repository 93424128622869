import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';

const VERSION_EVENT = 'version';

@Injectable()
export class VersionService {
	private version: String;

	constructor(private socketService: SocketService) { }

	public init(): Observable<any> {
		return this.socketService.call(VERSION_EVENT, (version: String) => {
			this.version = version;
		});
	}

	public getVersion(): String {
		return this.version;
	}
}

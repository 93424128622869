import { Observable } from 'rxjs';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import { AccessLevel } from '../_enum/IAccessLevel';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
var PermissionService = /** @class */ (function () {
    function PermissionService(socketService) {
        this.socketService = socketService;
        this.myPermissions = [];
        this.permissions = [];
        this.entitlements = [];
    }
    PermissionService.prototype.init = function () {
        var _this = this;
        return new Observable(function (observer) {
            // nested observables - slightly hacky
            _this.socketService
                .call(Events.GET_PERMISSIONS, function (myPermissions) {
                _this.myPermissions = myPermissions;
            })
                .subscribe(function (_) {
                _this.socketService
                    .call(Events.LIST_PERMISSION_TYPES, function (permissions) {
                    _this.permissions = permissions;
                })
                    .subscribe(function (__) {
                    _this.socketService
                        .call(Events.LIST_ENTITLEMENTS, function (entitlements) {
                        _this.entitlements = entitlements;
                    }).subscribe(function (_) {
                        observer.next();
                        observer.complete();
                    });
                });
            });
        });
    };
    PermissionService.prototype.getMyPermissions = function () {
        return this.myPermissions;
    };
    PermissionService.prototype.getPermissions = function () {
        return this.permissions;
    };
    PermissionService.prototype.getEntitlements = function () {
        return this.entitlements;
    };
    PermissionService.prototype.getEntitlementsForResource = function (resource) {
        return this.entitlements.filter(function (entitlement) { return entitlement.target.resource === resource._id; });
    };
    PermissionService.prototype.getEntitlementsForUser = function (userId) {
        return this.entitlements.filter(function (entitlement) { return entitlement.user === userId; });
    };
    PermissionService.prototype.getEvaluatedEntitlementsForUser = function (userId) {
        return this.socketService
            .call(Events.GET_EVALUATED_ENTITLEMENT_ACCESS, undefined, userId);
    };
    PermissionService.prototype.createEntitlement = function (resource, resourceType, userId, accessLevel) {
        var _this = this;
        return this.socketService
            .call(Events.CREATE_ENTITLEMENT, function (entitlement) {
            _this.entitlements.push(entitlement);
        }, resource, resourceType, userId, accessLevel);
    };
    //   public createEntitlementWithSubdirectories(
    //     resource: IDatabaseObject,
    //     resourceType: EntitlementResourceType,
    //     userId: string,
    //     accessLevel: AccessLevel,
    //     shareChildren: boolean = true // Hardcoded to always share children
    //   ): Observable<IEntitlement> {
    //     return this.socketService.call(
    //         Events.CREATE_ENTITLEMENT,
    //         (entitlement) => {
    //           this.entitlements.push(entitlement);
    //         },
    //         resource,
    //         resourceType,
    //         userId,
    //         accessLevel
    //       )
    //       .pipe(
    //         switchMap((entitlement) => {
    //           // If not a directory or shareChildren is false, return the entitlement Observable
    //           if (!shareChildren || resourceType !== EntitlementResourceType.DIRECTORY) {
    //             return of(entitlement);
    //           }
    //
    //           // Share all children and wait for completion
    //           return this.shareAllChildren(resource, userId, accessLevel).pipe(
    //             map(() => entitlement) // Pass the original entitlement through
    //           );
    //         }),
    //         catchError((error) => {
    //           console.error(`Failed to create entitlement for resource ${resource._id}:`, error);
    //           return throwError(() => error); // Propagate the error
    //         })
    //       );
    //   }
    //
    // // Helper method to share all children as Observables and use `forkJoin` to wait for them
    //   private shareAllChildren(
    //     directory: IDatabaseObject,
    //     userId: string,
    //     accessLevel: AccessLevel
    //   ): Observable<void> {
    //     return this.socketService
    //       .call(Events.LIST_ENTITLEMENTS, (entitlements) => {
    //         return entitlements.filter(
    //           (entitlement) => entitlement.target.parent === directory._id
    //         );
    //       })
    //       .pipe(
    //         switchMap((children) => {
    //           // Map each child to a createEntitlement call
    //           const childObservables = children.map((child) =>
    //             this.createEntitlementWithSubdirectories(
    //               child.target,
    //               EntitlementResourceType.DIRECTORY,
    //               userId,
    //               accessLevel,
    //               true // Ensure recursive sharing
    //             )
    //           );
    //
    //           // Use `forkJoin` to wait for all child entitlements to complete
    //           return forkJoin(childObservables).pipe(
    //             map(() => undefined) // Return void to match method signature
    //           );
    //         }),
    //         catchError((error) => {
    //           console.error(`Failed to share children for directory ${directory._id}:`, error);
    //           return throwError(() => error); // Propagate the error
    //         })
    //       );
    //   }
    PermissionService.prototype.editEntitlement = function (entitlement, config) {
        return this.socketService
            .call(Events.EDIT_ENTITLEMENT, function (editedEntitlement) {
            Object.assign(entitlement, editedEntitlement);
        }, entitlement._id, config);
    };
    PermissionService.prototype.deleteEntitlement = function (entitlement) {
        var _this = this;
        return this.socketService
            .call(Events.DELETE_ENTITLEMENT, function (entitlement) {
            _this.entitlements = _this.entitlements.filter(function (innerEntitlement) { return innerEntitlement._id !== entitlement._id; });
        }, entitlement._id);
    };
    PermissionService.prototype.hasAccessLevelOnResource = function (resource, accessLevel) {
        if (!resource['@permission']) {
            return undefined;
        }
        var currentAccessLevel = resource['@permission'];
        switch (accessLevel) {
            case AccessLevel.OWNER:
                return currentAccessLevel === AccessLevel.OWNER;
            case AccessLevel.EDITOR:
                return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR;
            case AccessLevel.VIEWER:
                return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR || currentAccessLevel === AccessLevel.VIEWER;
        }
        return false;
    };
    PermissionService.prototype.isAdmin = function (userType) {
        return (userType === 'admin' || userType === 'internal');
    };
    PermissionService.prototype.checkForPermission = function (userType, permissionType) {
        if (this.isAdmin(userType)) {
            return true;
        }
        return !!this.myPermissions.find(function (permission) { return permission.type === permissionType; });
    };
    PermissionService.prototype.getPermissionById = function (id) {
        return this.permissions.find(function (permission) { return permission._id === id; });
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.SocketService)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };

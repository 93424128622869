import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class LayerUpToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'layer_up';
	icon: string = 'angle up';
	tooltip: string = 'Move an element towards the front';
	label: string = 'Move up a layer';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const indexProperty = context.getPropertyType(ElementType.ANY, 'index');

		// sort in descending order
		const elementsByIndex = context.getActiveSlide().elements.sort((a, b) => b.index - a.index);

		// 1 or 0 elements, don't bother with layers
		if (elementsByIndex.length < 2) {
			return;
		}

		const highestElement = elementsByIndex[0];
		const secondHighestElement = elementsByIndex[1];

		const currentIndex = activeElement.index;

		if (highestElement.index === currentIndex && highestElement.index > secondHighestElement.index) {
			return;
		}

		context.setElementProperty(activeElement, indexProperty, currentIndex + 1);
	}
}

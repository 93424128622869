import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.LOADER_FADE_TIME = 250;
        this.loading = false;
        this.pageLoaded = false;
        // before page load
        this.unloadedStatus = false;
        // percentage loader
        this.isPercentageLoading = false;
        this.percentageLoadingValue = 0;
    }
    LoaderService.prototype.initializeOnPageload = function () {
        this.pageLoaded = true;
        this.loading = this.unloadedStatus;
        this.loaderText = this.unloadedText;
    };
    LoaderService.prototype.setLoading = function (value, loaderText) {
        if (loaderText === void 0) { loaderText = ''; }
        if (this.pageLoaded) {
            this.loading = value;
            this.loaderText = loaderText;
        }
        else {
            this.unloadedStatus = value;
            this.unloadedText = loaderText;
        }
    };
    LoaderService.prototype.createPercentageLoader = function (message) {
        var _this = this;
        this.isPercentageLoading = true;
        this.loaderText = message;
        this.percentageLoadingValue = 0;
        return function (value) {
            _this.percentageLoadingValue = value;
            if (value >= 100) {
                _this.stopPercentageLoader();
            }
        };
    };
    LoaderService.prototype.stopPercentageLoader = function () {
        var _this = this;
        setTimeout(function () {
            _this.isPercentageLoading = false;
        }, this.LOADER_FADE_TIME);
    };
    LoaderService.prototype.isDeterminateLoading = function () {
        return this.isPercentageLoading;
    };
    LoaderService.prototype.isIndeterminateLoading = function () {
        return this.isLoading && !this.isPercentageLoading;
    };
    LoaderService.prototype.isLoading = function () {
        if (!this.pageLoaded) {
            return false;
        }
        return this.loading || this.isPercentageLoading;
    };
    LoaderService.prototype.getLoadingText = function () {
        return this.loaderText;
    };
    LoaderService.prototype.getPercentageLoadingValue = function () {
        // Clamp from 0-100
        return Math.min(Math.max(this.percentageLoadingValue, 0), 100);
    };
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };

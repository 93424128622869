import { clamp } from '../../../../_util/functions';
import { ElementPropertyDisplayType, ElementPropertyValueType } from '../../builder.enum';
import {
    IElement, IElementProperty, IElementSVGProperty, IViewportDimensions
} from '../../builder.interface';

export class ShapeFillProperty implements IElementSVGProperty {
	label: string = 'Fill color';
	value: string = 'properties["svg::fill"]';
	svg: string = 'fill';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.COLOR;
}

export class ShapeStrokeProperty implements IElementSVGProperty {
	label: string = 'Border color';
	value: string = 'properties["svg::stroke"]';
	svg: string = 'stroke';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.COLOR;
}

export class ShapeThicknessProperty implements IElementSVGProperty {
	label: string = 'Thickness';
	value: string = 'properties["svg::width"]';
	unit: string = 'px';
	svg: string = 'width';
	width: ElementPropertyDisplayType = ElementPropertyDisplayType.NONE;
	type: ElementPropertyValueType = ElementPropertyValueType.NUMBER;

	public validate(element: IElement, viewport: IViewportDimensions, value: number): number {
		let minimumValue: number = 0;
		let maximumValue: number = 32;

		return clamp(value, minimumValue, maximumValue);
	}
}
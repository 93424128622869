import { ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';
import { VideoElement } from '../elements/video/video.element';

export class AddVideoToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'add_video';
	icon: string = 'video';
	tooltip: string = 'Add a new video';
	label: string = 'Videos';
  	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext, url: string = 'https://citadel-video-builder-assets.s3.amazonaws.com/sample_video.mp4', width: number = 300, height: number = 300): IElement | void {
		const currentElementCount: number = context.getActiveSlide().elements.length;
		const defaultVideo = new VideoElement(url, width, height);
		defaultVideo.index = currentElementCount;
		context.getActiveSlide().elements.push(defaultVideo);
		context.setActiveElement(defaultVideo);
		return defaultVideo;
	}
}

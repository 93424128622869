import { ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';
import { TextElement } from '../elements/text/text.element';

export class AddTextToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'add_text';
	icon: string = 'font';
	tooltip: string = 'Add a new text box';
	label: string = 'Text';
  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const currentElementCount: number = context.getActiveSlide().elements.length;

		const defaultTextBox = new TextElement('Edit this text');
		defaultTextBox.index = currentElementCount;
		context.getActiveSlide().elements.push(defaultTextBox);
		context.setActiveElement(defaultTextBox);

		return defaultTextBox;
	}
}

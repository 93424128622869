import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';

import Events from '../_util/events';
import { ILibraryResult } from '../_interfaces/ILibraryResult';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

	constructor(private socketService: SocketService) {}

	public init(): Observable<any> {
		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}

	public queryMediaLibraries(query: string, page: number, count: number, types: string[]): Observable<{ [key: string]: ILibraryResult[] }> {
		return this.socketService.call(Events.QUERY_MEDIA_LIBRARIES, undefined, { query, page, count, types });
	}

	public reportDownload(resourceId: string, provider: string): Observable<any> {
    	return this.socketService.call(Events.REPORT_MEDIA_DOWNLOAD, undefined, { resourceId, provider });
	}
}

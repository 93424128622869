import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class LayerFrontToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'layer_front';
	icon: string = 'angle double up';
	tooltip: string = 'Move an element all the way to the front';
	label: string = 'Move to front';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const indexProperty = context.getPropertyType(ElementType.ANY, 'index');
		const minimumIndex = context.getActiveSlide()!.elements.map(element => element.index);
		const newIndex = Math.max(...minimumIndex) + 1;

		context.setElementProperty(activeElement, indexProperty, newIndex);
	}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./action.modal";
import * as i2 from "ng2-semantic-ui/dist/modules/modal/classes/modal-controls";
var styles_ActionModalComponent = [];
var RenderType_ActionModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionModalComponent, data: {} });
export { RenderType_ActionModalComponent as RenderType_ActionModalComponent };
export function View_ActionModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "ui button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.deny(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "button", [["autofocus", ""], ["class", "ui button primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.approve(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.action; _ck(_v, 8, 0, currVal_2); }); }
export function View_ActionModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-action", [], null, null, null, View_ActionModalComponent_0, RenderType_ActionModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.ActionModalComponent, [i2.Modal], null, null)], null, null); }
var ActionModalComponentNgFactory = i0.ɵccf("app-modal-action", i1.ActionModalComponent, View_ActionModalComponent_Host_0, {}, {}, []);
export { ActionModalComponentNgFactory as ActionModalComponentNgFactory };

export function validateMimetype(type: string, filter: string) {
    if (!filter) {
        return false;
    }

    const filterTypes = filter.split(',').map(s => s.trim());

    for (const filterType of filterTypes) {
        const filterTypeNoWildcard = filterType.replace('*', '');

        if (type.indexOf(filterTypeNoWildcard) !== -1) {
            return true;
        }
    }

    return false;
}
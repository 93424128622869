import { ElementType, ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class AlignTextLeftToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'align_text_left';
	icon: string = 'align left';
	tooltip: string = 'Align text to the left';
  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const alignmentProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::alignment"]');

		context.setElementProperty(activeElement, alignmentProperty, 'left');
	}
}

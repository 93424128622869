import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import { IVideoScaffold } from '../../_components/builder/builder.interface';
import IBuildingModalContext from './building.interface';
import { BuildingModalComponent } from './building.modal';

export class BuildingModal extends ComponentModalConfig<IBuildingModalContext, string, void> {
	constructor(creation: IVideoScaffold, size = ModalSize.Tiny) {
		super(BuildingModalComponent, { creation });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}

import * as tslib_1 from "tslib";
import { SuiModal } from 'ng2-semantic-ui';
var DeleteModalComponent = /** @class */ (function () {
    function DeleteModalComponent(modal) {
        this.modal = modal;
        this.requiresConfirmation = false;
        this.confirmValue = '';
        this.label = this.modal.context.label;
        this.type = this.modal.context.type;
        this.requiresConfirmation = this.modal.context.requiresConfirmation;
    }
    DeleteModalComponent.prototype.delete = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modal.approve(undefined);
                return [2 /*return*/];
            });
        });
    };
    DeleteModalComponent.prototype.isTypedProperly = function () {
        return !this.requiresConfirmation || (this.confirmValue && this.confirmValue.toLowerCase().trim() === this.label.toLowerCase().trim());
    };
    return DeleteModalComponent;
}());
export { DeleteModalComponent };

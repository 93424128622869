import * as tslib_1 from "tslib";
import { SuiModal } from 'ng2-semantic-ui';
var InputModalComponent = /** @class */ (function () {
    function InputModalComponent(modal) {
        this.modal = modal;
        this.value = '';
        this.header = modal.context.header;
        this.message = modal.context.message;
        this.input = modal.context.input;
    }
    InputModalComponent.prototype.hasValue = function () {
        return !!this.value && this.value.length > 0;
    };
    InputModalComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modal.approve(this.value);
                return [2 /*return*/];
            });
        });
    };
    return InputModalComponent;
}());
export { InputModalComponent };

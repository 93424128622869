import { ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';
import { ShapeElement } from '../elements/shape/shape.element';
import { ISVG } from '../svg/svg.definition';

export class AddShapeToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'add_shape';
	icon: string = 'cube';
	tooltip: string = 'Add a new shape';
	label: string = 'Shapes';
	actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext, svg: ISVG): IElement | void {
		const currentElementCount: number = context.getActiveSlide()!.elements.length;

		const defaultShape = new ShapeElement(svg);
		defaultShape.index = currentElementCount;
		context.getActiveSlide()!.elements.push(defaultShape);
		context.setActiveElement(defaultShape);

		return defaultShape;
	}
}

import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class FontEffectSizeToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'font_effect_size';
	label: string = 'Effect size';
	icon: string = 'text height';
	tooltip: string = 'Change your effect size';
	actionType: ToolbarActionType = ToolbarActionType.INPUT;

	execute(context: IVideoBuilderContext, size: number): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontEffectSizeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::effect-size"]');

		context.setElementProperty(activeElement, fontEffectSizeProperty, size);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const fontEffectSizeProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::effect-size"]');
		return context.getElementProperty(context.getActiveElement(), fontEffectSizeProperty);
	}
}

import { naiveDeepCopy } from '../../../../_util/functions';
import { v4 as uuidv4 } from 'uuid';
var GenericElement = /** @class */ (function () {
    function GenericElement(id) {
        if (id === void 0) { id = ''; }
        this.properties = {};
        this.position = {
            x: 320,
            y: 240,
        };
        this.rotation = 0;
        this.size = {
            width: 240,
            height: 180,
        };
        this.background = '#FFFFFF';
        this.index = 0;
        this.id = id || uuidv4();
    }
    GenericElement.prototype.clone = function () {
        var clonedElement = Object.create(this);
        naiveDeepCopy(clonedElement, this);
        clonedElement.index++;
        clonedElement.id = uuidv4();
        return clonedElement;
    };
    GenericElement.prototype.generateCSS = function (editorViewport, outputViewport) {
        var _a;
        var style = (_a = {},
            _a['position'] = 'absolute',
            _a);
        var heightRatio = editorViewport.height / outputViewport.height;
        var widthRatio = editorViewport.width / outputViewport.width;
        if (this.index !== undefined) {
            style['z-index'] = this.index;
        }
        if (this.size) {
            if (this.size.width) {
                style['width'] = this.size.width * widthRatio + "px";
            }
            else {
                style['width'] = "0px";
            }
            if (this.size.height) {
                style['height'] = this.size.height * heightRatio + "px";
            }
            else {
                style['width'] = "0px";
            }
        }
        if (this.position) {
            if (!isNaN(this.position.x)) {
                style['left'] = this.position.x * widthRatio + "px";
            }
            if (!isNaN(this.position.y)) {
                style['top'] = this.position.y * heightRatio + "px";
            }
        }
        if (this.rotation) {
            // We don't handle the rotation here for various reasons. Check the __rotation element for more info.
            // if (!isNaN(this.rotation)) {
            // 	style['transform']= `rotate(${this.rotation}deg)`;
            // }
        }
        if (this.background) {
            style['background'] = this.background;
        }
        return style;
    };
    return GenericElement;
}());
export { GenericElement };

import * as i0 from "@angular/core";
var PreferencesService = /** @class */ (function () {
    function PreferencesService() {
        this.PREFERENCE_PREFIX = 'preference';
    }
    PreferencesService.prototype.setPreference = function (key, value) {
        return localStorage.setItem(this.generateStorageKey(key), value);
    };
    PreferencesService.prototype.getPreference = function (key, fallback) {
        if (fallback === void 0) { fallback = undefined; }
        return localStorage.getItem(this.generateStorageKey(key)) || fallback;
    };
    PreferencesService.prototype.generateStorageKey = function (key) {
        return [this.PREFERENCE_PREFIX].concat(key.map(function (x) { return x.trim(); })).join('/');
    };
    PreferencesService.ngInjectableDef = i0.defineInjectable({ factory: function PreferencesService_Factory() { return new PreferencesService(); }, token: PreferencesService, providedIn: "root" });
    return PreferencesService;
}());
export { PreferencesService };

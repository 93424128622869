import * as tslib_1 from "tslib";
import { SVGCircle } from "../elements/circle.definition";
import { SVGLine } from "../elements/line.definition";
import { SVGType } from "../svg.definition";
import SVGGeneric from "./generic.definition";
var SVGArrow = /** @class */ (function (_super) {
    tslib_1.__extends(SVGArrow, _super);
    function SVGArrow(ax, ay, bx, by) {
        var _a;
        var _this = _super.call(this) || this;
        _this.type = SVGType.ARROW;
        _this.properties = (_a = {},
            _a['fill'] = '#042e41',
            _a['width'] = 6,
            _a);
        _this.points = [
            {
                x: ax,
                y: ay,
                transformable: true
            },
            {
                x: bx,
                y: by,
                transformable: true
            }
        ];
        _this.render();
        return _this;
    }
    SVGArrow.prototype.calculateElements = function () {
        this.elements = [
            new SVGLine(this.points[0], this.points[1]),
            new SVGLine(this.points[1], this.points[2]),
            new SVGLine(this.points[1], this.points[3]),
            new SVGCircle(this.points[0]),
            new SVGCircle(this.points[1]),
            new SVGCircle(this.points[2]),
            new SVGCircle(this.points[3])
        ];
    };
    SVGArrow.prototype.calculatePoints = function () {
        var angleOffset = Math.PI / 4;
        var distance = this.getProperty('width') * 2;
        var adjacent = this.points[1].x - this.points[0].x;
        var opposite = this.points[1].y - this.points[0].y;
        var angle = Math.atan(opposite / adjacent);
        angle = adjacent < 0 ? angle + Math.PI : angle;
        var arrowPointA = {
            x: this.points[1].x - distance * Math.cos(angle + angleOffset),
            y: this.points[1].y - distance * Math.sin(angle + angleOffset),
            transformable: false,
        };
        var arrowPointB = {
            x: this.points[1].x - distance * Math.cos(angle - angleOffset),
            y: this.points[1].y - distance * Math.sin(angle - angleOffset),
            transformable: false,
        };
        this.points = [
            this.points[0],
            this.points[1],
            arrowPointA,
            arrowPointB
        ];
    };
    SVGArrow.prototype.getBounds = function () {
        return {
            ax: Math.min.apply(Math, this.points.map(function (point) { return point.x; })) - (this.getProperty('width') / 2),
            ay: Math.min.apply(Math, this.points.map(function (point) { return point.y; })) - (this.getProperty('width') / 2),
            bx: Math.max.apply(Math, this.points.map(function (point) { return point.x; })) + (this.getProperty('width') / 2),
            by: Math.max.apply(Math, this.points.map(function (point) { return point.y; })) + (this.getProperty('width') / 2)
        };
    };
    return SVGArrow;
}(SVGGeneric));
export default SVGArrow;

import { ElementType, ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class ToggleUnderlineToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'toggle_underline';
	icon: string = 'underline';
	tooltip: string = 'Toggle underline effect';
  actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontUnderlineProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::underline"]');

		const currentPropertyValue = activeElement.properties['font::underline'];

		if (currentPropertyValue === 'underline') {
			context.setElementProperty(activeElement, fontUnderlineProperty, 'none');
		} else {
			context.setElementProperty(activeElement, fontUnderlineProperty, 'underline');
		}
	}
}

import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { NgZone } from '@angular/core';
import { compress, decompress } from '../_util/compression';
import { NotificationService } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "./notification.service";
var SocketService = /** @class */ (function () {
    function SocketService(zone, notifications) {
        this.zone = zone;
        this.notifications = notifications;
        this.registeredTaggedEvents = {};
        this.registeredEvents = {};
    }
    SocketService.prototype.init = function (endpointBaseUrl, target, rejectUnauthorized) {
        var _this = this;
        if (rejectUnauthorized === void 0) { rejectUnauthorized = true; }
        return new Observable(function (observer) {
            var socketConnectionOpts = {
                forceNew: true,
                transports: ['websocket'],
                rejectUnauthorized: rejectUnauthorized,
                query: {
                    target: target,
                },
            };
            var connectionUrl = endpointBaseUrl;
            _this.socket = io(connectionUrl, socketConnectionOpts);
            _this.socket.on('connect', function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    observer.next(this.socket);
                    observer.complete();
                    return [2 /*return*/];
                });
            }); });
            _this.socket.on('error', function (e) {
                console.error(e);
                observer.error(e);
                observer.complete();
            });
            _this.socket.on('data', function (payload) {
                var _a = decompress(payload), event = _a.event, tag = _a.tag, data = _a.data, success = _a.success;
                console.log("Got " + event + " response!");
                if (tag) {
                    if (_this.registeredTaggedEvents[tag]) {
                        _this.registeredTaggedEvents[tag](success, data);
                    }
                }
                else {
                    if (_this.registeredEvents[event]) {
                        _this.registeredEvents[event](data);
                    }
                }
            });
        });
    };
    SocketService.prototype.getTargetString = function () {
        if (window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('127.0.0.1') !== -1) {
            return 'local';
        }
        var splitHost = window.location.host.split('.');
        return splitHost[0];
    };
    SocketService.prototype.call = function (event, callback) {
        var _this = this;
        if (callback === void 0) { callback = undefined; }
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        return new Observable(function (observer) {
            var uuidTag = uuidv4();
            _this.registerTaggedEvent(uuidTag, function (success, data) {
                if (!success) {
                    console.error("cc-trace-8093-fac077b3e5cc", 'not successful:', data);
                    observer.error(data);
                    _this.notifications.displayError(data);
                    return;
                }
                if (callback) {
                    _this.zone.run(function () {
                        try {
                            callback(data);
                        }
                        catch (err) {
                            console.error("cc-trace-821c-12c1a61fa090", err);
                            _this.notifications.displayError(err);
                        }
                    });
                }
                _this.zone.run(function () {
                    try {
                        observer.next(data);
                    }
                    catch (err) {
                        console.error("cc-trace-b0b5-6ee6b56a2fa8", err);
                        _this.notifications.displayError(err);
                    }
                    try {
                        observer.complete();
                    }
                    catch (err) {
                        console.error("cc-trace-96dd-294777b5920d", err);
                        _this.notifications.displayError(err);
                    }
                });
            });
            try {
                var payload = { event: event, tag: uuidTag, data: args };
                var compressedPayload = compress(payload);
            }
            catch (err) {
                console.error("cc-trace-9edf-7240a9329d02", err);
                _this.getSocketConnection().emit('data', err);
                return;
            }
            console.log("Sending " + event + "...");
            _this.getSocketConnection().emit('data', compressedPayload);
        });
    };
    SocketService.prototype.registerEvent = function (event, callback) {
        this.registeredEvents[event] = callback;
    };
    SocketService.prototype.unregisterEvent = function (event) {
        delete this.registeredEvents[event];
    };
    SocketService.prototype.registerTaggedEvent = function (tag, callback) {
        this.registeredTaggedEvents[tag] = callback;
    };
    SocketService.prototype.unregisterTaggedEvent = function (tag) {
        delete this.registeredTaggedEvents[tag];
    };
    SocketService.prototype.getSocketConnection = function () {
        return this.socket;
    };
    SocketService.ngInjectableDef = i0.defineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.inject(i0.NgZone), i0.inject(i1.NotificationService)); }, token: SocketService, providedIn: "root" });
    return SocketService;
}());
export { SocketService };

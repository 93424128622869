import { DragAndDropModule } from 'angular-draggable-droppable';
import { NgPipesModule } from 'angular-pipes';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { SuiModule } from 'ng2-semantic-ui';
import { ColorPickerModule } from 'ngx-color-picker';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieService } from 'ngx-cookie-service';
import { FileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'ngx-moment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DndModule } from 'ngx-drag-drop';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { HoverClassDirective } from './_directives/hover-class.directive';

import { BuilderComponent } from './_components/builder/builder.component';
import { FSComponent } from './_components/fs/fs.component';
import { NotificationsComponent } from './_components/notifications/notifications.component';
import { ActionModalComponent } from './_modals/action/action.modal';
import { BuilderModalComponent } from './_modals/builder/builder.modal';
import { BuildingModalComponent } from './_modals/building/building.modal';
import { ConfirmModalComponent } from './_modals/confirm/confirm.modal';
import { DeleteModalComponent } from './_modals/delete/delete.modal';
import { FilePickerModalComponent } from './_modals/filePicker/filePicker.modal';
import { NewDirectoryModalComponent } from './_modals/newDirectory/newDirectory.modal';
import { ResourceModalComponent } from './_modals/resource/resource.modal';
import { ResourceViewModalComponent } from './_modals/resourceView/resourceView.modal';
import { InputModalComponent } from './_modals/input/input.modal';
import { SafePipe } from './_pipes/safe.pipe';
import { UncamelPipe } from './_pipes/uncamelcase.pipe';
import { WaveformComponent } from './_components/waveform/waveform.component';
import { UnsavedChangesGuard } from './_guards/unsaved-changes.guard';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';

const MODALS = [
	DeleteModalComponent,
	ResourceModalComponent,
	NewDirectoryModalComponent,
	FilePickerModalComponent,
	BuilderModalComponent,
	ConfirmModalComponent,
	ResourceViewModalComponent,
	BuildingModalComponent,
  ActionModalComponent,
  InputModalComponent,
];

const COMPONENTS = [
  FSComponent,
  BuilderComponent,
  NotificationsComponent,
  WaveformComponent
];

const PIPES = [
  UncamelPipe,
  SafePipe,
  SafeHtmlPipe
];

const DIRECTIVES = [
  HoverClassDirective
]

const SERVICES = [
  CookieService
];

const GUARDS = [
  UnsavedChangesGuard
];

const MODULES = [
  SimpleNotificationsModule.forRoot(),
  FormsModule,
  FileDropModule,
  ReactiveFormsModule,
  DragDropModule,
  RouterModule,
  CommonModule,
  ContextMenuModule,
  DragAndDropModule,
  ColorPickerModule,
  InfiniteScrollModule,
  DndModule,
  MomentModule.forRoot({
    relativeTimeThresholdOptions: {
      ss: 1,
    },
  }),
  SuiModule,
  NgPipesModule,
];

@NgModule({
	imports: [...MODULES],
	providers: [...SERVICES, ...GUARDS],
	declarations: [...MODALS, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
	exports: [...MODALS, ...COMPONENTS, ...PIPES],
	entryComponents: [...MODALS, ...COMPONENTS],
})
export class SharedModule {}

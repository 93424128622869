import svg from 'svg-builder';

import { ISVG, ISVGBounds, ISVGElement, ISVGPoint, SVGType } from "../svg.definition";
import { EventEmitter } from '@angular/core';

export default abstract class SVGGeneric implements ISVG {
    type: SVGType;
    points: ISVGPoint[];
    elements: ISVGElement[];
    rendered: string;
    properties: { [key: string]: any; };

    constructor() { }

    protected abstract calculateElements(): void;

    protected abstract calculatePoints(): void;

    render(): string {
        this.calculatePoints();
        this.calculateElements();

        const size = this.getSize();

        let builder = svg.newInstance().width(size.width).height(size.height);

        for (const element of this.elements) {
            builder = element.generate(builder, this);
        }

        this.rendered = builder.render();

        return this.rendered;
    }

    abstract getBounds(): ISVGBounds;

    validateBounds(width: number, height: number): void {
        const bounds = this.getBounds();
        const size = this.getSize();
        const currentOrigin = this.getOriginPoint();

        if (bounds.ax < 0) {
            this.changeOrigin(0, currentOrigin.y);
        }

        if (bounds.ay < 0) {
            this.changeOrigin(currentOrigin.x, 0);
        }

        if (bounds.bx >= width) {
            this.changeOrigin(width - size.width, currentOrigin.y);
        }

        if (bounds.by >= height) {
            this.changeOrigin(currentOrigin.x, height - size.height);
        }
    }

    getOriginPoint(): ISVGPoint {
        let lowestPoint = this.points[0];
        
        for (let point of this.points) {
            if (!point.transformable) {
                continue;
            }
            if (point.x <= lowestPoint.x && point.y <= lowestPoint.y) {
                lowestPoint = point;
            }
        }
        
        return lowestPoint;
    }

    changeOrigin(x: number, y: number): void {
        let origin = this.getOriginPoint();

        for (let point of this.points) {
            if (point === origin) {
                continue;
            }
            let currentOffsetX = point.x - origin.x;
            let currentOffsetY = point.y - origin.y;

            point.x = x + currentOffsetX;
            point.y = y + currentOffsetY;
        }

        origin.x = x;
        origin.y = y;
    }

    getSize(): { width: number; height: number; } {
        return {
            width: Math.abs(this.getBounds().bx - this.getBounds().ax),
            height: Math.abs(this.getBounds().by - this.getBounds().ay),
        }
    }

    getPoints(): ISVGPoint[] {
        return this.points;
    }

    getProperty(key: string): any {
        return this.properties[key];
    }

    setProperty(key: string, value: any): void {
        this.properties[key] = value;
    }
}
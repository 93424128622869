import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent {
  public notificationOptions: any = {
		timeOut: 6000,
		position: ['top', 'right'],
		preventDuplicates: 'true',
		showProgressBar: false,
	};

	constructor() {}
}

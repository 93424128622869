export default {
	AUTHENTICATE_TOKEN_EVENT: 'token/authenticate',

	VERSION_EVENT: 'version',

	LOGIN: 'self/login',
	REGISTER: 'self/register',
	REQUEST_PASSWORD_RESET: 'self/request-password-reset',
	RESET_PASSWORD: 'self/reset-password',
	COMPLETE_REGISTRATION: 'self/complete-registration',
	CHANGE_PASSWORD: 'self/change-password',
	LIST_LOGIN_OPTIONS: 'self/login-options',

	LIST_DEPLOYMENTS: 'deployment/list',
	GET_AUTHENTICATION_TOKEN_FOR_DEPLOYMENT: 'deployment/authentication',

	LIST_VIDEOS: 'video/list',
	ADD_VIDEO: 'video/add',
	REMOVE_VIDEO: 'video/remove',

	GENERATE_VIDEO: 'builder/generate',
	CHECK_VIDEO_STATUS: 'builder/status',
	GET_VIDEO_LINK: 'builder/link',

	GET_CUSTOMER_PORTAL_URL: 'invoice/url',

	LIST_LICENSES: 'license/list',

	ADMIN_CREATE_DEPLOYMENT: 'admin/deployment/create',
	ADMIN_TERMINATE_DEPLOYMENT: 'admin/deployment/terminate',
	ADMIN_ACTIVATE_ACCOUNT: 'admin/user/activate',
	ADMIN_DEACTIVATE_ACCOUNT: 'admin/user/deactivate',
	ADMIN_GET_PIN_DEPLOYMENT: 'admin/deployment/pin',
	ADMIN_GET_USERS: 'admin/users',
	ADMIN_CHANGE_PASSWORD: 'admin/users/change-password',
	ADMIN_POST_TO_FEED: 'admin/feed/post',
	ADMIN_REMOVE_FROM_FEED: 'admin/feed/remove',
	ADMIN_GET_RECENT_POSTS: 'admin/feed/get',

	SEARCH_IN_FILESYSTEM: 'filesystem/search',
	EDIT_IN_FILESYSTEM: 'filesystem/edit',
	COPY_IN_FILESYSTEM: 'filesystem/paste',
	MOVE_IN_FILESYSTEM: 'filesystem/move',
	DELETE_IN_FILESYSTEM: 'filesystem/delete',
	MAKE_DIRECTORY_IN_FILESYSTEM: 'filesystem/mkdir',
	GET_DIRECTORY_DETAILS_IN_FILESYSTEM: 'filesystem/ls',
	GET_DIRECTORY_BY_PATH_IN_FILESYSTEM: 'filesystem/get-path',
	GET_FILE_DETAILS_IN_FILESYSTEM: 'filesystem/file',
	CHECK_FOR_PATH_IN_FILESYSTEM: 'filesystem/check-path',

	LIST_PLAYLISTS: 'playlist/list',
	GET_PLAYLIST_CONTENTS: 'playlist/content',
	MAKE_PLAYLIST: 'playlist/add',
	GET_PLAYLIST_DETAILS_IN_FILESYSTEM: 'playlist/ls',
	PLAYLIST_SEARCH_IN_FILESYSTEM: 'playlist/search',
	PLAYLIST_DELETE_IN_FILESYSTEM: 'playlist/delete',
};

import { SuiModal } from 'ng2-semantic-ui';

import { Component } from '@angular/core';

import IResourceModalContext from './delete.interface';

@Component({
	selector: 'app-modal-delete',
	templateUrl: './delete.modal.html',
	styleUrls: ['./delete.modal.scss'],
})
export class DeleteModalComponent {
	public label: string;
	public type: string;

	public requiresConfirmation: boolean = false;

	public confirmValue: string = '';

	constructor(public modal: SuiModal<IResourceModalContext, void, void>) {
		this.label = this.modal.context.label;
		this.type = this.modal.context.type;
		this.requiresConfirmation = this.modal.context.requiresConfirmation;
	}

	public async delete() {
		this.modal.approve(undefined);
	}

	public isTypedProperly() {
		return !this.requiresConfirmation || (this.confirmValue && this.confirmValue.toLowerCase().trim() === this.label.toLowerCase().trim());
	}
}

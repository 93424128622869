import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import IDirectory from '../_interfaces/IDirectory';
import IFile from '../_interfaces/IFile';
import Events from '../_util/events';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class FilesystemService {
	private endpointBaseUrl: string;

	constructor(private http: HttpClient, private socketService: SocketService) {}

	public init(baseUrl: string): Observable<any> {
		return new Observable((observer) => {
			observer.next();
			this.endpointBaseUrl = baseUrl;
			observer.complete();
		});
	}

	public upload(file: File, parent: IDirectory, sessionToken: string, name?: string): Observable<any> {
		const formData = new FormData();
		formData.append('file', file, name);
		formData.append('parent', parent ? parent._id : '');

		return this.http.post(`${this.endpointBaseUrl}/api/upload`, formData, {
			reportProgress: true,
			observe: 'events',
			headers: {
				['x-citadel-session']: sessionToken,
				['x-target']: this.socketService.getTargetString(),
			},
		});
	}

	public uploadBlob(blob: Blob, name: string, parent: IDirectory, sessionToken: string): Observable<any> {
		const formData = new FormData();
		formData.append('file', blob, name);
		formData.append('parent', parent ? parent._id : '');

		return this.http.post(`${this.endpointBaseUrl}/api/upload`, formData, {
			reportProgress: true,
			observe: 'events',
			headers: {
				['x-citadel-session']: sessionToken,
				['x-target']: this.socketService.getTargetString(),
			},
		});
	}

	public search(filter: string): Observable<any> {
		return this.socketService.call(Events.SEARCH_IN_FILESYSTEM, undefined, filter);
	}

	public edit(type: string, resource: IFile | IDirectory, config: any): Observable<any> {
		return this.socketService.call(Events.EDIT_IN_FILESYSTEM, undefined, resource._id, type, config);
	}

	public copy(type: string, resource: IFile | IDirectory, destination: IDirectory): Observable<any> {
		return this.socketService.call(Events.COPY_IN_FILESYSTEM, undefined, resource._id, type, destination ? destination._id : null);
	}

	public move(type: string, resource: IFile | IDirectory, destination: IDirectory): Observable<any> {
		return this.socketService.call(Events.MOVE_IN_FILESYSTEM, undefined, resource._id, type, destination ? destination._id : null);
	}

	public delete(type: string, resource: IFile | IDirectory): Observable<any> {
		return this.socketService.call(Events.DELETE_IN_FILESYSTEM, undefined, resource._id, type);
	}

	public makeDirectory(directoryName: string, parent: IDirectory | undefined, type: string = 'user'): Observable<any> {
		return this.socketService.call(Events.MAKE_DIRECTORY_IN_FILESYSTEM, undefined, directoryName, parent ? parent._id : null, type);
	}

	public getDirectoryDetails(directory: IDirectory): Observable<any> {
		return this.socketService.call(Events.GET_DIRECTORY_DETAILS_IN_FILESYSTEM, undefined, directory ? directory._id : null);
	}

	public getAllChildrenFiles(directory: IDirectory | IFile): Observable<any> {
		return this.socketService.call(Events.GET_CHILDREN_FILES_IN_FILESYSTEM, undefined, directory);
	}

	public getDirectoryByPath(path: string): Observable<any> {
		return this.socketService.call(Events.GET_DIRECTORY_BY_PATH_IN_FILESYSTEM, undefined, path);
	}

	public getFileDetails(fileId: string): Observable<any> {
		return this.socketService.call(Events.GET_FILE_DETAILS_IN_FILESYSTEM, undefined, fileId);
	}

	public checkForPath(path: string): Observable<any> {
		return this.socketService.call(Events.CHECK_FOR_PATH_IN_FILESYSTEM, undefined, path);
	}
}

import { ISVGBounds, SVGType } from "../svg.definition";
import SVGGeneric from "./generic.definition";
import { SVGRect } from '../elements/rect.definition';

export default class SVGBox extends SVGGeneric {
    type = SVGType.BOX;

    properties: { [key: string]: any; } = {
        ['fill']: 'black',
        ['stroke']: '#ffffff',
        ['width']: 0
    };

    constructor(x: number, y: number, size: number) {
        super();

        this.points = [
            {
                x: x,
                y: y,
                transformable: true
            },
            {
                x: x + size,
                y: y + size,
                transformable: true
            }
        ];

        this.render();
    }

    protected calculateElements(): void {
        this.elements = [
            new SVGRect(
                this.points[0],
                this.points[1]
            )
        ];
    }

    protected calculatePoints(): void {
        return;
    }

    getBounds(): ISVGBounds {
        return {
            ax: Math.min(...this.points.map(point => point.x)) - (this.getProperty('width') / 2),
            ay: Math.min(...this.points.map(point => point.y)) - (this.getProperty('width') / 2),
            bx: Math.max(...this.points.map(point => point.x)) + (this.getProperty('width') / 2),
            by: Math.max(...this.points.map(point => point.y)) + (this.getProperty('width') / 2)
        }
    }
}
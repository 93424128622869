import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class FontEffectToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'font_effect';
	label: string = 'Font effect';
	icon: string = 'hand sparkles';
	tooltip: string = 'Assign a font effect';
	actionType: ToolbarActionType = ToolbarActionType.DROPDOWN;

	execute(context: IVideoBuilderContext, type: string): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const fontEffectProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::effect"]');

		context.setElementProperty(activeElement, fontEffectProperty, type);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const fontEffectProperty = context.getPropertyType(ElementType.TEXT, 'properties["font::effect"]');

		return context.getElementProperty(context.getActiveElement(), fontEffectProperty);
	}

	getRelevantPropertyType(context: IVideoBuilderContext): any {
		return context.getPropertyType(ElementType.TEXT, 'properties["font::effect"]')
	}
}

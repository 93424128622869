import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { ConfirmModalComponent } from './confirm.modal';
var ConfirmModal = /** @class */ (function (_super) {
    tslib_1.__extends(ConfirmModal, _super);
    function ConfirmModal(title, description) {
        if (description === void 0) { description = ''; }
        var _this = _super.call(this, ConfirmModalComponent, { title: title, description: description }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = ModalSize.Small;
        return _this;
    }
    return ConfirmModal;
}(ComponentModalConfig));
export { ConfirmModal };

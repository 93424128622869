import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { ResourceModalComponent } from './resource.modal';
var ResourceModal = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceModal, _super);
    function ResourceModal(resource, justUploaded, availableTags, size) {
        if (justUploaded === void 0) { justUploaded = false; }
        if (availableTags === void 0) { availableTags = []; }
        if (size === void 0) { size = ModalSize.Small; }
        var _this = _super.call(this, ResourceModalComponent, { resource: resource, justUploaded: justUploaded, availableTags: availableTags }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return ResourceModal;
}(ComponentModalConfig));
export { ResourceModal };

import { NotificationsService } from 'angular2-notifications';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
	constructor(private notifications: NotificationsService) {}

	public displayError(error: any, override: any = {}): void {
		this.notifications.error('Oops', error, override);
		console.error(error);
	}

	public displaySuccess(success: string, override: any = {}): void {
		this.notifications.success('Success', success, override);
	}

  public displayInfo(message: string, override: any = {}): void {
		this.notifications.info('Info', message, override);
	}
}

import { ElementType, ToolbarActionType } from '../builder.enum';
import {
    IElement, IVideoBuilderContext, IVideoBuilderToolbarAction, IVideoSlide
} from '../builder.interface';

export class AddAnimationToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'add_animation';
	icon: string = 'play circle icon';
	tooltip: string = 'Animate this element';
    label: string = 'Animate';
    actionType: ToolbarActionType = ToolbarActionType.BUTTON;

	execute(context: IVideoBuilderContext): IElement | void {
		const activeElement: IElement = context.getActiveElement();

		if (!activeElement) {
			return;
		}

        context.addAnimation(activeElement);
	}
}

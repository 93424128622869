import { NotificationsService } from 'angular2-notifications';
import * as i0 from "@angular/core";
import * as i1 from "angular2-notifications";
var NotificationService = /** @class */ (function () {
    function NotificationService(notifications) {
        this.notifications = notifications;
    }
    NotificationService.prototype.displayError = function (error, override) {
        if (override === void 0) { override = {}; }
        this.notifications.error('Oops', error, override);
        console.error(error);
    };
    NotificationService.prototype.displaySuccess = function (success, override) {
        if (override === void 0) { override = {}; }
        this.notifications.success('Success', success, override);
    };
    NotificationService.prototype.displayInfo = function (message, override) {
        if (override === void 0) { override = {}; }
        this.notifications.info('Info', message, override);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.NotificationsService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };

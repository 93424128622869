import { SVGElementType, ISVGPoint, ISVG } from "../svg.definition";
import SVGElementGeneric from "./generic.definition";

export class SVGRect extends SVGElementGeneric {
    type: SVGElementType = SVGElementType.RECT;
    points: ISVGPoint[] = [];

    constructor(pointA: ISVGPoint, pointB: ISVGPoint) {
        super();

        this.points = [pointA, pointB];
    }

    generate(builder: any, parent: ISVG) {
        return builder.rect({
            ['x']: Math.min(this.points[0].x, this.points[1].x) - parent.getBounds().ax,
            ['y']: Math.min(this.points[0].y, this.points[1].y) - parent.getBounds().ay,
            ['width']: Math.abs(this.points[1].x - this.points[0].x),
            ['height']: Math.abs(this.points[1].y - this.points[0].y),
            ['stroke']: parent.getProperty('stroke'),
            ['stroke-width']: parent.getProperty('width'),
            ['fill']: parent.getProperty('fill'),
        });
    }
}
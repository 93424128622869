import { ElementType, ToolbarActionType } from '../builder.enum';
import {
	IElement, IVideoBuilderContext, IVideoBuilderToolbarAction
} from '../builder.interface';

export class ShapeStrokeToolbarAction implements IVideoBuilderToolbarAction {
	type: string = 'shape_stroke';
	label: string = 'Border color';
	icon: string = 'paint brush';
	tooltip: string = 'Assign a border color to the shape';
	actionType: ToolbarActionType = ToolbarActionType.COLOR;

	execute(context: IVideoBuilderContext, color: string): IElement | void {
		const activeElement: IElement = context.getActiveElement();
		if (!activeElement) {
			return;
		}

		const strokeColorProperty = context.getPropertyType(ElementType.SHAPE, 'properties["svg::stroke"]');

		context.setElementProperty(activeElement, strokeColorProperty, color);
	}

	getCurrentValue(context: IVideoBuilderContext): any {
		const strokeColorProperty = context.getPropertyType(ElementType.SHAPE, 'properties["svg::stroke"]');

		return context.getElementProperty(context.getActiveElement(), strokeColorProperty);
	}
}

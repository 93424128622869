import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./socket.service";
var FilesystemService = /** @class */ (function () {
    function FilesystemService(http, socketService) {
        this.http = http;
        this.socketService = socketService;
    }
    FilesystemService.prototype.init = function (baseUrl) {
        var _this = this;
        return new Observable(function (observer) {
            observer.next();
            _this.endpointBaseUrl = baseUrl;
            observer.complete();
        });
    };
    FilesystemService.prototype.upload = function (file, parent, sessionToken, name) {
        var _a;
        var formData = new FormData();
        formData.append('file', file, name);
        formData.append('parent', parent ? parent._id : '');
        return this.http.post(this.endpointBaseUrl + "/api/upload", formData, {
            reportProgress: true,
            observe: 'events',
            headers: (_a = {},
                _a['x-citadel-session'] = sessionToken,
                _a['x-target'] = this.socketService.getTargetString(),
                _a),
        });
    };
    FilesystemService.prototype.uploadBlob = function (blob, name, parent, sessionToken) {
        var _a;
        var formData = new FormData();
        formData.append('file', blob, name);
        formData.append('parent', parent ? parent._id : '');
        return this.http.post(this.endpointBaseUrl + "/api/upload", formData, {
            reportProgress: true,
            observe: 'events',
            headers: (_a = {},
                _a['x-citadel-session'] = sessionToken,
                _a['x-target'] = this.socketService.getTargetString(),
                _a),
        });
    };
    FilesystemService.prototype.search = function (filter) {
        return this.socketService.call(Events.SEARCH_IN_FILESYSTEM, undefined, filter);
    };
    FilesystemService.prototype.edit = function (type, resource, config) {
        return this.socketService.call(Events.EDIT_IN_FILESYSTEM, undefined, resource._id, type, config);
    };
    FilesystemService.prototype.copy = function (type, resource, destination) {
        return this.socketService.call(Events.COPY_IN_FILESYSTEM, undefined, resource._id, type, destination ? destination._id : null);
    };
    FilesystemService.prototype.move = function (type, resource, destination) {
        return this.socketService.call(Events.MOVE_IN_FILESYSTEM, undefined, resource._id, type, destination ? destination._id : null);
    };
    FilesystemService.prototype.delete = function (type, resource) {
        return this.socketService.call(Events.DELETE_IN_FILESYSTEM, undefined, resource._id, type);
    };
    FilesystemService.prototype.makeDirectory = function (directoryName, parent, type) {
        if (type === void 0) { type = 'user'; }
        return this.socketService.call(Events.MAKE_DIRECTORY_IN_FILESYSTEM, undefined, directoryName, parent ? parent._id : null, type);
    };
    FilesystemService.prototype.getDirectoryDetails = function (directory) {
        return this.socketService.call(Events.GET_DIRECTORY_DETAILS_IN_FILESYSTEM, undefined, directory ? directory._id : null);
    };
    FilesystemService.prototype.getAllChildrenFiles = function (directory) {
        return this.socketService.call(Events.GET_CHILDREN_FILES_IN_FILESYSTEM, undefined, directory);
    };
    FilesystemService.prototype.getDirectoryByPath = function (path) {
        return this.socketService.call(Events.GET_DIRECTORY_BY_PATH_IN_FILESYSTEM, undefined, path);
    };
    FilesystemService.prototype.getFileDetails = function (fileId) {
        return this.socketService.call(Events.GET_FILE_DETAILS_IN_FILESYSTEM, undefined, fileId);
    };
    FilesystemService.prototype.checkForPath = function (path) {
        return this.socketService.call(Events.CHECK_FOR_PATH_IN_FILESYSTEM, undefined, path);
    };
    FilesystemService.ngInjectableDef = i0.defineInjectable({ factory: function FilesystemService_Factory() { return new FilesystemService(i0.inject(i1.HttpClient), i0.inject(i2.SocketService)); }, token: FilesystemService, providedIn: "root" });
    return FilesystemService;
}());
export { FilesystemService };

import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { DeleteModalComponent } from './delete.modal';
var DeleteModal = /** @class */ (function (_super) {
    tslib_1.__extends(DeleteModal, _super);
    function DeleteModal(label, type, requiresConfirmation, size) {
        if (size === void 0) { size = ModalSize.Small; }
        var _this = _super.call(this, DeleteModalComponent, { label: label, type: type, requiresConfirmation: requiresConfirmation }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return DeleteModal;
}(ComponentModalConfig));
export { DeleteModal };
